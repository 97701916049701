.e {
  &-timepicker {
    &__input {
      cursor: text;
      line-height: $input-box-height - 2;
      outline: 0;
      position: relative;
      user-select: none;

      &:read-only:focus {
        border-color: $input-color-border-focus;
        box-shadow: 0 0 4px rgba($input-color-border-focus, .5);
      }

      &:focus {
        span {
          background: palette('secondary', 'x-light');
        }
      }
    }
  }
}
