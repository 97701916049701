$switch-box-width: 24px !default;
$switch-box-handle-size: 15px !default;
$switch-box-height: 8px !default;
$switch-box-border: -2px !default;

$switch-handle-top: 50% !default;
$switch-color-background: palette('default', 'x-light') !default;
$switch-color-background-active: palette('primary') !default;
$switch-color-background-choose: palette('secondary') !default;
$switch-color-handle-fallback: palette('grays', 'inactive') !default;
$switch-color-handle-top: #eee !default;
$switch-color-handle-bottom: #fff !default;
$switch-color-handle: linear-gradient(-45deg, $switch-color-handle-top 20%, $switch-color-handle-bottom 100%) !default;
$switch-color-handle-border: palette('default', 'x-light') !default;
$switch-handle-movement: $switch-box-width - $switch-box-handle-size - ($switch-box-border * 2) !default;

.e {
  &-switch {
    cursor: pointer;
    display: inline-block;
    font-size: 0;
    line-height: $text-lineheight;
    margin: 0 (abs($switch-box-border) + 4) 0 abs($switch-box-border);
    position: relative;
    user-select: none;

    &__input {
      opacity: 0;
      position: absolute;

      &:checked + .e-switch__toggle {
        background-color: $switch-color-background-active;

        &:before {
          transform: translate($switch-handle-movement, -50%);
        }
      }

      &:disabled + .e-switch__toggle {
        opacity: .4;
      }

      &:focus + .e-switch__toggle {
        &:before {
          border-color: palette('secondary');
        }
      }
    }

    &__toggle {
      background-color: $switch-color-background;
      border-radius: $switch-box-height;
      cursor: pointer;
      display: inline-block;
      height: $switch-box-height;
      transition: all .2s;
      width: $switch-box-width;

      &:before {
        // scss-lint:disable DuplicateProperty
        background: $switch-color-handle-fallback;
        background: $switch-color-handle;
        background-clip: padding-box;
        border: 1px solid $switch-color-handle-border;
        border-radius: $switch-box-handle-size;
        content: '';
        height: $switch-box-handle-size;
        left: $switch-box-border;
        position: absolute;
        top: $switch-handle-top;
        transform: translate(0, -50%);
        transition: all .2s cubic-bezier(.5, 1.6, .5, 1);
        width: $switch-box-handle-size;
      }

      &:after {
        content: '';
        cursor: pointer;
        display: inline-block;
        height: $switch-box-handle-size + 2;
        left: $switch-box-border;
        position: absolute;
        right: $switch-box-border;
        top: $switch-handle-top;
        transform: translateY(-50%);
      }
    }

    &__label {
      display: inline-block;
      padding-left: 5px;
      vertical-align: middle;
    }

    &-choose {
      margin: 0 (abs($switch-box-border) + 4);

      .e-switch__input {
        + .e-switch__toggle {
          background-color: $switch-color-background-choose;
        }
      }
    }
  }
}
