$alert-size-icon: 14px !default;
$alert-text-size: typo('body', 'size');
$alert-box-padding-horizontal: 15px !default;
$alert-box-padding-vertical: 8px !default;
$alert-box-padding: $alert-box-padding-vertical $alert-box-padding-horizontal;
$alert-box-padding-withicon: 40px !default;
$alert-box-inline-height: $box-form-element-height !default;
$alert-box-width-canvas: 300px !default;
$alert-box-width-fixed: 600px !default;

@mixin e-alert {
  font-size: $alert-text-size;
  line-height: $text-lineheight;
  margin-bottom: $grid-gutter-width / 2;
  padding: $alert-box-padding;
  position: relative;
  text-align: left;

  &:empty {
    display: none;
  }
}

@mixin e-alert-modifier($modifier: 'default') {
  $data: map-get($alert-style, $modifier);

  background: map-get($data, 'background');
  border: 1px solid map-get($data, 'background');
  color: darken(map-get($data, 'color'), 20%);
  fill: darken(map-get($data, 'color'), 20%);
}

.e {
  &-alert,
  &-alerts {
    @include e-alert;

    @each $class, $data in $alert-style {
      $class-name: if($class == 'default', '', '-#{$class}');

      &#{$class-name} {
        @include e-alert-modifier($class);
      }
    }

    a,
    a:visited,
    a:active,
    a:hover,
    a:focus {
      color: inherit;
      text-decoration: underline;
    }

    a:hover,
    a:focus {
      opacity: .85;
    }

    &-withicon {
      padding-left: $alert-box-padding-withicon;
    }

    &-sticky {
      margin: 0 0 -1px;
    }

    &-condensed {
      margin: 0 0 2px;
    }

    &-withclose {
      padding-right: 40px;
    }

    &-inline {
      display: inline-block;
      height: $alert-box-inline-height;
      line-height: $alert-box-inline-height - 2;
      margin: 0 $grid-gutter-width / 4;
      padding-bottom: 0;
      padding-top: 0;
      vertical-align: middle;
    }

    &__close {
      cursor: pointer;
      position: absolute;
      right: 7px;
      top: 7px;
    }

    &-fixed {
      left: 0;
      margin: auto;
      position: fixed;
      right: 0;
      top: $fixed-topnav-height + 13px;
      width: $alert-box-width-fixed;
      z-index: z('fixed-element');
    }

    &-canvas {
      bottom: $grid-gutter-width / 2;
      left: auto;
      position: fixed;
      right: $grid-gutter-width / 2;
      top: auto;
      width: $alert-box-width-canvas;
      z-index: z('fixed-element');
    }

    &__icon {
      left: $alert-box-padding-horizontal;
      position: absolute;
      top: $alert-box-padding-vertical - 2;

      .e-icon {
        fill: inherit;
      }

      svg {
        height: $alert-size-icon;
        width: $alert-size-icon;
      }
    }
  }
}

