$grid-fixed-sizes: (
  'small': 250px,
  'medium': 330px,
  'large': 488px
);

.e-fixed {
  display: flex;
  flex-direction: row;

  &__full {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: $grid-gutter-width / 2;

    &-centered {
      align-items: center;
      display: flex;
    }
  }

  &__center {
    display: block;

    &-fullwidth {
      width: 100%;
    }
  }

  @each $class, $data in $grid-fixed-sizes {
    &__#{$class} {
      flex: 0 0 $data;
      padding: $grid-gutter-width / 2;

      &-bordered {
        border-left: 1px solid palette('grays', 'separator');
        border-right: 1px solid palette('grays', 'separator');
        flex: 0 0 $data + 2;
        margin: 0 -1px;
      }
    }
  }
}
