$table-box-gutter: 20px !default;
$table-box-margin: 0 0 10px 0 !default;
$table-box-padding-old: 12px 10px !default;
$table-box-padding: 12px $table-box-gutter / 2 !default;
$table-box-padding-summary: 6px 10px !default;
$table-box-padding-compact: 2px 10px !default;
$table-box-padding-condensed-old: 4px 10px !default;
$table-box-padding-condensed: 6px 10px !default;
$table-box-padding-nopadding: 4px 0 !default;
$table-box-filter-height: 60px !default;

$table-color-background-zebra: palette('grays', 'zebra') !default;
$table-color-background-header: #f1f1f1 !default;
$table-color-background-hover: palette('grays', 'inactive') !default;
$table-color-background-selected: palette('secondary') !default;

$table-color-background: #fff !default;
$table-color-selected: #fff !default;
$table-color-border: lighten(palette('default', 'x-light'), 10) !default;
$table-color-filter-background: #fff !default;
$table-color-advancedfilter-background: #e3edf8 !default;
$table-hover-transition: background $transition-time ease-in-out !default;
$table-size-title: typo('subheader', 'size') !default;
$table-box-padding-title: 20px 0 0 0 !default;

$table-sort: url('table-sort.svg') !default;
$table-sort-asc: url('table-sort-asc.svg') !default;
$table-sort-desc: url('table-sort-desc.svg') !default;

//TODO:
// 1. remove after matyas-v09 @dbarkoczi (remove from suite as well: list_regforms.inc)

@mixin e-table() {
  background: $table-color-background;
  margin: $table-box-margin;
  width: 100%;

  td,
  th {
    border-bottom: 1px solid $table-color-border;
    padding: $table-box-padding;

    &:first-child {
      padding-left: $grid-gutter-width / 2;
    }

    &:last-child {
      padding-right: $grid-gutter-width / 2;
    }
  }

  td {
    @include word-wrap;
    vertical-align: top;
  }

  th {
    font-weight: $text-bold-weight;
    text-align: left;
    vertical-align: bottom;

    &:focus {
      outline: none;
    }

    &.e-table__col-right {
      padding-right: $table-box-gutter;
      text-align: right;
    }
  }

  tbody {
    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  tfoot {
    td {
      border-bottom: 0;
      border-top: 1px solid $table-color-border;
    }
  }
}

@mixin e-table-modifier($modifier) {
  @if ($modifier == 'hover') {
    tbody {
      tr {
        transition: $tab-hover-transition;

        &:hover td {
          background: $table-color-background-hover;
        }

        &:hover .e-table__col-selected {
          background: $table-color-background-selected;
        }
      }
    }
  }

  @if ($modifier == 'condensed') {
    thead {
      th,
      td {
        padding: $table-box-padding-condensed;
      }

      th.e-table__sort {
        &:before {
          bottom: 10px;
        }
      }
    }

    tbody {
      th,
      td {
        padding: $table-box-padding-condensed;
        vertical-align: middle;
      }
    }
  }

  @if ($modifier == 'zebra') {
    tbody {
      tr:nth-child(odd) {
        background: $table-color-background-zebra;
      }
    }
  }

  @if ($modifier == 'bordered') {
    border: 1px solid $table-color-border;
  }

  @if ($modifier == 'inner_bordered') {
    thead {
      td,
      th {
        border: 1px solid $table-color-border;
      }
    }

    tbody {
      td {
        border: 1px solid $table-color-border;
      }
    }
  }

  @if ($modifier == 'withheader') {
    thead {
      th {
        background: $table-color-background-header;
      }
    }
  }

  @if ($modifier == 'modal') {
    border: 0;
    margin-bottom: -1px;

    tr:last-child td {
      border-bottom: 0;
    }
  }

  @if ($modifier == 'verticalcentered') {
    th,
    td {
      vertical-align: middle;
    }
  }

  @if ($modifier == 'inner') {
    border: 0;

    td {
      border: 0;
      padding: 2px 0;
      vertical-align: bottom;
    }
  }

  @if ($modifier == 'fixed') {
    table-layout: fixed;
  }
}

@mixin e-table__sort() {
  cursor: pointer;
  padding-left: $table-box-gutter / 2 + 13px;
  position: relative;

  a {
    color: $color-text;
  }

  &:before {
    bottom: 16px;
    content: $table-sort;
    height: 15px;
    left: $table-box-gutter / 2 - 3px;
    position: absolute;
    width: 14px;
  }

  &.e-table__col-right {
    padding-left: $table-box-gutter / 2;

    &:before {
      bottom: -3px;
      display: inline-block;
      left: -3px;
      position: relative;
    }
  }

  &:first-child {
    padding-left: $table-box-gutter + 6px;

    &:before {
      left: $table-box-gutter / 2;
    }
  }
}

@mixin e-table__sort-modifier($modifier: 'asc') {
  @if ($modifier == 'asc') {
    &:before {
      content: $table-sort-asc;
    }
  }

  @if ($modifier == 'desc') {
    &:before {
      content: $table-sort-desc;
    }
  }
}

[data-e-version='2'] {
  &.e {
    &-table {
      @include e-table;

      .e-stretch & {
        border-left-width: 0;
        border-right-width: 0;
        padding: 5px;
      }

      .e-stretch.e-stretch-top & {
        border-top-width: 0;
      }

      .e-stretch.e-stretch-bottom & {
        border-bottom-width: 0;
      }

      &-overview {
        @include e-table-modifier('bordered');
        @include e-table-modifier('hover');
      }

      &-hover {
        @include e-table-modifier('hover');
      }

      &-zebra {
        @include e-table-modifier('zebra');
      }

      &-withheader {
        @include e-table-modifier('withheader');
      }

      &-condensed {
        @include e-table-modifier('condensed');
      }

      &-bordered {
        @include e-table-modifier('bordered');
      }

      &-inner_bordered {
        @include e-table-modifier('inner_bordered');
      }

      &-inner {
        @include e-table-modifier('inner');
      }

      &-verticalcentered {
        @include e-table-modifier('verticalcentered');
      }

      // scss-lint:disable QualifyingElement
      // e-table th is too strong
      th.e-table__sort {
        @include e-table__sort;

        &-asc {
          @include e-table__sort-modifier('asc');
        }

        &-desc {
          @include e-table__sort-modifier('desc');
        }
      }

      td { // e-table td is too strong
        &.e-table__col-breakall {
          @include break-all;
        }

        &.e-table__col-right {
          padding-right: $table-box-gutter;
        }
      }

      &-modal {
        @include e-table-modifier('modal');
      }

      &-fixed {
        @include e-table-modifier('fixed');
      }

      pre {
        margin: 0;
        white-space: pre-wrap;
      }
    }
  }

  * {
    &.e {
      &-table {
        &__col {
          &-actions {
            text-align: right;
            user-select: none;
            white-space: nowrap;
            width: 110px;
          }

          &-selected { // 1.
            background-color: $table-color-background-selected;
            color: $table-color-selected;
          }

          &-filter {
            height: $table-box-filter-height;
            padding: 0 $table-box-gutter;
          }

          &-nowrap {
            white-space: nowrap;
          }

          &-groupheader {
            background: palette('grays', 'inactive');
            font-weight: $text-bold-weight;
          }

          &-right {
            text-align: right;
          }

          @each $class, $data in $table-col-size {
            &-#{$class} {
              width: $data;
            }
          }
        }

        &__row {
          td:first-child {
            position: relative;
          }

          &-active {
            td:first-child {
              &:before {
                background: palette('secondary');
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 3px;
              }
            }
          }
        }

        &__content {
          &-without_border {
            th,
            td {
              border: 0;
            }
          }
        }

        &__settings {
          display: inline-block;
          line-height: $box-form-element-height;
        }

        &__action { // in case the action buttons are not links
          cursor: pointer;
        }

        &__filter {
          display: inline-block;
          margin-right: $table-box-gutter / 2;
        }

        &__datatablefilter {
          padding-left: 260px;
          position: relative;
        }

        &__advancedfilters {
          background: $table-color-advancedfilter-background;
          height: 0;
          overflow: hidden;
          position: relative;
          top: 12px;
          transition: height $transition-time ease;

          &-active {
            height: 60px;
          }
        }

        &__thumbnail {
          background-image: url('e-thumbail-emptybg.svg');
          background-position: center;
          background-size: cover;
          border: 1px solid palette('grays', 'shade');
          height: 50px;
          min-width: 50px;
          position: relative; // loading icon position
          vertical-align: top;
          width: 50px;

          &-empty {
            background-size: auto;
          }
        }
      }
    }
  }
}
