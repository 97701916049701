$emojipicker-box-size: 20px !default;
$emojipicker-box-width: 35px !default;

e-emoji {
  display: block;
  position: relative;
}

.e {
  &-emojipicker {
    display: block;
    position: relative;

    .e-emoji,
    .e-tokeninput__editable {
      font-family: 'Segoe UI Emoji', typo('body', 'family');
      padding-right: $emojipicker-box-width;

      &::-ms-clear {
        display: none;
      }
    }

    &__picker {
      align-items: center;
      background: rgba(#fff, .0001);
      cursor: pointer;
      display: flex;
      height: $emojipicker-box-width - 2;
      justify-content: center;
      opacity: .7;
      position: absolute;
      right: 1px;
      top: 1px;
      width: $emojipicker-box-width;
      z-index: 1;

      &:hover {
        opacity: 1;
      }

      &-active {
        .e-emojipicker {
          &__open {
            display: none;
          }

          &__close {
            display: block;
          }
        }
      }

      svg {
        fill: palette('grays', 'shade');
        height: $emojipicker-box-size;
        position: relative;
        top: -1px;
        width: $emojipicker-box-size;
      }
    }

    &__close {
      display: none;
    }

    &__emoji {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      height: 26px;
      justify-content: center;
      line-height: 1;
      width: 26px;

      &:after {
        content: attr(data-emoji);
      }

      &:hover {
        background-color: palette('grays', 'separator');
      }

    }

    &__container {
      background: $color-white;
      border: 1px solid #ddd;
      flex-direction: column;
      font-family: 'Segoe UI Emoji', typo('body', 'family');
      font-size: 20px;
      height: 200px;
      margin-bottom: 0;
      padding: 0;
      top: -99999px;
      user-select: none;
      width: 290px;
      z-index: z('flying');

      &[x-placement*='top'] {
        border-bottom: 0;
      }

      &[x-placement*='bottom'] {
        border-top: 0;
      }
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      height: 165px;
      overflow: auto;
      padding: 5px;
    }

    &__tabs {
      display: flex;
      height: 33px;
    }

    &__tab {
      align-items: center;
      background: palette('grays', 'separator');
      cursor: pointer;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      line-height: 1;
      opacity: .8;
      text-align: center;

      &:hover {
        opacity: 1;
      }

      &-active {
        background: $color-white;
        opacity: 1;
      }
    }
  }
}
