// scss-lint:disable QualifyingElement
input.editable-input {
  @include e-input;
}

select.editable-input {
  @include e-select;
}

input,
select {
  &.editable-input {
    @include e-input-size('large');
  }
}

.editable-input.editable-input-medium {
  @include e-input-size('medium');
}

.editable-input.editable-input-small {
  @include e-input-size('small');
}

.editable-controls {
  @include e-buttongroup('button, .editable-input');
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
  vertical-align: middle;
}

.editable-error {
  background: palette('danger', 'default');
  color: palette('grays', 'empty');
  font-size: typo('small', 'size');
  line-height: $text-lineheight-condensed;
  margin: 3px 5px;
  padding: 5px;
  position: absolute;
  top: 100%;
  z-index: 1;
}

.editable-buttons {
  float: left;

  button {
    @include e-btn;
    @include e-btn-onlyicon;
    float: left;
    font-size: 0;

    &:after {
      background: url('remove.svg') no-repeat center center;
      content: '';
      display: inline-block;
      height: 100%;
      position: relative;
      vertical-align: middle;
      width: 100%;
    }

    &:first-child {
      @include e-btn-modifier('primary');

      &:after {
        background-image: url('success-white.svg');
      }
    }
  }
}
