$daterange-box-preset-size: 30px !default;
$daterange-box-content: 280px !default;
$daterange-box-icon: 16px !default;
$daterange-box-width: ((2 * ($calendar-box-size-days * 7 + 2)) + $grid-gutter-width * 2) + $daterange-box-content + 2;

e-daterange {
  display: block;
}

e-daterange[inline] {
  display: inline-block;
}

.e {
  &-daterange {
    top: -99999px;
    z-index: z('flying');

    &-inline {
      display: inline-block;
      min-width: 200px;
    }

    &__picker {
      cursor: pointer;
      line-height: $input-box-height - 2;
      max-width: $daterange-box-width;
      position: relative;
      transition: none;
      user-select: none;
      will-change: box-shadow;

      &-active {
        box-shadow: 0 0 0 10000px rgba(0, 0, 0, .1);
        z-index: z('modal-backdrop');
      }

      &.e-input-disabled {
        cursor: not-allowed;
      }

      .e-icon {
        height: $daterange-box-icon;
        margin-right: 8px;
        margin-top: -3px;
        width: $daterange-box-icon;
      }
    }

    &__value {
      margin-right: 8px;
    }

    &__range {
      font-size: typo('small', 'size');
    }

    &__content {
      background: $color-white;
      border: 1px solid $input-color-border;
      display: inline-block;
      font-size: 0;
      margin: -1px auto;
      padding: $grid-gutter-width / 2;
      width: $daterange-box-width;

      &-inline {
        margin-top: 0;
      }
    }

    &__actions {
      display: inline-block;
      font-size: typo('body', 'size');
      margin-right: $grid-gutter-width / 2;
      vertical-align: top;
      width: $daterange-box-content;
    }

    &__calendars {
      display: inline-block;
      font-size: typo('body', 'size');
      margin-top: 28px;
    }

    &__calendar {
      float: left;

      &-start {
        margin-right: $grid-gutter-width / 2;
      }
    }

    &__input {
      float: left;
      margin-bottom: $grid-gutter-width / 2;
      width: 50%;

      &-start {
        padding-right: 5px;
      }

      &-end {
        padding-left: 5px;
      }
    }

    &__presets {
      border: 1px solid $calendar-color-cell-separator;
    }

    &__preset {
      border-bottom: 1px solid $calendar-color-cell-separator;
      cursor: pointer;
      height: $input-box-height;
      line-height: $input-box-height - 2;
      padding: $input-box-padding;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: $color-hover;
      }

      &-active {
        background: palette('secondary');
        color: $color-white;
        position: relative;

        &:hover {
          background: palette('secondary');
        }

        &:after {
          background: url('success-white.svg') no-repeat top right;
          content: '';
          height: $daterangepicker-box-icon-size;
          position: absolute;
          right: 10px;
          top: 12px;
          width: $daterangepicker-box-icon-size;
        }
      }
    }

    &__border {
      background: $color-white;
      height: 2px;
      position: absolute;
    }

    &[x-placement*='bottom'] {
      .e-daterange__border {
        top: -2px;
      }
    }

    &[x-placement*='top'] {
      .e-daterange__border {
        bottom: -2px;
      }
    }
  }
}
