$campaignselector-color-background: #fff !default;
$campaignselector-box-padding: 8px !default;
$campaignselector-color-list-border: palette('grays', 'separator') !default;

.e {
  &-campaignselector {
    @include e-input;
    background: $campaignselector-color-background;
    cursor: pointer;
    line-height: $input-box-height - 2;
    padding: 0;
    position: relative;
    user-select: none;

    &-opened {
      border: 1px solid $box-color-border;
      box-shadow: $box-box-shadow;

      &:before {
        background: $campaignselector-color-background;
        bottom: -3px;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 503;
      }
    }

    &__button {
      @include text-overflow;
      padding: $input-box-padding;

      &:after {
        background: url('table-sort-desc.svg') no-repeat top right;
        content: '';
        height: 14px;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 14px;
      }
    }

    &__item {
      padding: 0 $daterangepicker-box-padding;
    }

    &__list {
      border: 1px solid $campaignselector-color-list-border;
      height: 210px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    &__campaign {
      padding: 0 $grid-gutter-width / 2;

      small {
        padding-left: 8px;
      }
    }

    &__popup {
      @include e-box;
      background: $campaignselector-color-background;
      margin-left: -1px;
      margin-top: -1px;
      padding-bottom: $grid-gutter-width / 2;
      position: absolute;
      width: 710px;
      z-index: z('dropdown');
    }
  }
}
