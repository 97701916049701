$charttooltip-color-header-background: rgba(240, 240, 240, .8) !default;
$charttooltip-color-border: palette('grays', 'separator') !default;

.e {
  &-charttooltip {
    margin: - $grid-gutter-width / 4;

    &__header {
      @include word-wrap;
      background: $charttooltip-color-header-background;
      font-size: typo('body', 'size');
      line-height: $grid-gutter-width / 2;
      padding: $grid-gutter-width / 4;
    }

    &__table {
      border: $grid-gutter-width / 4 solid transparent;
    }

    &__row {
      border-bottom: 1px solid $charttooltip-color-border;
      margin-bottom: 5px;
      padding-bottom: 5px;
      white-space: normal;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__cell {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    &__name {
      display: table-cell;
      font-size: typo('button', 'small');
    }

    &__value {
      display: table-cell;
      font-size: typo('button', 'size');
      font-weight: typo('button', 'weight');
      text-align: right;
      vertical-align: middle;
    }
  }
}
