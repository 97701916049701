// OLD

@mixin e-table__sort-old() {
  cursor: pointer;

  a {
    color: $color-text;
  }

  &:after {
    content: $table-sort;
    display: inline-block;
    height: 15px;
    left: 4px;
    position: relative;
    top: 2px;
    vertical-align: top;
    width: 14px;
  }
}

@mixin e-table__sort-modifier-old($modifier: 'asc') {
  @if ($modifier == 'asc') {
    &:after {
      content: $table-sort-asc;
    }
  }

  @if ($modifier == 'desc') {
    &:after {
      content: $table-sort-desc;
    }
  }
}

table:not([data-e-version='2']) {
  &.e {
    &-table {
      background: $table-color-background;
      border: 1px solid $table-color-border;
      margin: $table-box-margin;
      max-width: 100%;
      width: 100%;

      .e-stretch & {
        border-left-width: 0;
        border-right-width: 0;
        padding: 5px;

        th,
        td {
          padding-left: $grid-gutter-width / 2;
          padding-right: $grid-gutter-width / 2;
        }
      }

      .e-stretch.e-stretch-top & {
        border-top-width: 0;
      }

      .e-stretch.e-stretch-bottom & {
        border-bottom-width: 0;
      }

      tbody {
        // scss-lint:disable SelectorDepth
        tr {
          transition: $tab-hover-transition;

          &:hover td {
            background: $table-color-background-hover;
            background-clip: padding-box;
          }
        }

        th,
        td {
          background: $table-color-background;
          padding: $table-box-padding-old;
          vertical-align: top;
        }

        td {
          background-clip: padding-box;
          border-bottom: 1px solid $table-color-border;
        }

        tr:last-child td {
          border-bottom-color: $table-color-border;
        }

        .e-table__title {
          font-size: $table-size-title;
          padding: $table-box-padding-title;
        }
      }

      th {
        border-bottom: 1px solid $table-color-border;
        border-top: 1px solid $table-color-border;
        font-weight: $text-bold-weight;
        padding: $table-box-padding-old;
        text-align: left;
        vertical-align: bottom;

        // SI settings
        &.e-table__col-right {
          text-align: right;
        }

        &:focus {
          outline: none;
        }
      }

      tfoot td {
        padding: $table-box-padding-old;
      }

      &-simple {
        tbody {
          tr {
            &:hover td {
              background: transparent;
            }
          }
        }
      }

      &-inner {
        background: transparent;
        border: 0;

        tbody {
          td {
            background: transparent;
            border: 0;
            padding: 2px 0;
            vertical-align: bottom;
          }
        }
      }

      &-summary {
        background: transparent;

        tbody {
          tr {
            &:hover td {
              background: transparent;
            }
          }

          td {
            background: transparent;
            border: 0;
            padding: $table-box-padding-summary;
          }
        }

        tfoot {
          td {
            border-top: 1px solid $table-color-border;
          }
        }
      }

      &-borderless {
        border: 0;

        tbody {
          td {
            border: 0;
          }
        }
      }

      &-bordered {
        thead {
          td,
          th {
            border: 1px solid $table-color-border;
          }
        }

        tbody {
          td {
            border: 1px solid $table-color-border;
          }
        }
      }

      &-centered {
        tbody {
          th,
          td {
            vertical-align: middle;
          }
        }
      }

      &-centeredtext {
        thead {
          th {
            text-align: center;
            vertical-align: middle;
          }
        }

        tbody {
          th,
          td {
            text-align: center;
            vertical-align: middle;
          }
        }
      }

      &-truncated {
        table-layout: fixed;
        vertical-align: middle;
        white-space: nowrap;
        width: 100%;
      }

      &-fixed {
        table-layout: fixed;
        width: 100%;
      }

      // scss-lint:disable SelectorDepth
      &-accordion {
        > tbody {
          > tr {
            > td {
              padding: 0;

              .e-accordion {
                border: 0;
              }

              .e-accordion__title {
                margin: -1px;
              }

              .e-accordion__content {
                background: $color-white;
                border: 0;
              }
            }
          }
        }
      }

      &-modal {
        border: 0;
        margin-bottom: -1px;

        tr:last-child td {
          border-bottom: 0;
        }
      }

      &.e-table-compact {
        tbody {
          th,
          td {
            padding: $table-box-padding-compact;
            vertical-align: middle;
          }
        }
      }

      &.e-table-condensed {
        thead {
          th,
          td {
            padding: $table-box-padding-condensed-old;
          }
        }

        tbody {
          th,
          td {
            padding: $table-box-padding-condensed-old;
            vertical-align: middle;
          }
        }
      }

      &.e-table-nopadding {
        thead {
          th {
            padding: $table-box-padding-nopadding;
          }
        }

        tbody {
          td {
            padding: $table-box-padding-nopadding;
            vertical-align: middle;
          }
        }
      }

      // scss-lint:disable QualifyingElement
      td.e-table,
      th.e-table {
        &__col {
          &-groupheader {
            background: palette('grays', 'body');
            border-bottom-color: $table-color-border;
            box-shadow: inset 0 2px 0  $table-color-background;
            font-weight: $text-bold-weight;
            padding: 18px 10px;
            text-align: center;

            &:hover {
              background: palette('grays', 'body');
            }

            &-leftaligned {
              background: transparent;
              font-size: typo('subheader', 'size');
              text-align: left;

              &:hover {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }

  * {
    &.e {
      &-table {
        &__col {
          &-right {
            text-align: right;
          }

          &-actions {
            text-align: right;
            user-select: none;
            white-space: nowrap;
            width: 110px;
          }

          &-filter {
            background: $table-color-filter-background;
            height: $table-box-filter-height;
            padding: 0 10px;
          }

          &-bottom {
            vertical-align: bottom;
          }

          &-small {
            white-space: nowrap;
            width: 120px;
          }

          &-truncated {
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &__sort {
          @include e-table__sort-old;

          &-asc {
            @include e-table__sort-modifier-old('asc');
          }

          &-desc {
            @include e-table__sort-modifier-old('desc');
          }
        }

        // Segments overview
        &__button {
          float: right;
          margin-top: 6px;
        }

        &__settings {
          float: right;
          margin-right: -8px;
          padding-bottom: 20px;
          padding-top: 20px;
        }

        &__action { // in case the action buttons are not links
          cursor: pointer;
        }

        &__datatablefilter {
          padding-left: 260px;
          position: relative;

          &-advanced {
            margin-bottom: $grid-gutter-width / 2;
            top: $grid-gutter-width / 4;
          }
        }

        &__advancedfilters {
          background: $table-color-advancedfilter-background;
          height: 0;
          overflow: hidden;
          transition: height $transition-time ease;

          &-active {
            height: 60px;
          }
        }
      }
    }
  }
}
