$tab-box-padding-content: 15px !default;
$tab-box-padding-title: 8px 0 5px !default;
$tab-color-border: palette('grays', 'separator') !default;
$tab-color-content-background: $color-white !default;
$tab-color-title: palette('default') !default;
$tab-color-title-active: map-get($palette_navigation, 'background') !default;
$tab-color-title-disabled: palette('default', 'x-light') !default;
$tab-color-title-hover: palette('default') !default;
$tab-color-title-hover-background: palette('grays', 'inactive') !default;
$tab-text-size-content: typo('body', 'size') !default;
$tab-text-size-title: typo('subheader', 'size') !default;
$tab-max-tabs: 20 !default;
$tab-hover-transition: all $transition-time ease-in-out !default;

@mixin e-tabs {
  font-size: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin e-tabs-title {
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  color: $tab-color-title;
  cursor: pointer;
  display: inline-block;
  font-size: $tab-text-size-title;
  margin-bottom: -1px;
  padding: $tab-box-padding-title;
  position: relative;
  transition: $tab-hover-transition;

  a {
    color: inherit;
  }

  &:hover {
    background: palette('grays', 'inactive');
    border-bottom-color: $tab-color-border;
    color: $tab-color-title-hover;
  }
}

@mixin e-tabs-title-modifier($modifier: 'active') {
  @if ($modifier == 'active') {
    background: $tab-color-content-background;
    border-bottom-color: transparent;
    border-left-color: $tab-color-border;
    border-right-color: $tab-color-border;
    box-shadow:
      inset 0 3px 0 $tab-color-title-active,
      -3px 0 3px -2px rgba($color-black, 0),
      3px 0 3px -2px rgba($color-black, .1),
      0 -3px 3px -2px rgba($color-black, .1);
    color: $tab-color-title-active;
    transform: translateZ(0);

    &:hover {
      background: $tab-color-content-background;
      border-bottom-color: transparent;
      color: $tab-color-title-active;
      cursor: default;
    }
  }

  @if ($modifier == 'disabled') {
    color: $tab-color-title-disabled;

    &:hover {
      background: transparent;
      border-bottom-color: transparent;
      color: $tab-color-title-disabled;
      cursor: default;
    }
  }
}

@mixin e-tabs-separator {
  border-right: 1px solid lighten($tab-color-border, 5);
  box-shadow: 1px 0 0 rgba($color-white, .8);
  display: inline-block;
  font-size: $tab-text-size-title;
  margin-right: -3px;
  padding: 2px 18px 2px 15px;
  text-decoration: none;
}

@mixin e-tabs-active-separator {
  border-color: transparent;
  box-shadow: none;
}

.e {
  &-tabs {
    @include e-tabs;

    &-embed {
      .e-tabs__title:first-child,
      & > input:first-child + .e-tabs__title {
        border-left: 0;
      }

      .e-tabs__panel__content {
        border: 0;
        border-top: 1px solid $tab-color-border;
      }
    }

    &-dialogheader {
      border-bottom: 1px solid $tab-color-border;
      line-height: $text-lineheight;

      .e-tabs__title:first-child,
      & > input:first-child + .e-tabs__title {
        margin-left: 15px;
      }
    }

    &__title {
      @include e-tabs-title;

      &:hover {
        .e-tabs__separator {
          @include e-tabs-active-separator;
        }
      }

      &-active {
        @include e-tabs-title-modifier('active');

        .e-tabs__separator {
          @include e-tabs-active-separator;
        }

        .e-tabs__badge {
          opacity: 1;
        }
      }

      &-disabled {
        @include e-tabs-title-modifier('disabled');

        .e-tabs__badge {
          opacity: .5;
        }

        &:hover {
          .e-tabs__separator {
            @include e-tabs-separator;
          }
        }
      }
    }

    &__separator,
    &__separator:active,
    &__separator:visited {
      @include e-tabs-separator;
    }

    &__badge {
      font-size: 11px;
      opacity: .8;
      padding-left: 15px;

      e-icon {
        vertical-align: sub;
      }
    }

    &__panel {
      &__content {
        background: $tab-color-content-background;
        border: 1px solid $tab-color-border;
        display: none;
        font-size: $tab-text-size-content;
        padding: $tab-box-padding-content;

        &-active {
          display: block;
        }
      }
    }

    &-inner {
      .e-tabs__title:first-of-type {
        border-left-width: 0;
      }

      .e-tabs__panel__content {
        border: 0;
        border-top: 1px solid $tab-color-border;
      }
    }

    > .e-btn {
      margin-right: $grid-gutter-width / 2;
    }

    > input {
      display: none;

      &:checked + .e-tabs__title {
        @include e-tabs-title-modifier('active');

        .e-tabs__separator {
          @include e-tabs-active-separator;
        }
      }

      &:disabled + .e-tabs__title {
        @include e-tabs-title-modifier('disabled');

        &:hover {
          .e-tabs__separator {
            @include e-tabs-separator;
          }
        }
      }
    }

    @for $i from 1 through $tab-max-tabs {
      & > input:checked:nth-of-type(#{$i}) ~ .e-tabs__panel > .e-tabs__panel__content:nth-child(#{$i}) {
        display: block;
      }
    }
  }
}

a.e-tabs__title {
  color: $tab-color-title;
}

a.e-tabs__title-active {
  color: $tab-color-title-active;
}
