.e {
  &-toggle {
    &__text {
      margin: 0 $grid-gutter-width / 4;

      &-open {
        display: none;
      }
    }

    &.e-toggle-opened {
      .e-toggle__text {
        display: none;
      }

      .e-toggle__text-open {
        display: inline;
      }
    }
  }
}
