// scss-lint:disable SelectorFormat SelectorDepth PlaceholderInExtend NameFormat
.dataTables_wrapper {
  position: relative;
}

.dataTable {
  thead {
    td {
      height: $table-box-filter-height;
    }
  }

  tfoot {
    td {
      height: $table-box-filter-height;
    }
  }

  .right {
    text-align: right;
    user-select: none;
    white-space: nowrap;
    width: 110px;
  }
}

.dataTable[data-e-version='2'] {
  .sorting {
    @include e-table__sort;
  }

  .sorting_asc {
    @include e-table__sort;
    @include e-table__sort-modifier('asc');
  }

  .sorting_desc {
    @include e-table__sort;
    @include e-table__sort-modifier('desc');
  }
}

.dataTable:not([data-e-version='2']) {
  .sorting {
    @include e-table__sort-old;
  }

  .sorting_asc {
    @include e-table__sort-old;
    @include e-table__sort-modifier-old('asc');
  }

  .sorting_desc {
    @include e-table__sort-old;
    @include e-table__sort-modifier-old('desc');
  }
}

.dataTables_info {
  display: none;
}

.dataTables_empty {
  text-align: center;
}

.dataTables_paginate {
  @include clearfix;
  bottom: 12px;
  margin-right: 1px;
  position: absolute;
  right: 15px;
  text-align: right;

  > span {
    float: left;

    > span {
      @include e-pagination-item;
      @include e-pagination-item-modifier('disabled');
    }
  }

  .paginate_button {
    @include e-pagination-item;
    cursor: pointer;

    &.current {
      @include e-pagination-item-modifier('active');
    }

    &.disabled {
      @include e-pagination-item-modifier('disabled');
    }

    &.previous,
    &.next {
      font-size: 0;

      &.disabled {
        &:before {
          opacity: .5;
        }
      }

      &:before {
        content: '';
        height: 20px;
        left: 0;
        line-height: 1;
        margin: auto;
        min-width: 0;
        position: absolute;
        right: 0;
        top: 6px;
        width: 20px;
      }
    }

    &.previous {
      &:before {
        background: url('caret-left.svg');
      }

      &.disabled {
        &:hover {
          &:before {
            background: url('caret-left.svg');
          }
        }
      }

      &:hover {
        &:before {
          background: url('caret-left-white.svg');
        }
      }
    }

    &.next {
      border-right: 1px solid $pagination-color-border;

      &:before {
        background: url('caret-right.svg');
      }

      &.disabled {
        &:hover {
          &:before {
            background: url('caret-right.svg');
          }
        }
      }

      &:hover {
        &:before {
          background: url('caret-right-white.svg');
        }
      }
    }
  }
}

.dataTables_length {
  bottom: 12px;
  left: 15px;
  position: absolute;

  .e-select {
    margin: 0 5px;
  }
}

.dataTables_filter {
  left: 15px;
  position: absolute;
  top: 13px;
  z-index: z('tooltip');

  label {
    @extend .e-field__label;
    @extend .e-field__label-small;
  }

  input {
    @include e-input;
    @include e-input-size('large');
    margin-left: 10px;
  }
}

.table-action-icon {
  &.copy,
  &.dropdown-copy,
  &.delete,
  &.dropdown-delete {
    background: transparent;
    height: auto;
    top: 0;
    width: auto;
  }

  &.refresh {
    background: url('refresh.svg');
    height: 14px;
    margin: 0 5px;
    position: relative;
    top: 2px;
    width: 14px;

    &.disabled {
      opacity: .3;
    }

    &.running {
      animation: e-spin 2s infinite linear;
    }
  }
}

@keyframes e-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
