$uitooltip-color-background: $tooltip-color-backgronud !default;
$uitooltip-color-border: $tooltip-color-border !default;

.ui-tooltip {
  background: $uitooltip-color-background;
  border: 1px solid $uitooltip-color-border;
  box-shadow: 0 2px 4px rgba($color-black, .1);
  max-width: 300px;
  padding: .533em 1em;
  position: absolute;
  z-index: z('tooltip');
}

