$progress-box-height: 20px !default;
$progress-box-height-small: 12px !default;
$progress-box-width-bar: 40px !default;
$progress-color-background: palette('grays', 'inactive') !default;
$progress-color-percent: #fff !default;
$progress-color-shadow: rgba(#666, .2);
$progress-color-shadow-inverse: rgba(#000, .2);

.e {
  &-progress {
    background: $progress-color-background;
    box-shadow: inset 0 2px 4px $progress-color-shadow;
    display: block;
    height: $progress-box-height;
    margin: 0 auto;
    position: relative;

    &-static {
      .e-progress {
        &__bar {
          animation: initial;
          background-image: none;
        }
      }
    }

    &-small {
      height: $progress-box-height-small;

      .e-progress__bar {
        height: $progress-box-height-small;

        &:after {
          font-size: typo('small', 'size');
          top: -2px;
        }
      }
    }

    &-indicator {
      background: transparent;
      box-shadow: none;
      margin-right: 50px;

      .e-progress__bar {
        &:after {
          color: $color-text;
          left: 100%;
          margin-left: 8px;
          text-align: left;
          width: auto;
        }
      }
    }

    &-inverse {
      background: $color-white;
      box-shadow: inset 0 2px 4px $progress-color-shadow-inverse;
    }

    &-has_label {
      margin-bottom: 21px;
    }

    &__label {
      display: flex;
      font-size: typo('small', 'size');
      justify-content: space-between;
      padding-top: 3px;
      position: relative;
      top: 100%;
    }

    &__bar {
      animation: progress 1s infinite linear;
      background-color: palette('info');
      background-image: linear-gradient(45deg, rgba($color-white, .15) 25%, transparent 25%, transparent 50%, rgba($color-white, .15) 50%, rgba($color-white, .15) 75%, transparent 75%);
      background-repeat: repeat-x;
      background-size: $progress-box-width-bar $progress-box-width-bar;
      display: inline-block;
      height: $progress-box-height;
      position: absolute;
      transition: width $transition-time-long ease-in-out;

      // Extend main colors with des colors
      @each $class, $data in $progress_style_des {
        &-#{$class} {
          background-color: map-get($data, 'color');
        }
      }

      &:after {
        color: $progress-color-percent;
        content: attr(data-e-percent);
        display: inline-block;
        position: absolute;
        text-align: center;
        width: 100%;
      }
    }
  }
}

@keyframes progress {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: $progress-box-width-bar 0;
  }
}
