@import 'device-push';

e-device {
  display: inline-block;
  overflow: hidden;
  position: relative;

  &[readonly]:after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

e-device-content {
  background-image: url(https://assets.emarsys.net/ui/static/images/e-device/e-device-content-wallpaper.jpg);
}

.e {
  &-device {
    &__frame {
      background-image: url(https://assets.emarsys.net/ui/static/images/e-device/e-device-iphone_6.svg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__content,
    e-device-content {
      display: block;
      height: 667px;
      left: 0;
      max-width: none;
      position: absolute;
      text-align: left;
      top: 0;
      transform-origin: 0 0;
      width: 375px;

      .e-tokeninput__editable,
      .e-tokeninput__content {
        font-family: Arial, sans-serif;
        padding: 0;
      }

      .e-tokeninput__token {
        line-height: 18px;
        padding: 0;

        &:before,
        &:after {
          content: '\00a0';
        }
      }
    }

    &[type='computer'] {
      .e-device__frame {
        background-image: url(https://assets.emarsys.net/ui/static/images/e-device/e-device-computer.svg);
      }

      .e-device__content,
      e-device-content {
        height: 720px;
        width: 1280px;
      }
    }

    &[type='android'] {
      .e-device {
        &__content,
        e-device-content {
          height: 640px;
          width: 360px;
        }

        &__frame {
          background-image: url(https://assets.emarsys.net/ui/static/images/e-device/e-device-android.svg);
        }

        &__led {
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-name: pulse;
          background-color: currentColor;
          border-radius: 100%;
          box-shadow: 0 0 10px;
          color: transparent;
          position: absolute;
        }

        @keyframes pulse {
          from {
            box-shadow: 0 0 10px;
          }

          50% {
            box-shadow: 0 0 18px;
          }

          to {
            box-shadow: 0 0 10px;
          }
        }
      }
    }
  }

  &-device-content {
    background-color: $color-white;
    background-image: url(https://assets.emarsys.net/ui/static/images/e-device/e-device-content-messagelist_applemail_ios10.3.2.svg);
    bottom: 0;
    font-family: Arial, sans-serif;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &-lockscreen {
      background-color: transparent;
      background-image: url(https://assets.emarsys.net/ui/static/images/e-device/e-device-content-lockscreen_applemail_ios10.3.2.svg);
      background-repeat: no-repeat;
      background-size: 375px 667px;

      .e-device-content {
        &__notification {
          background-color: #abcec8;
          border-radius: 13px;
          margin: 7px;
          padding-bottom: 7px;
          padding-top: 40px;
          position: relative;
          top: 192px;
          width: 360px;

          &:after {
            background-image: url(https://assets.emarsys.net/ui/static/images/e-device/e-device-content-lockscreen_icon_applemail_ios10.3.2.png);
            background-repeat: no-repeat;
            content: 'MAIL';
            font-size: 11px;
            height: 20px;
            left: 6px;
            position: absolute;
            text-indent: 28px;
            top: 6px;
          }

          &:before {
            background-color: #b7dbd1;
            border-top-left-radius: 13px;
            border-top-right-radius: 13px;
            content: '';
            height: 32px;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        &__fromname,
        &__subject,
        &__preheader {
          color: #000;
          display: block;
          font-size: 13px;
          left: 0;
          line-height: 18px;
          margin-left: 13px;
          margin-right: 13px;
          position: relative;
          top: 0;
        }

        &__fromname {
          letter-spacing: .1px;
        }

        &__time,
        &__date {
          color: #fff;
          font-size: 80px;
          font-weight: normal;
          left: 0;
          line-height: 1;
          position: absolute;
          right: 0;
          text-align: center;
          top: 73px;
        }

        &__date {
          display: block;
          font-size: 21px;
          top: 155px;
        }

        &__preheader:not(:empty) {
          background-color: transparent;

          &:after {
            content: none;
          }
        }

        &__preheader,
        &__preheader .e-tokeninput__editable-multiline {
          min-height: 36px;
        }

        &__subject {
          font-weight: bold;
        }

        &__timeago {
          display: block;
          font-size: 11px;
          position: absolute;
          right: 22px;
          text-align: right;
          top: 206px;
        }
      }
    }

    &__fromname,
    &__subject,
    &__preheader {
      color: #000;
      font-size: 15px;
      font-weight: bold;
      left: 27px;
      position: absolute;
      right: 27px;
      top: 114px;

      &:before {
        color: rgba(0, 0, 0, .3);
        content: attr(fake-placeholder);
        font-style: italic;
        font-weight: normal;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
      }
    }

    &__fromname,
    &__subject {
      .e-tokeninput__editable,
      .e-tokeninput__content {
        line-height: inherit;
        min-height: inherit;
      }
    }

    &__fromname {
      @include text-overflow();
      font-size: 17px;
      line-height: 28px;
      min-height: 28px;
      right: 70px;
    }

    &__subject {
      @include text-overflow();
      font-weight: normal;
      line-height: 22px;
      min-height: 22px;
      top: 134px;
    }

    &__preheader,
    &__time {
      color: #999;
      font-weight: normal;
      letter-spacing: .25px;
      line-height: 21px;
      top: 154px;
    }

    &__preheader:not(:empty) {
      background: #fff;

      &:after {
        background-color: #fff;
        border-bottom: 1px solid #e8e8e8;
        bottom: -8px;
        content: '';
        height: 8px;
        left: 0;
        position: absolute;
        right: -27px;
      }
    }

    &__preheader,
    &__preheader .e-tokeninput__editable-multiline {
      min-height: 42px;
    }

    &__time {
      font-size: 15px;
      position: absolute;
      right: 31px;
      top: 116px;
    }

    &__date,
    &__timeago {
      display: none;
    }
  }
}
