.e-numberinput {
  cursor: text;
  line-height: $input-box-height - 2;
  outline: 0;
  position: relative;
  user-select: none;

  &:read-only:focus {
    @include e-input-modifier('focus');
  }

  &:focus {
    .e-numberinput__content {
      background: palette('secondary', 'x-light');
    }
  }

  &__arrow {
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 0;
    width: 15px;

    &-up {
      top: 2px;
    }

    &-down {
      bottom: 2px;
    }
  }
}
