*,
*:before,
*:after {
  box-sizing: border-box;
}

*::selection {
  background: palette('secondary', 'x-light');
}

*::-moz-selection {
  background: palette('secondary', 'x-light');
}

html,
body {
  background: palette('grays', 'body');
  color: palette('grays', 'text');
  font-family: typo('body', 'family');
  font-size: typo('body', 'size');
  -webkit-font-smoothing: antialiased;
  font-weight: typo('body', 'weight');
  height: 100%;
  line-height: $text-lineheight;
}

hr {
  background: palette('grays', 'separator');
  border: 0;
  height: 1px;
}

// scss-lint:disable QualifyingElement
input[type=text] {
  &::-ms-clear {
    display: none;
  }
}

h1,
%h1 {
  color: map-get($palette_navigation, 'background');
  font-family: typo('headline', 'family');
  font-size: typo('headline', 'size');
  font-weight: typo('headline', 'weight');
  margin: 0 0 8px;
}

h2,
%h2 {
  font-family: typo('title', 'family');
  font-size: typo('title', 'size');
  font-weight: typo('title', 'weight');
  margin-bottom: 15px;
  margin-top: 0;
}

h3,
%h3 {
  font-family: typo('subheader', 'family');
  font-size: typo('subheader', 'size');
  font-weight: typo('subheader', 'weight');
  margin-bottom: 5px;
  margin-top: 0;
}

h1,
h2,
h3 {
  a,
  a:active,
  a:visited,
  a:hover {
    color: inherit;
  }
}

p {
  margin-top: 0;
}

b,
strong {
  font-weight: $text-bold-weight;

  font & {
    font-weight: bold;
  }

  [contenteditable='true'] & { // <Chrome45 bug
    -webkit-font-smoothing: initial;
    font-weight: bold;
  }
}

a {
  &,
  &:visited {
    color: palette('secondary');
    text-decoration: none;
    transition: color $transition-time ease-in-out;
  }

  &:active {
    color: lighten(palette('secondary'), $hover-modifier-amount);
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: darken(palette('secondary'), $hover-modifier-amount);
    outline: none;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

//
// Address inconsistent and variable font size in all browsers.
//

small {
  // font-size: typo('small', 'size');
  font-size: 70%;

  h1 & {
    color: $color-text;
    font-size: 50%;
    margin-left: 4px;
  }
}

//
// Address styling not present in IE 8/9.
//

mark {
  // scss-lint:disable all
  // TODO
  background: #ff0;
  color: #000;
}

//
// Prevent `sub` and `sup` affecting `line-height` in all browsers.
//

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

//
// Address margin not present in IE 8/9 and Safari.
//

figure {
  margin: 1em 40px;
}

//
// Define consistent border, margin, and padding.
//

fieldset {
  // scss-lint:disable all
  // TODO
  border: 0;
  margin: 0;
  padding: 10px 0;
}

//
// 1. Correct `color` not being inherited in IE 8/9/10/11.
// 2. Remove padding so people aren't caught out if they zero out fieldsets.
//

legend {
  border: 0; // 1
  padding: 0; // 2
}

iframe {
  border: 0;
  max-width: 100%;
}

// on loginpage, the fingerprint object made gap and scrollbar
// scss-lint:disable IdSelector
#fingerprintjs2 {
  bottom: 0;
  position: absolute;
}
