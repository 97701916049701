$pagination-size: $box-form-element-height !default;
$pagination-color: palette('grays', 'text') !default;
$pagination-color-background: #fff !default;
$pagination-color-background-hover: palette('secondary') !default;
$pagination-color-border: palette('default', 'x-light') !default;
$pagination-color-hover: #fff !default;
$pagination-color-svg: #666 !default;
$pagination-opacity-disabled: .5 !default;
$pagination-opacity-svg: 1 !default;
$pagination-opacity-svg-hover: 1 !default;
$pagination-text-size: typo('button', 'size') !default;
$pagination-hover-transition: all $transition-time ease-in-out !default;

@mixin e-pagination-item {
  border: 1px solid $pagination-color-border;
  color: $pagination-color;
  cursor: pointer;
  float: left;
  font-size: $pagination-text-size;
  height: $box-form-element-height;
  line-height: $pagination-size - 2;
  margin-right: -1px;
  min-width: $box-form-element-height;
  padding: 0 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: $btn-hover-transition;
  user-select: none;

  &:visited {
    color: $pagination-color;
  }

  &:hover {
    background: $pagination-color-background-hover;
    color: $pagination-color-hover;

    svg {
      fill: $pagination-color-hover;
      opacity: $pagination-opacity-svg-hover;
    }
  }
}

@mixin e-pagination-item-modifier($modifier: 'default') {
  @if ($modifier == 'active') {
    background: $pagination-color-background-hover;
    color: $pagination-color-hover;
    cursor: default;

    &:visited {
      color: $pagination-color-hover;
    }
  }

  @if ($modifier == 'disabled') {
    cursor: default;
    opacity: $pagination-opacity-disabled;

    &:hover {
      background: $pagination-color-background;
      color: $pagination-color;
    }
  }
}

.e {
  &-pagination {
    @include clearfix;
    margin-right: 1px;

    &__item {
      @include e-pagination-item;

      &-prev,
      &-next {
        > .e-icon,
        e-icon {
          fill: $pagination-color-svg;
          height: 20px;
          left: 6px;
          opacity: $pagination-opacity-svg;
          position: absolute;
          top: 6px;
          width: 20px;
        }

        e-icon {
          height: auto;
          width: auto;

          .e-icon {
            height: 20px;
            width: 20px;
          }
        }
      }

      &-next {
        > .e-icon,
        e-icon {
          left: 7px;
        }
      }

      &-active {
        @include e-pagination-item-modifier('active');
      }

      &-disabled {
        @include e-pagination-item-modifier('disabled');

        &:hover {
          svg {
            fill: $pagination-color-svg;
            opacity: $pagination-opacity-svg;
          }
        }
      }
    }
  }
}
