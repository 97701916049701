$fieldset-color-border: palette('grays', 'separator') !default;
$fieldset-color-border-dark: palette('default', 'x-light') !default;
$fieldset-color-chart-background: palette('grays', 'body') !default;
$fieldset-color-chart-background-active: #fff !default;
$fieldset-box-padding: $grid-gutter-width / 3 $grid-gutter-width / 3 0 $grid-gutter-width / 3 !default;
$fieldset-box-padding-chart: $grid-gutter-width / 5 !default;

.e {
  &-fieldset {
    border: 1px solid $fieldset-color-border;
    margin-bottom: $grid-gutter-width / 3;
    padding: 0;

    &-chart {
      border: 0;
      margin-bottom: 0;
    }

    &-legend-top {
      position: absolute;
      top: 0;
      width: 100%;
    }

    &-legend-bottom {
      border-top: 1px solid $fieldset-color-border;
      margin-bottom: -1px;
    }

    &-bottom {
      border-top: 1px solid $fieldset-color-border;
      bottom: -1px;
      position: absolute;
      width: 100%;
    }

    &-sidebar {
      border-color: $fieldset-color-border-dark;
      border-left: 0;
      height: 239px;
      margin-bottom: 0;
      overflow: auto;

      .e-fieldset__item {
        border-left: 1px solid $fieldset-color-border-dark;
        padding-right: 20px;
        position: static;
        white-space: nowrap;

        &-chart-active {
          position: relative;
        }

        &:last-child {
          margin-bottom: -1px;
        }
      }
    }

    &__item {
      cursor: pointer;
      padding: $fieldset-box-padding;
      position: relative;

      &-header {
        border-bottom: 1px solid $fieldset-color-border;
        margin-bottom: -1px;
      }

      &:last-child {
        padding-bottom: $grid-gutter-width / 3;
      }

      &-chart {
        background: $fieldset-color-chart-background;
        border-bottom: 1px solid $fieldset-color-border;
        padding: $fieldset-box-padding-chart;
        position: relative;

        &:last-child {
          padding-bottom: $fieldset-box-padding-chart;
        }

        &-active {
          background: $fieldset-color-chart-background-active;
          margin-left: -1px;
          padding-left: $fieldset-box-padding-chart + 1;
        }
      }

      &__indicator {
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 3px;
      }

      .e-checkbox + .e-legend {
        display: inline-block;

        &:before,
        &:after {
          bottom: -1px;
          top: auto;
        }

        &:after {
          bottom: 2px;
        }

        .e-legend__value {
          margin-bottom: 4px;
        }
      }
    }
  }
}
