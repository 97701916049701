$field-box-margin: 0 0 17px 0 !default;
$field-box-margin-label: 0 0 4px 0 !default;
$field-text-size: typo('subheader', 'size') !default;
$field-prefix-box-padding: 1em !default;
$field-colorpicker-size: 33px !default;
$field-prefix-size-icon: 1.154em !default; // 15px
$field-prefix-color-icon: palette('default') !default;

.e {
  &-field {
    margin: $field-box-margin;
    position: relative;

    &-fixwidth {
      max-width: 400px;
    }

    &-inline {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 10px;
      padding: 12px 0;
    }

    &__postfix {
      border-collapse: separate;
      display: table;
      width: 100%;

      &__text {
        background: palette('grays', 'body');
        border: 1px solid palette('default', 'x-light');
        border-left-width: 0;
        color: $btn-color-text;
        display: inline-block;
        font-size: $btn-text-size-small;
        font-weight: $btn-text-weight;
        line-height: $btn-box-height - 2;
        padding: $btn-box-padding;
        text-align: center;
        vertical-align: middle;
      }

      &__cell {
        display: table-cell;
        vertical-align: middle;
        width: 100%;

        &-short {
          white-space: nowrap;
          width: 1%;
        }

        &-shortsticky {
          font-size: 0;
          white-space: nowrap;
          width: 1%;

          .e-btn {
            border-left: 0;
          }
        }
      }

      &-reverse {
        .e-field__postfix__text {
          border-left-width: 1px;
          border-right-width: 0;
        }

        .e-field__postfix__cell-short { // subpixel fix
          box-shadow: 0 1px 0 0 palette('default', 'x-light') inset, 0 -1px 0 0 palette('default', 'x-light') inset;
        }
      }
    }

    &__prefix {
      display: inline-block;
      position: relative;

      &__icon {
        left: $field-prefix-box-padding;
        line-height: 0;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: z('default');

        .e-icon {
          fill: $field-prefix-color-icon;
          height: $field-prefix-size-icon;
          width: $field-prefix-size-icon;
        }
      }

      .e-input {
        padding-left: ($field-prefix-box-padding * 2) + $field-prefix-size-icon;
      }

      &-block {
        display: block;
      }
    }

    &__label {
      display: block;
      font-size: $field-text-size;
      font-weight: typo('subheader', 'weight');
      margin: $field-box-margin-label;

      &-small {
        font-size: typo('body', 'size');
      }

      &-inline {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 5px;
        vertical-align: middle;
      }

      &__info {
        float: right;
      }

      &-required {
        &:after {
          color: palette('danger');
          content: '*';
          left: 4px;
          position: relative;
          top: 2px;
        }
      }
    }

    &__color {
      border-right: 1px solid palette('default', 'x-light');
      display: inline-block;
      height: $field-colorpicker-size;
      margin: 1px 0 0 -12px;
      vertical-align: middle;
      width: $field-colorpicker-size + 2px;
    }

    &__message {
      display: block;
      line-height: $text-lineheight-condensed;
      padding: 5px 0;

      &:empty {
        display: none;
      }

      &-error {
        color: palette('danger');
      }
    }

    &__indented {
      padding-left: $grid-gutter-width / 2;

      &-checkbox {
        padding-left: 24px;
      }
    }
  }
}
