/*
  This file was automatically generated from variable json files 
*/$box-form-element-height: 35px;

$z-indexes: (
  below: -1,
  default: 1,
  tooltip: 2,
  dropdown-backdrop: 3,
  dropdown: 4,
  fixed-element: 100,
  topnav: 601,
  shame-default: 101,
  modal-backdrop: 501,
  modal: 600,
  flying: 602
);

$palettes: (
  grays: (
    full: #000000,
    text: #333333,
    shade: #999999,
    separator: #dddddd,
    inactive: #f3f3f3,
    placeholder: #cccccc,
    zebra: #fafafa,
    body: #fbfbfb,
    empty: #ffffff
  ),
  primary: (
    default: #81c980,
    light: #9ad499,
    x-light: #c0e4bf
  ),
  secondary: (
    default: #6597cf,
    light: #7cabdf,
    x-light: #b2d1f2
  ),
  success: (
    default: #65b864,
    light: #81ef7f,
    x-light: #baf6b9
  ),
  danger: (
    default: #d86762,
    light: #e77974,
    x-light: #f2b5b4
  ),
  dark: (
    default: #7993ad,
    light: #88a3be,
    x-light: #beccdb,
    xx-light: #f5f7f9
  ),
  info: (
    default: #39aad3,
    light: #48bde7,
    x-light: #9bdbf2
  ),
  default: (
    default: #666,
    light: #929e9e,
    x-light: #c8ceca
  ),
  warning: (
    default: #eca24a,
    light: #fbb15a,
    x-light: #f6f5b2
  ),
  alert: (
    default: #9244b5,
    light: #d177fa,
    x-light: #e6b4fc
  )
);

$palette_reporting: (
  opened: #65B964,
  mobile_opened: #86DA85,
  desktop_opened: #3b855a,
  sent: #012851,
  clicked: #4A4EB2,
  mobile_clicked: #6E71C2,
  desktop_clicked: #424e88,
  mobile_click_through: #00c9b4,
  delivered: #7993AC,
  bounced: #A6AC6C,
  purchased: #9F6E5D,
  unsubscribed: #D86761,
  complained: #ECA24B,
  optin_users: #ECA24B
);

$palette_socialads: (
  impressions: #65b964,
  socialreach: #5fce5e,
  reach: #86da85,
  likes: #3b3e8e,
  clicks: #4a4eb2,
  pageclick: #6e71c2,
  cpc: #6d70c2,
  frequency: #d86761,
  comments: #eca24b,
  conversions: #7f584a,
  spent: #8f6354,
  revenue: #9f6e5d,
  cpm: #bc998c,
  costperconversion: #c5a79d
);

$palette_navigation: (
  background: #002838,
  background-active: #00162b,
  background-hover: #0f344a,
  text: #b6bec3,
  text-active: #ffffff,
  text-hover: #ffffff
);

$palette_smartinsight: (
  current_period: #6597cf,
  comparison_period: #9bdbf2
);

$size: (
  small: (
    width: 65px
  ),
  medium: (
    width: 100px
  ),
  large: (
    width: 200px
  )
);

$alert_style: (
  default: (
    color: #666,
    background: #c8ceca,
    icon: e-info-circle
  ),
  info: (
    color: #39aad3,
    background: #9bdbf2,
    icon: e-info-circle
  ),
  warning: (
    color: #eca24a,
    background: #f6f5b2,
    icon: exclamation-circle
  ),
  success: (
    color: #65b864,
    background: #baf6b9,
    icon: e-check-circle
  ),
  danger: (
    color: #d86762,
    background: #f2b5b4,
    icon: warning
  )
);

$btn_style: (
  default: (
    background: #666,
    color: #fff
  ),
  primary: (
    background: #81c980,
    color: #fff
  ),
  secondary: (
    background: #6597cf,
    color: #fff
  ),
  dark: (
    background: #7993ad,
    color: #fff
  ),
  danger: (
    background: #d86762,
    color: #fff
  ),
  warning: (
    background: #eca24a,
    color: #fff
  )
);

$dropdown_style: (
  danger: #d86762,
  success: #65b864
);

$dropdown-box-arrow-size: 12px;

$dropdown-color-inner-background: #ffffff;

$dropdown-color-text: #333333;

$dropdown-hover-transition: all .2s ease-in-out;

$dropdown-box-padding: 5px 20px 5px 10px;

$dropdown-box-padding-righticon: 5px 10px;

$dropdown-text-size: 13px;

$icon-size-badge: 12px;

$icon-size-small: 14px;

$icon-size: 24px;

$icon-size-middle: 50px;

$icon-size-large: 80px;

$icon-color-tooltip: #002850;

$icon-color-tooltip-background: #d0def0;

$icon-color: #666;

$icon-color-white: #fff;

$label_style: (
  default: (
    background: #39aad3
  ),
  primary: (
    background: #81c980
  ),
  secondary: (
    background: #6597cf
  ),
  info: (
    background: #39aad3
  ),
  danger: (
    background: #d86762
  ),
  warning: (
    background: #eca24a
  )
);

$notice_style: (
  default: (
    color: #39aad3,
    icon: e-info-circle
  ),
  warning: (
    color: #eca24a,
    icon: exclamation-circle
  ),
  success: (
    color: #65b864,
    icon: e-check-circle
  ),
  danger: (
    color: #d86762,
    icon: warning
  ),
  neutral: (
    color: #999999,
    icon: e-info-circle
  )
);

$notice_style_noc: (
  from-emarsys: (
    color: #81c980,
    icon: e-b2clogo-simple
  ),
  segment-generation: (
    color: #b5b439,
    icon: users
  ),
  automation-center: (
    color: #d69237,
    icon: ac-icon_connect
  ),
  data-import: (
    color: #39aad3,
    icon: user-plus
  ),
  campaign-launch: (
    color: #7725ab,
    icon: envelope
  ),
  dns-fasttrack: (
    color: #9f6e5d,
    icon: e-rocket
  ),
  achievement: (
    color: #929e9e,
    icon: e-thumbs-up
  ),
  visual-content-editor: (
    color: #39aad3,
    icon: e-blocks
  )
);

$overlay-color-background: rgba(0, 0, 0, .5);

$overlay-color-background-inverse: rgba(255, 255, 255, .8);

$progress_style_des: (
  success: (
    color: #65b864
  ),
  warning: (
    color: #eca24a
  ),
  danger: (
    color: #d86762
  )
);

$steps_box_size: 50px;

$table-col-size: (
  auto: 1%,
  xxsmall: 3%,
  xsmall: 5%,
  small: 10%,
  medium: 15%,
  large: 20%,
  xlarge: 25%
);

$fixed-topnav-height: 60px;

$fixed-footer-height: 60px;

$fixed-breadcrumb-height: 30px;

$transition-time: .2s;

$transition-time-long: .3s;

$grid-gutter-width: 30px;

$grid-columns: 12;

$screen-sizes: (
  x-small: 480px,
  small: 1024px,
  topnav: 1150px,
  medium: 1280px,
  large: 1600px
);

$text-size: 13px;

$text-lineheight: 1.667;

$text-lineheight-condensed: 1.267;

$text-bold-weight: 500;

$text-thin-weight: 300;

$text-normal-weight: 400;

$typo: (
  headline: (
    family: Roboto,
    weight: 300,
    size: 30px
  ),
  title: (
    family: Roboto,
    weight: 300,
    size: 20px
  ),
  subheader: (
    family: Roboto,
    weight: 400,
    size: 15px
  ),
  button: (
    family: Roboto,
    weight: 400,
    size: 14px
  ),
  body: (
    family: Roboto,
    weight: 400,
    size: 13px
  ),
  small: (
    family: Roboto,
    weight: 400,
    size: 11px
  )
);

$node_style: (
  default: #333333,
  entry: #81c980,
  channel: #6597cf,
  response: #d86762
);

$engagement_style: (
  success: #65b864,
  warning: #eca24a,
  neutral: #6597cf,
  danger: #d86762
);

