.e,
e {
  &-ring {
    display: inline-block;
    position: relative;

    e-circle {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;

      &:first-child {
        position: static;
      }

      svg {
        vertical-align: top;
      }
    }

    &__path {
      &-stroke {
        stroke: $color-white;
      }
    }
  }

  &-ringchart {
    align-items: center;
    display: flex;

    &-has_footer {
      flex-direction: column;
      text-align: center;

      .e-ringchart__legend {
        padding-left: 0;
      }
    }

    &__chart {
      flex: 0 0 auto;
      font-size: 0;
      position: relative;
    }

    &__inner {
      font-size: typo('title', 'size');
      left: 50%;
      line-height: $text-lineheight-condensed;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__legend {
      flex: 0 1 auto;
      padding-left: $grid-gutter-width / 3;
    }

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__value {
      font-size: 20px;
      line-height: 1;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }

      small {
        color: $color-text;
        padding-left: 4px;
      }
    }

    &__background,
    &__fill {
      fill: none;
    }

    &__fill {
      transform-origin: 50% 50%;
    }
  }
}
