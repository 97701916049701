$btn-box-height: $box-form-element-height !default;
$btn-box-sidepadding: 1.1em !default;
$btn-box-padding: 0 $btn-box-sidepadding !default;
$btn-text-size: typo('button', 'size') !default;
$btn-text-size-small: typo('small', 'size') !default;
$btn-color-text: palette('grays', 'text') !default;
$btn-color-background: $color-white !default;
$btn-color-border: palette('default', 'x-light') !default;
$btn-text-weight: typo('button', 'weight') !default;
$btn-hover-transition: background $transition-time ease-in-out, border $transition-time ease-in-out, box-shadow $transition-time ease-in-out, color $transition-time ease-in-out, opacity $transition-time ease-in-out !default;
$btn-hover-shadow: 0 0 6px 0 rgba($color-black, .15) !default;
$btn-active-shadow: inset 0 0 3px rgba($color-black, .15);
$btn-checked-shadow: inset 0 0 3px rgba($color-black, .15);
$btn-box-height-small: 25px !default;
$btn-box-height-large: 45px !default;
$btn-box-height-badge: 16px !default;
$btn-box-minwidth: 75px !default;

@mixin e-btn {
  background-color: $btn-color-background;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $btn-color-border;
  color: $btn-color-text;
  cursor: pointer;
  display: inline-block;
  font-size: $btn-text-size;
  font-weight: $btn-text-weight;
  height: $btn-box-height;
  line-height: $btn-box-height - 2;
  min-width: $btn-box-minwidth;
  padding: $btn-box-padding;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: $btn-hover-transition;
  vertical-align: middle;
  white-space: nowrap;

  svg {
    fill: $icon-color;
  }

  &:active,
  &:visited,
  &:focus,
  &:hover {
    color: $btn-color-text;
    outline: 0;

    svg {
      fill: $icon-color;
    }
  }

  &:focus,
  &:hover {
    background: $color-hover;
    box-shadow: $btn-hover-shadow;
  }

  &-active {
    background: darken($btn-color-background, 5);
    box-shadow: $btn-active-shadow;

    &:focus,
    &:hover {
      background: darken($btn-color-background, 5);
      box-shadow: $btn-active-shadow;
    }
  }

  &-disabled,
  &:disabled {
    // stylelint-disable declaration-no-important
    cursor: default !important;
    opacity: .5;

    &:hover {
      background: inherit;
      box-shadow: none;
      color: $btn-color-text;
    }
  }
}

@mixin e-btn-modifier($modifier: 'default') {
  $data: map-get($btn_style, $modifier);

  background: map-get($data, 'background');
  border-color: darken(map-get($data, 'background'), 5);
  color: map-get($data, 'color');

  svg {
    fill: map-get($data, 'color');
  }

  &.e-btn-disabled,
  &:disabled {
    &:hover {
      background: map-get($data, 'background');
      color: map-get($data, 'color');
    }
  }

  &.e-btn-active {
    background: darken(map-get($data, 'background'), 10);

    &:focus,
    &:hover {
      background: darken(map-get($data, 'background'), 10);
      box-shadow: $btn-active-shadow;
    }
  }

  &:active,
  &:visited {
    color: map-get($data, 'color');

    svg {
      fill: map-get($data, 'color');
    }
  }

  &:active {
    background: darken(map-get($data, 'background'), $hover-modifier-amount);
  }

  &:hover,
  &:focus {
    background: darken(map-get($data, 'background'), $hover-modifier-amount);
    box-shadow: $btn-hover-shadow;
    color: map-get($data, 'color');

    svg {
      fill: map-get($data, 'color');
    }
  }
}

@mixin e-btn-onlyicon {
  min-width: 0;
  padding: 0;
  text-align: center;
  width: $btn-box-height;
}

@mixin e-btn-on_overlay {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  color: $color-white;
  transition: background $transition-time ease-in-out;

  &:hover,
  &:active,
  &:visited,
  &.e-btn-active {
    background: rgba($color-white, .33);
    color: $color-white;

    svg {
      fill: $color-white;
    }
  }

  svg {
    fill: $color-white;
  }
}
