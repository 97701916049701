.e {
  &-node {
    &__label {
      text-align: center;
      width: 123px;

      &__text {
        background-color: palette('secondary');
        color: $color-white;
        display: inline-block;
        min-width: 40px;
        padding: 1px 5px;

        &.status-error {
          background-color: palette('danger');

        }

        &.status-warning {
          background-color: palette('warning');

        }

        &.status-healthy {
          background-color: palette('primary');

        }
      }
    }

    &__datacontent {
      background: palette('secondary');
      color: $color-white;
      display: inline-block;
      font-weight: $text-bold-weight;
      height: 40px;
      margin-bottom: 7px;
      margin-top: 7px;
      padding-top: 13px;
      width: 50px;

      .e-icon {
        fill: $color-white;
        margin-top: -5px;
      }

      .e-node-report & {
        background: rgba(palette('secondary'), .4);
      }
    }
  }
}

.node {
  color: palette('grays', 'text');
  font-family: typo('body', 'family');
  font-size: typo('body', 'size');
  font-weight: typo('body', 'weight');
  white-space: normal;
  width: 120px;
}
