.e {
  &-datetimepicker {
    background: $color-white;
    border: 1px solid palette('grays', 'separator');
    margin: -1px auto;
    z-index: z('flying');

    &__date {
      .e-calendar {
        border-left: 0;
        border-right: 0;
      }
    }

    &__time {
      display: block;
      padding: 5px;
    }
  }
}
