$infolayer-box-height: 163px !default;
$infolayer-box-logo-size: 100px !default;
$infolayer-text-size: 28px !default;
$infolayer-text-size-title: 44px !default;

.e {
  &-infolayer {
    background: palette('info', 'default');
    left: 0;
    min-height: $infolayer-box-height;
    position: fixed;
    top: 30%;
    width: 100%;

    &__logo {
      font-size: 0;
      left: 5px;
      line-height: 0;
      position: absolute;
      top: 34px;

      svg {
        height: $infolayer-box-logo-size;
        width: $infolayer-box-logo-size;
      }
    }

    &__content {
      color: $color-white;
      font-size: $infolayer-text-size;
      line-height: 1.3;
      margin: 0 auto;
      max-width: map-get($screen-sizes, 'large');
      padding: $grid-gutter-width ($grid-gutter-width / 2);
      padding-left: $infolayer-box-logo-size + $grid-gutter-width;
      position: relative;

      a,
      a:visited,
      a:hover,
      a:active,
      a:focus {
        color: inherit;
        text-decoration: underline;
      }
    }

    &__title {
      font-size: $infolayer-text-size-title;
      margin-bottom: 8px;
    }
  }
}
