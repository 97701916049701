$tooltip-color-backgronud: $color-white !default;
$tooltip-color-background-inverse: #333 !default;
$tooltip-color: palette('grays', 'text') !default;
$tooltip-color-border: #ccc !default;
$tooltip-box-arrow-size: 8px !default;
$tooltip-text-size: typo('small', 'size') !default;
$tooltip-text-weight: typo('body', 'weight') !default;

e-tooltip {
  display: inline-flex;
  vertical-align: middle;

  &[block] {
    display: flex;
  }

  &[valign='baseline'] {
    vertical-align: baseline;
  }

  &[type='helper'] {
    margin-left: 4px;
    margin-top: -2px;

    .e-icon-helper {
      margin: 0;
    }

    .e-svgclickfix {
      font-size: 0;
    }
  }
}

.e {
  &-tt {
    background: $tooltip-color-backgronud;
    border: 1px solid $tooltip-color-border;
    box-shadow: 0 2px 4px rgba($color-black, .1);
    color: $tooltip-color;
    font-size: $tooltip-text-size;
    font-weight: $tooltip-text-weight;
    line-height: $text-lineheight;
    opacity: 0;
    padding: .533em 1em;
    text-align: left;
    top: -99999px;
    transform: translate3d(0, 0, 0);
    z-index: z('flying');

    &-bubble {
      background-clip: initial;
      background-color: inherit;
      border-color: transparent;
      border-radius: 4px;
      box-shadow: none;
      color: $color-white;
      max-width: 100%;
      padding: 0 1em;
      z-index: z('default');

      .e-tt__content {
        @include text-overflow;
      }

      .e-tt__arrow {
        background: transparent;
        border: 6px solid transparent;
        border-top: 6px solid currentColor;
        box-shadow: none;
        height: 0;
        transform: none;
        width: 0;
      }
    }

    &-inverse {
      background: $tooltip-color-background-inverse;
      border-color: $tooltip-color-background-inverse;
      box-shadow: none;
      color: $color-white;

      .e-tt__arrow {
        background: $tooltip-color-background-inverse;
        box-shadow: 1px 1px 0 0 $tooltip-color-background-inverse;
      }
    }

    &-has_action {
      &:before {
        background: $color-white;
        content: '';
        opacity: 0;
        position: absolute;
      }
    }

    &__arrow {
      background: $tooltip-color-backgronud;
      box-shadow: 1px 1px 0 0 $tooltip-color-border;
      height: $tooltip-box-arrow-size;
      position: absolute;
      transform: rotate(45deg);
      width: $tooltip-box-arrow-size;
    }

    &[x-placement^='top'],
    &[x-placement^='bottom'] {
      &:before {
        height: 10px;
        left: -1px;
        right: -1px;
      }
    }

    &[x-placement^='left'],
    &[x-placement^='right'] {
      &:before {
        bottom: -1px;
        top: -1px;
        width: 10px;
      }
    }

    &[x-placement^='top'] {
      animation: tooltip-top $transition-time normal forwards ease-in-out;
      margin-bottom: 10px;

      &:before {
        top: 100%;
      }

      .e-tt__arrow {
        bottom: -3px;
        margin-left: -2px;
      }
    }

    &-bubble[x-placement^='top'] {
      .e-tt__arrow {
        bottom: -12px;
      }
    }

    &[x-placement^='bottom'] {
      animation: tooltip-bottom $transition-time normal forwards ease-in-out;
      margin-top: 10px;

      &:before {
        top: -10px;
      }

      .e-tt__arrow {
        margin-left: -2px;
        top: -3px;
        transform: rotate(-135deg);
      }
    }

    &-bubble[x-placement^='bottom'] {
      .e-tt__arrow {
        top: -12px;
        transform: rotate(180deg);
      }
    }

    &[x-placement^='right'] {
      animation: tooltip-right $transition-time normal forwards ease-in-out;
      margin-left: 12px;

      &:before {
        left: -10px;
      }

      .e-tt__arrow {
        left: -3px;
        margin-top: -2px;
        transform: rotate(135deg);
      }
    }

    &[x-placement^='left'] {
      animation: tooltip-left $transition-time normal forwards ease-in-out;
      margin-right: 12px;

      &:before {
        left: 100%;
      }

      .e-tt__arrow {
        margin-top: -2px;
        right: -3px;
        transform: rotate(-45deg);
      }
    }
  }
}

@keyframes tooltip-top {
  to {
    opacity: 1;
    transform: translate3d(0, 3px, 0);
  }
}

@keyframes tooltip-bottom {
  to {
    opacity: 1;
    transform: translate3d(0, -3px, 0);
  }
}

@keyframes tooltip-right {
  to {
    opacity: 1;
    transform: translate3d(-3px, 0, 0);
  }
}

@keyframes tooltip-left {
  to {
    opacity: 1;
    transform: translate3d(3px, 0, 0);
  }
}
