$fullheight-box-width_sidebar: 320px !default;
$fullheight-box-top-padding: 8px !default;

.e {
  &-fullheight {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $fullheight-box-top-padding ($grid-gutter-width / 2) ($grid-gutter-width / 2);

    &-has_header {
      padding-top: $fixed-topnav-height + $fullheight-box-top-padding;
    }

    &-has_footer {
      padding-bottom: $fixed-topnav-height + $fullheight-box-top-padding;
    }

    &-inner {
      padding: 0;

      .e-fullheight__container {
        left: 0;
        right: 0;
      }

      .e-fullheight__content {
        margin: 0;
      }
    }

    &-modal {
      height: calc(100% + 30px);
      margin: -15px;
      padding: 0;
    }

    &__wrapper {
      flex-grow: 1;
      position: relative;
    }

    &__container {
      bottom: 0;
      display: flex;
      left: -5px;
      position: absolute;
      right: -5px;
      top: 0;

      &-block {
        display: block;
        left: 0;
        right: 0;
      }

      &-ac {
        display: block;

        .e-fullheight {
          &__content {
            float: left;

            &-sidebar {
              width: 250px;
            }

            &-main {
              width: calc(100% - #{250px + 20px});
            }
          }
        }
      }
    }

    &__content {
      flex: 1 1 50%;
      height: 100%;
      margin: 0 5px;
      min-width: 0;
      position: relative;
      transition: flex-basis $transition-time ease-out, width $transition-time ease-out, margin-left $transition-time ease-out;

      &-no_animation {
        transition: none;
      }

      &-box {
        @include e-box-style;
      }

      &-sidebar {
        flex-basis: $fullheight-box-width_sidebar;
        flex-grow: 0;
        flex-shrink: 0;
        width: $fullheight-box-width_sidebar;

        &.e-verticaltab,
        &.e-verticalnav {
          flex-basis: $fullheight-box-width_sidebar + 70px;
          width: $fullheight-box-width_sidebar + 70px;
        }
      }

      &-closed {
        flex-basis: 0;
        overflow: hidden;
        width: 0;
      }

      &-bordered { // email-preview
        border-right: 1px solid palette('grays', 'separator');
      }
    }

    &__body {
      height: calc(100% - 63px);
      overflow: auto;
      padding: $grid-gutter-width / 2;

      &-always_scroll {
        overflow-y: scroll;
      }

      &-no_scroll {
        overflow: hidden;
      }

      &-no_padding {
        padding: 0;
      }

      &-no_header {
        height: 100%;
      }
    }

    &__header {
      align-items: center;
      border-bottom: 1px solid palette('grays', 'separator');
      display: flex;
      height: 63px;
      padding: 0 ($grid-gutter-width / 2);

      h2 {
        line-height: $text-lineheight-condensed;
        margin: 0;
      }
    }

    &__actions {
      flex-basis: auto;
      flex-shrink: 0;
      margin-left: auto;
    }

    &__toggle {
      position: relative;
      right: -$grid-gutter-width / 2;
    }
  }
}
