$multiselect-color-border: palette('default', 'x-light') !default;
$multiselect-color-header-background: palette('grays', 'separator') !default;
$multiselect-padding-select: 5px 10px !default;
$multiselect-padding-title: 10px 15px !default;
// $multiselect-box-width: 260px !default;
$multiselect-box-height: 200px !default;
$multiselect-box-padding: $grid-gutter-width / 2 !default;

@mixin selectDesign() {
  border: 1px solid $multiselect-color-border;
  height: $multiselect-box-height;
  width: 100%;
}

.e {
  &-multiselect {
    display: table;
    width: 100%;

    &__item,
    &__buttons {
      display: table-cell;
      vertical-align: middle;
    }

    &__item {
      width: 45%;

      select {
        @include selectDesign;
      }

      option {
        padding: $multiselect-padding-select;
      }

      &__title {
        background: $multiselect-color-header-background;
        padding: $multiselect-padding-title;
      }
    }

    &__emptystate {
      @include selectDesign;
      color: palette('grays', 'placeholder');
      font-style: italic;
      padding: $multiselect-padding-select;
      user-select: none;
    }

    &__buttons {
      width: 10%;

      .e-btn {
        display: block;
        margin: 0 auto $multiselect-box-padding;
      }
    }

    @each $class, $data in $size {
      &-#{$class} {
        width: map-get($data, 'width');
      }
    }
  }
}
