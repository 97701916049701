$input-box-height: $box-form-element-height !default;
$input-box-height-textarea: 100px !default;
$input-box-padding: 0 1em !default;
$input-box-padding-textarea: .5em !default;
$input-color-background-disabled: palette('grays', 'inactive') url('ban.svg') no-repeat center right 7.5px !default;
$input-color-background-disabled-noicon: palette('grays', 'inactive');
$input-color-border: palette('default', 'x-light') !default;
$input-color-border-error: palette('danger') !default;
$input-color-border-focus: palette('secondary') !default;
$input-color-disabled: palette('default', 'light') !default;
$input-text-size: typo('body', 'size') !default;
$input-text-color: palette('default') !default;
$input-text-color-placeholder: palette('grays', 'placeholder') !default;
$input-text-color-placeholder-error: palette('danger', 'x-light') !default;
$input-focus-transition: border $transition-time ease-in-out, box-shadow $transition-time ease-in-out !default;

@mixin e-input {
  border: 1px solid $input-color-border;
  color: $input-text-color;
  display: inline-block;
  font-size: $input-text-size;
  height: $input-box-height;
  padding: $input-box-padding;
  position: relative;
  text-overflow: ellipsis;
  transition: $input-focus-transition;
  vertical-align: middle;
  width: 100%;

  &::placeholder {
    color: $input-text-color-placeholder;
    font-style: italic;
  }

  &:focus {
    @include e-input-modifier('focus');
  }

  &:disabled {
    @include e-input-modifier('disabled');
  }

  &:read-only {
    &:focus {
      border-color: $input-color-border;
      box-shadow: none;
    }
  }
}

@mixin e-input-size($_size: 'large') {
  width: map-deep-get($size, $_size, 'width');
}

@mixin e-input-modifier($modifier: 'default') {
  @if $modifier == 'error' {
    border: 1px dashed $input-color-border-error;
    color: $input-color-border-error;

    &::placeholder {
      color: $input-text-color-placeholder-error;
    }

    &:focus {
      border-color: $input-color-border-error;
      box-shadow: 0 0 4px rgba($input-color-border-error, .5);
    }
  }

  @if $modifier == 'disabled' {
    background: $input-color-background-disabled;
    color: $input-color-disabled;
    cursor: not-allowed;
    padding-right: 23px;

    &:focus {
      border-color: $input-color-border;
      box-shadow: none;
    }
  }

  @if $modifier == 'code' {
    background: $input-color-background-disabled-noicon;

    &:focus {
      border-color: $input-color-border;
      box-shadow: none;
    }
  }

  @if $modifier == 'textarea' {
    height: $input-box-height-textarea;
    line-height: inherit;
    padding-bottom: $input-box-padding-textarea;
    padding-top: $input-box-padding-textarea;
    resize: none;
  }

  @if $modifier == 'autoheight' {
    height: auto;
  }

  @if $modifier == 'focus' {
    border-color: $input-color-border-focus;
    box-shadow: 0 0 4px rgba($input-color-border-focus, .5);
  }
}

@mixin search-input-cancel {
  -webkit-appearance: none;
  background: url('e-times-circle.svg');
  color: transparent;
  height: 16px;
  margin-left: 5px;
  margin-right: -5px;
  position: relative;
  width: 16px;
}
