.e {
  &-compare {
    @include clearfix;
    margin: 0 7px;
    padding-top: 50px;
    position: relative;

    &__line {
      background: palette('grays', 'separator');
      height: 4px;

      &:before {
        background: inherit;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        content: '';
        height: 4px;
        left: -7px;
        position: absolute;
        top: 50px;
        width: 7px;
      }

      &:after {
        background: inherit;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        content: '';
        height: 4px;
        position: absolute;
        right: -7px;
        top: 50px;
        width: 7px;
      }
    }

    &__label {
      color: palette('default');
      margin-top: 8px;

      &-left {
        float: left;
        margin-left: -7px;
      }

      &-right {
        float: right;
        margin-right: -7px;
      }
    }
  }
}
