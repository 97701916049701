e-barchart {
  display: block;
}

.e {
  &-barchart {
    display: inline-block;
    overflow: hidden;
    position: relative;
    shape-rendering: crispEdges;
    vertical-align: top;
    width: 100%;

    &-inverse {
      .e-barchart {
        &__x {
          text {
            fill: #fff;
          }

          path {
            display: none;
          }

          line {
            display: none;
            stroke: #fff;
          }
        }

        &__y {
          text {
            fill: #fff;
          }
        }

        &__grid {
          line {
            stroke: #fff;
          }
        }
      }
    }

    &__content {
      display: inline-block;
      left: 0;
      position: absolute;
      top: 10px;
    }

    &__y {
      path,
      line {
        display: none;
      }
    }

    &__grid {
      opacity: .5;

      path {
        display: none;
      }

      line {
        stroke-dasharray: 1 2;
      }
    }
  }
}
