.e {
  &-chart {
    &-si {
      background: $color-body;
      border: 1px solid $box-color-border;
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    &__expand {
      float: right;
      position: relative;
      top: -20px;
    }

    &-small {
      height: 35px;
    }

    &-medium {
      height: 100px;
    }

    &__pie {
      float: left;
      height: 100px;
      padding-right: $grid-gutter-width / 2;

      &-reporting {
        height: 120px;
      }

      &-centered {
        float: none;
        margin: $grid-gutter-width / 2 0 $grid-gutter-width / 4;
        padding-right: 0;
        text-align: center;
      }

      > div {
        height: 130px;
        width: 130px;
      }
    }

    &__info {
      text-align: center;

      &-reporting {
        padding-bottom: $grid-gutter-width / 2;
        text-align: left;
      }

      &__icon {
        float: left;
        margin-right: $grid-gutter-width / 2;

        .e-btn {
          height: 45px;
          width: 45px;

          .e-icon {
            height: 22px;
            margin-top: 5px;
            width: 22px;
          }
        }
      }

      &__title {
        font-size: typo('headline', 'size');
        line-height: $text-lineheight-condensed;
      }

      &__subtitle {
        font-size: typo('subheader', 'size');
        font-weight: typo('subheader', 'weight');
        line-height: $text-lineheight-condensed;
      }
    }

    &__title {
      @include text-overflow;
      font-size: typo('subheader', 'size');

      small {
        padding-left: 4px;
      }
    }

    &__value {
      @include text-overflow;
      font-size: typo('title', 'size');
      line-height: 1;

      small {
        padding-left: 4px;
      }
    }
  }
}
