@import 'assets';

.e {
  &-input {
    @include e-input;

    &-error,
    &.error { // we should remove this
      @include e-input-modifier('error');
    }

    &-disabled {
      @include e-input-modifier('disabled');
    }

    &-code {
      @include e-input-modifier('code');
    }

    &::-webkit-search-cancel-button {
      @include search-input-cancel;
    }

    &::-webkit-contacts-auto-fill-button {
      display: none;
      pointer-events: none;
      position: absolute;
      right: 0;
      visibility: hidden;
    }

    &::-ms-clear {
      @include search-input-cancel;
    }

    @each $class, $data in $size {
      &-#{$class} {
        @include e-input-size($class);
      }
    }
  }
}
