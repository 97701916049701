e {
  &-iframe-loader {
    display: inline-block;
    position: relative;

    iframe {
      position: relative;
    }

    e-iframe-fit & {
      height: 100%;
      width: 100%;
    }
  }
}
