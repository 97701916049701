$e-device-push-assets: 'https://assets.emarsys.net/ui/static/images/e-device';

.e {
  &-device-push {
    background-color: transparent;
    background-repeat: no-repeat;
    height: 100%;
    position: relative;

    &.is-ios_phone_default {
      background-size: 375px 667px;

      &.is-lockscreen {
        background-image:
          url(#{$e-device-push-assets}/e-device-push-ios10-lockscreen.svg),
          url(#{$e-device-push-assets}/e-device-push-ios10-background.jpg);
      }

      &.is-alert {
        background-image:
          url(#{$e-device-push-assets}/e-device-push-ios10-homescreen.svg),
          url(#{$e-device-push-assets}/e-device-push-ios10-background.jpg);
      }

      &.is-noc {
        background-image: url(#{$e-device-push-assets}/e-device-push-ios10-noc.jpg);
      }

      &.is-expanded {
        background-image: url(#{$e-device-push-assets}/e-device-push-ios10-background-blurred.jpg);
      }
    }

    &.is-android_phone_default {
      background-size: 360px 640px;

      &.is-lockscreen {
        background-image:
          url(#{$e-device-push-assets}/e-device-push-android-nougat-footer-lock.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-lockscreen.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-background.jpg);
      }

      &.is-alert {
        background-image:
          url(#{$e-device-push-assets}/e-device-push-android-nougat-footer-nav.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-homescreen.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-background.jpg);
      }

      &.is-noc,
      &.is-expanded {
        background-image:
          url(#{$e-device-push-assets}/e-device-push-android-nougat-footer-nav.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-notification-bar.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-homescreen.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-background.jpg);

        &:after {
          background-color: #000;
          bottom: 0;
          content: '';
          left: 0;
          opacity: .5;
          position: absolute;
          right: 0;
          top: 70px;
        }
      }

      &.is-expanded {
        background-image:
          url(#{$e-device-push-assets}/e-device-push-android-nougat-footer-lock.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-notification-bar.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-background.jpg);
      }
    }

    &__notification {
      font-size: 13px;
      position: absolute;
      top: 192px;
      z-index: 1;

      &.is-ios_phone_default {
        background-color: #abcec8;
        border-radius: 10px;
        left: 7px;
        padding-bottom: 7px;
        right: 7px;

        &.is-alert {
          top: 5px;
        }

        &.is-noc {
          top: 120px;
        }

        &.is-expanded {
          background: $color-white;
        }

        &.is-editable {
          min-height: 111px;

          .e-device-push__headline {
            font-weight: bold;
          }

          .e-device-push__headline,
          .e-device-push__content {
            position: relative;

            &:before {
              color: rgba(0, 0, 0, .3);
              content: attr(fake-placeholder);
              font-style: italic;
              font-weight: normal;
              left: 14px;
              pointer-events: none;
              position: absolute;
              top: 0;
            }
          }

          .e-tokeninput__content {
            left: 14px;
            right: 8px;
          }
        }
      }

      &.is-android_phone_default {
        left: 0;
        padding-bottom: 14px;
        right: 0;

        &.is-lockscreen {
          background-color: #f1f1f1;
        }

        &.is-expanded,
        &.is-noc,
        &.is-alert {
          background-color: $color-white;
        }

        &.is-alert {
          box-shadow: 0 25px 75px -30px #000;
          top: 0;
        }

        &.is-noc,
        &.is-expanded {
          top: 70px;
        }
      }
    }

    &__header {
      display: flex;

      &.is-ios_phone_default {
        align-items: center;
        background-color: #b7dbd1;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #555;
        font-size: 11px;
        height: 32px;
        margin-bottom: 7px;
        padding-left: 8px;
        padding-right: 14px;

        &.is-expanded {
          background: #fff;
          border-bottom: 1px solid #b7dbd1;
          height: 44px;
          padding-left: 14px;
        }
      }

      &.is-android_phone_default {
        align-items: flex-end;
        height: 30px;
        padding: 0 14px 4px;
      }
    }

    &__icon {
      &.is-ios_phone_default {
        height: 20px;
        width: 20px;
      }

      &.is-android_phone_default {
        border-radius: 50%;
        max-height: 15px;
        position: relative;
        top: -3px;
        width: 15px;
      }
    }

    &__apptitle {
      @include text-overflow();

      &.is-ios_phone_default {
        padding-left: 10px;
        text-transform: uppercase;
      }

      &.is-android_phone_default {
        padding-left: 8px;
      }
    }

    &__timeago {
      &.is-ios_phone_default {
        margin-left: auto;
      }

      &.is-android_phone_default {
        margin-left: 5px;
      }
    }

    &__headline {
      @include text-overflow();
      color: palette('grays', 'full');
      display: block;
      font-size: 13px;
      font-weight: $text-bold-weight;
      letter-spacing: .1px;
      line-height: 16px;
      padding-left: 14px;
      padding-right: 8px;
    }

    &__content {
      display: block;
      letter-spacing: .25px;
      line-height: 16px;
      padding-left: 14px;
      padding-right: 8px;
      position: relative;

      &.is-android_phone_default {
        @include text-overflow();

        &.is-expanded {
          white-space: normal;
        }
      }
    }

    &__more {
      color: #555;
      display: none;
      font-size: 11px;
      margin: 3px 8px 0 14px;

      &.is-ios_phone_default {
        &.is-lockscreen {
          display: block;
        }

        &.is-expanded {
          display: none;
        }
      }
    }
  }
}
