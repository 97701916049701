// Used: AC

.ui-dialog {
  background: $color-white;
  border: 0;
  border-radius: 0;
  // scss-lint:disable ImportantRule ColorVariable
  box-shadow: 0 0 6px 0 rgba($color-black, .3);
  min-width: 300px !important; /* stylelint-disable-line declaration-no-important */
  padding: $grid-gutter-width / 2;
  z-index: z('modal');

  &-title {
    font-size: typo('headline', 'size');
    font-weight: typo('headline', 'weight');
    margin: 0 0 8px;
  }

  .ui-dialog-content {
    // scss-lint:disable ImportantRule SelectorFormat
    height: auto !important; /* stylelint-disable-line declaration-no-important */

    &.create_campaign_frame {
      display: block !important; /* stylelint-disable-line declaration-no-important */
      height: 510px !important; /* stylelint-disable-line declaration-no-important */
      margin: auto;
    }
  }

  .error {
    color: palette('danger');
    line-height: $text-lineheight;
    padding: 3px 0 0;
  }

  .ui-form-row {
    @extend .e-field;

    .row-title {
      @extend .e-field__label;
      padding: 0;
    }
  }

  .ui-dialog-buttonset {
    @include e-buttongroup('.ui-button');
    height: auto !important; /* stylelint-disable-line declaration-no-important */

    .ui-button {
      @include e-btn;
    }
  }

  .ui-dialog-buttonpane {
    .ui-button {
      @include e-btn;
      border-radius: 0;
      font-family: inherit;
      text-shadow: none;
    }

    .ui-button-create,
    .ui-button-ok {
      @include e-btn-modifier('primary');
    }
  }

  .ui-dialog-titlebar-close {
    font-size: 0;
    position: absolute;
    right: 30px;
    text-decoration: none;
    top: 10px;

    .ui-icon-closethick:before {
      background: url('close.svg');
      content: '';
      height: 20px;
      position: absolute;
      width: 20px;
    }
  }

  .ui-corner,
  .ui-corner-right {
    left: 50%;
    margin-left: -10px;
    position: absolute;
    top: -10px;

    &:before {
      background: $dropdown-color-inner-background;
      box-shadow: 2px 2px 4px rgba($color-black, .06);
      content: '';
      display: inline-block;
      height: 20px;
      transform: rotate(-135deg);
      width: 20px;
    }
  }

  .ui-corner-right {
    left: auto;
    right: 15px;
  }

  &.e-ui-dialog-notitlebar {
    .ui-dialog-titlebar {
      display: none;
    }
  }
}

.ui-widget-overlay {
  background-color: $color-black;
  height: 100%;
  left: 0;
  opacity: .25;
  position: absolute;
  top: 0;
  width: 100%;
}

.ui-dialog-titlebar-close {
  font-size: 0;
  position: absolute;
  right: 30px;
  text-decoration: none;
  top: 10px;
  z-index: z('default');

  &:before {
    background: url('close.svg');
    content: '';
    height: 20px;
    position: absolute;
    width: 20px;
  }
}

.ui-dialog-content {
  body {
    background: $modal-content-bg;
  }

  .e-topnav {
    &-topnav {
      display: none;
    }

    &-container {
      max-width: auto;
      min-width: auto;
      padding-top: 0;
    }
  }
}
