$daterangepicker-box-padding: 8px !default;
$daterangepicker-box-ranges-padding: .5em 1em !default;
$daterangepicker-box-icon-size: 16px !default;
$daterangepicker-color-background: #fff !default;
$daterangepicker-color-ranges-border: palette('grays', 'separator') !default;

// scss-lint:disable SelectorDepth

.e {
  &-daterangepicker {
    @include e-input;
    background: $daterangepicker-color-background;
    cursor: pointer;
    line-height: $input-box-height - 2;
    padding-right: $grid-gutter-width;
    position: relative;
    user-select: none;

    &:after {
      background: url('table-sort-desc.svg') no-repeat top right;
      content: '';
      height: 14px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 14px;
    }

    &-static {
      border-color: transparent;
      cursor: inherit;
      padding-right: inherit;
      user-select: initial;

      &:after {
        display: none;
      }
    }

    .opened & {
      border: 1px solid $box-color-border;
      box-shadow: $box-box-shadow;

      &:before {
        background: $daterangepicker-color-background;
        bottom: -3px;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 503;
      }
    }

    &__icon {
      height: $daterangepicker-box-icon-size;
      margin-top: -3px;
      width: $daterangepicker-box-icon-size;
    }

    &__item {
      padding: 0 $daterangepicker-box-padding;
    }

    &__container {
      @include e-box;
      background: $daterangepicker-color-background;
      display: none;
      margin-left: - $grid-gutter-width;
      margin-top: 9px;
      padding-bottom: $grid-gutter-width / 2;
      position: absolute;
      width: 820px;
      z-index: z('dropdown');
    }

    &__content {
      @include e-box;
      background: $daterangepicker-color-background;
      display: none;
      padding-bottom: $grid-gutter-width / 2;
      width: 820px;
    }

    &__ranges {
      ul {
        border: 1px solid $daterangepicker-color-ranges-border;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          border-bottom: 1px solid $daterangepicker-color-ranges-border;
          cursor: pointer;
          padding: $daterangepicker-box-ranges-padding;

          &:hover {
            background: $datepicker-color-cell-hover-background;
          }

          &.active {
            background: palette('secondary');
            color: $daterangepicker-color-background;
            position: relative;

            &:after {
              background: url('success-white.svg') no-repeat top right;
              content: '';
              height: $daterangepicker-box-icon-size;
              position: absolute;
              right: 10px;
              top: 12px;
              width: $daterangepicker-box-icon-size;
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    &__calendars {
      margin-top: 21px;

      thead {
        tr {
          &:first-child {
            th {
              &:first-child,
              &:last-child {
                cursor: pointer;
                opacity: .3;

                &.prev,
                &.next {
                  opacity: 1;
                }

                &:after {
                  content: '';
                  display: inline-block;
                  height: 20px;
                  position: absolute;
                  top: 2px;
                  width: 20px;
                }
              }

              &:last-child {
                @include e-datepicker-action('next');

                &:after {
                  background: url('caret-right.svg') no-repeat center center;
                  right: - $grid-gutter-width / 4;
                }
              }

              &:first-child {
                @include e-datepicker-action('prev');

                &:after {
                  background: url('caret-left.svg') no-repeat center center;
                  left: - $grid-gutter-width / 4;
                }
              }
            }
          }
        }
      }

      .month {
        @include e-datepicker-title;
      }

      td {
        @include e-datepicker-day;

        &:hover {
          background: $datepicker-color-cell-hover-background;
        }

        &.added-padding {
          width: 0;
        }

        &.off,
        &.disabled {
          @include e-datepicker-day-modifier('alternate');
        }

        &.in-range,
        &.start-date,
        &.end-date {
          @include e-datepicker-day-modifier('current');
        }
      }
    }

    &__calendar {
      thead {
        tr {
          &:first-child {
            th {
              &:first-child {
                opacity: 1;
                position: static;

                &:after {
                  display: none;
                }
              }

              &:last-child {
                opacity: 1;
                position: static;

                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
