$accordion-color-background: palette('grays', 'inactive') !default;
$accordion-color-text-disabled: palette('grays', 'separator') !default;
$accordion-color-border: #ededed !default;
$accordion-icon-color-background: palette('secondary') !default;
$accordion-icon-closed: $accordion-icon-color-background url('angle-double-right.svg') no-repeat center center !default;
$accordion-icon-size: 24px !default;
$accordion-box-padding: 15px !default;
//$accordion-box-margin: 2px !default;

.e {
  &-accordion {
    border-left: 1px solid $accordion-color-border;
    border-right: 1px solid $accordion-color-border;
    border-top: 1px solid $accordion-color-border;

    &-stretch {
      border-left: 0;
      border-right: 0;
      margin-left: -$grid-gutter-width / 2;
      margin-right: -$grid-gutter-width / 2;
    }

    &__title {
      @include clearfix;
      border-bottom: 1px solid $accordion-color-border;
      cursor: pointer;
      display: block;
      line-height: $accordion-icon-size;
      //margin-bottom: $accordion-box-margin;
      padding: 10px $accordion-box-padding 10px ($grid-gutter-width + $accordion-icon-size);
      position: relative;
      user-select: none;

      &:hover {
        background: $accordion-color-background;
      }

      &:after {
        background: $accordion-icon-closed;
        content: '';
        display: inline-block;
        height: $accordion-icon-size;
        left: $accordion-box-padding;
        position: absolute;
        top: 10px;
        width: $accordion-icon-size;
      }

      &-active {
        background: $accordion-color-background;

        &:after {
          transform: rotate(90deg);
        }

        + .e-accordion__content {
          display: block;
        }
      }

      &-disabled {
        color: $accordion-color-text-disabled;
        cursor: default;

        &:hover {
          background: transparent;
        }

        &:after {
          opacity: .6;
        }
      }
    }

    &__content {
      border-bottom: 1px solid $accordion-color-border;
      display: none;
      padding: $grid-gutter-width / 2;

      &-active {
        display: block;
      }
    }

    // scss-lint:disable QualifyingElement
    > input[type='radio'] {
      &:checked {
        + .e-accordion__title {
          cursor: default;
        }
      }
    }

    > input {
      display: none;

      &:checked {
        //nth-child: safari not repaint bug fix
        + .e-accordion__title:nth-child(n) {
          @extend .e-accordion__title-active;
        }
      }

      &:disabled {
        + .e-accordion__title {
          @extend .e-accordion__title-disabled;
        }
      }
    }

    &-sidebar {
      .e-accordion__title {
        padding: 15px 15px 14px 55px;
      }

      .e-accordion__title:after {
        top: 14px;
      }

      .e-fullheight & { // AC
        border-top: 0;
        margin: -15px;
        margin-bottom: 0;

        .e-accordion__title {
          padding: 10px 15px 9px 55px;

          &:after {
            top: 9px;
          }
        }
      }
    }
  }
}
