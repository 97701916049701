.e {
  &-inputgroup {
    .e-radio,
    .e-checkbox {
      + label {
        display: inline-block;
        margin-bottom: 5px;

        &:before {
          top: 1px;
        }
      }
    }

    .e-radio {
      + label {
        &:after {
          top: 5px;
        }
      }
    }

    .e-checkbox {
      + label {
        &:after {
          top: 4px;
        }
      }
    }
  }
}
