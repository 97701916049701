$label-box-padding: 0 8px 0 !default;
$label-border-radius: .25em !default;
$label-text-size: typo('small', 'size') !default;
$label-text-weight: typo('small', 'weight') !default;
$label-color-text: #fff !default;
$label-box-margin: 0 .3em 0 0 !default;
$label-box-height: 20px !default;
$label-box-tag-height: 25px !default;

@mixin e-label {
  background: palette('default');
  border-radius: $label-border-radius;
  display: inline-block;
  font-size: $label-text-size;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: $label-text-weight;
  height: $label-box-height;
  line-height: $label-box-height;
  margin: $label-box-margin;
  padding: $label-box-padding;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

@mixin e-label-modifier($modifier: 'default') {
  @if ($modifier == 'tag') {
    background: palette('secondary');
    font-size: typo('body', 'size');
    -webkit-font-smoothing: antialiased;
    font-weight: typo('body', 'weight');
    height: $label-box-tag-height;
    line-height: $label-box-tag-height;
  } @else {
    background: palette($modifier);
    color: palette($modifier);
  }
}

@mixin e-label-close {
  background: url('close-white.svg') center center no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  margin-right: -3px;
  vertical-align: top;
  width: 12px;
}

.e {
  &-label {
    @include e-label;

    @each $class, $data in $label_style {
      @if $class != 'default' {
        @if $class != 'grays' {
          &-#{$class} {
            @include e-label-modifier($class);
          }
        }
      }
    }

    &:not(.e-label-outline) {
      color: $label-color-text;
    }

    &__close {
      @include e-label-close;
    }

    &-outline {
      background: transparent;
      border: 1px solid currentColor;
      text-transform: uppercase;
    }

    &-tag {
      @include e-label-modifier('tag');

      .e-label__close {
        height: $label-box-tag-height + 1;
        width: 16px;
      }
    }
  }
}
