$canvas-content-box-offset: 259px !default;
$canvas-content-box-offset-iframe: 95px !default;
$canvas-sidebar-box-offset: 208px !default;
$canvas-ac-box-nodebar-width: 250px !default;
$canvas-color-background: #f7f7f7;
$canvas-vcms-box-width: 700px !default;

$canvas-sidebar-box-offset-socialads: 145px !default;

$canvas-color-status: (
  active: palette('success'),
  testing: palette('alert'),
  paused: palette('warning'),
  frozen: palette('warning'),
  error: palette('danger'),
  aborted: $color-black,
  failsafe: palette('warning', 'light')
);

.e {
  &-canvas {
    @include clearfix;
    background: $box-color-background;
    border: 1px solid $box-color-border;
    box-shadow: $box-box-shadow;

    &__content {
      height: calc(100vh - #{$canvas-content-box-offset});

      &-iframe {
        height: calc(100vh - #{$canvas-content-box-offset-iframe});
      }

      &-half {
        float: left;
        width: 50%;
      }
    }

    &__sidebar {
      height: calc(100vh - #{$canvas-sidebar-box-offset});
      position: relative;
      width: 100%;

      &-socialads {
        height: calc(100vh - #{$canvas-sidebar-box-offset-socialads});
      }

      &__content {
        display: block;
        height: 100%;
        overflow: auto;
        position: absolute;
        width: 100%;
      }
    }

    &-ac {
      height: calc(100vh - #{$canvas-sidebar-box-offset});
      position: relative;
    }

    &-vcms {
      height: calc(100vh - 190px);
      margin-bottom: 5px;
      position: relative;
    }

    &__preview {
      bottom: 0;
      left: $canvas-vcms-box-width;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__cms {
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: $canvas-vcms-box-width;
    }

    &__nodebar {
      background: $canvas-color-background;
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;
      width: $canvas-ac-box-nodebar-width;
    }

    &__toolbar {
      border-bottom: 1px solid $box-color-border;
      left: $canvas-ac-box-nodebar-width;
      padding: 10px;
      position: absolute;
      right: 0;
    }

    &__graph {
      border: 3px solid transparent;
      bottom: 0;
      left: $canvas-ac-box-nodebar-width;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 56px;

      @each $class, $data in $canvas-color-status {
        &.status-#{$class} {
          border-color: #{$data};
        }
      }
    }
  }
}
