$notice-size-icon: 16px !default;
$notice-size-icon-big: 24px !default;
$notice-size-icon-middle: $icon-size-middle !default;
$notice-text-size: typo('body', 'size');
$notice-box-padding-horizontal: 15px !default;
$notice-box-padding-vertical: 8px !default;
$notice-box-padding: $notice-box-padding-vertical $notice-box-padding-horizontal;
$notice-box-padding-withicon: 43px !default;
$notice-box-padding-withbigicon: 48px !default;
$notice-box-padding-withmiddleicon: 74px !default;
$notice-box-line-width: 4px !default;
$notice-box-border: 1px solid palette('grays', 'separator') !default;
$notice-box-width-toast: 400px !default;
$notice-box-width-fixed: 600px !default;

// Extend main colors with notification center (noc) colors
@each $class, $data in $notice_style_noc {
  // stylelint-disable declaration-colon-newline-after
  $noc-color: (
    '#{$class}': (
      'color': map-get($data, 'color')
    )
  );
  // stylelint-enable declaration-colon-newline-after

  $notice_style: map-merge($notice_style, $noc-color);
}

.e {
  &-notice {
    background: $color-white;
    border-bottom: $notice-box-border;
    border-right: $notice-box-border;
    border-top: $notice-box-border;
    font-size: $notice-text-size;
    line-height: $text-lineheight;
    margin-bottom: $grid-gutter-width / 2;
    padding: $notice-box-padding;
    position: relative;
    text-align: left;

    &:before {
      bottom: -1px;
      content: '';
      left: 0;
      position: absolute;
      top: -1px;
      width: $notice-box-line-width;
    }

    &:empty {
      display: none;
    }

    @each $class, $data in $notice_style {
      $class-name: if($class == 'default', '', '-#{$class}');

      &#{$class-name} {
        fill: map-get($data, 'color');

        &:before {
          background-color: map-get($data, 'color');
        }

        .e-notice__title {
          color: map-get($data, 'color');
        }
      }
    }

    &-withicon {
      padding-left: $notice-box-padding-withicon;
    }

    &-withbigicon {
      padding-left: $notice-box-padding-withbigicon;

      .e-notice__icon {
        left: 14px;
        top: 13px;

        svg {
          height: $notice-size-icon-big;
          width: $notice-size-icon-big;
        }

      }
    }

    &-withclose {
      padding-right: 40px;
    }

    &-sticky {
      margin: 0 0 -1px;

      &:before {
        top: 0;
      }
    }

    &-highlight {
      background: palette('grays', 'inactive');
    }

    &-fixed {
      left: 0;
      margin: auto;
      position: fixed;
      right: 0;
      top: $fixed-topnav-height + 13px;
      width: $notice-box-width-fixed;
      z-index: z('fixed-element');
    }

    &-toast {
      bottom: $grid-gutter-width / 2;
      min-height: 90px;
      position: fixed;
      right: $grid-gutter-width / 2;
      transition: all $transition-time cubic-bezier(.25, .46, .45, .94);
      width: $notice-box-width-toast;
      z-index: z('modal');
    }

    &-widget { // Dashboard notification widget
      bottom: 80px;
      left: $grid-gutter-width;
      margin-bottom: 0;
      padding-left: $notice-box-padding-withmiddleicon;
      position: absolute;
      right: $grid-gutter-width;
      top: 80px;
      z-index: z('fixed-element');

      .e-notice {
        &__icon {
          top: 12px;

          svg {
            height: $notice-size-icon-middle;
            width: $notice-size-icon-middle;
          }
        }

        &__title {
          @extend %h2;
          margin-bottom: 10px;

          time {
            margin-top: 2px;
          }
        }

        &__footer {
          align-items: center;
          bottom: $grid-gutter-width / 2;
          display: flex;
          justify-content: space-between;
          left: $notice-box-padding-withmiddleicon;
          position: absolute;
          right: $grid-gutter-width / 2;
        }
      }
    }

    &__close {
      cursor: pointer;
      position: absolute;
      right: 7px;
      top: 7px;
    }

    &__icon {
      left: $notice-box-padding-horizontal;
      position: absolute;
      top: $notice-box-padding-vertical - 2;

      .e-icon {
        fill: inherit;
      }

      svg {
        height: $notice-size-icon;
        width: $notice-size-icon;
      }
    }

    &__title {
      display: block;
      font-size: 15px;
      font-weight: $text-bold-weight;
      line-height: $text-lineheight-condensed;
      margin-bottom: 8px;
      padding-top: 2px;

      &-large {
        font-size: typo('title', 'size');
        font-weight: normal;
        margin-top: 3px;
      }

      time {
        color: palette('grays', 'shade');
        display: block;
        font-size: typo('small', 'size');
        font-weight: normal;
        margin-top: -1px;
      }
    }

    &__footer {
      display: block;
      margin-top: 8px;
    }
  }
}
