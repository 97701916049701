.e {
  &-nodeicon {
    fill: $color-white;
    height: 50px;
    padding: 10px;
    pointer-events: none;
    width: 50px;

    &-small {
      height: 20px;
      margin-right: 5px;
      padding: 1px;
      vertical-align: top;
      width: 20px;
    }

    @each $class, $data in $node_style {
      @if $class == 'default' {
        background-color: $data;

        .e-node-report & {
          background-color: rgba($data, .4);
        }

        &.e-nodeicon-disabled {
          background-color: desaturate(lighten($data, 40), 30);
        }
      } @else {
        &-#{$class} {
          background-color: $data;

          &.e-nodeicon-disabled {
            background-color: desaturate(lighten($data, 10), 30);
          }

          .e-node-report & {
            background-color: rgba($data, .4);
          }
        }
      }
    }
  }
}
