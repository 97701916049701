.e {
  &-overlay {
    background: $overlay-color-background;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: z('modal-backdrop');

    &-animation {
      transition: opacity .3s ease-out;
      visibility: visible;

      &_hide {
        opacity: 0;
        transition: opacity .3s ease-out, visibility 0s .3s linear;
        visibility: hidden;
      }
    }

    &-local {
      margin: auto;
      position: absolute;
      z-index: z('modal-backdrop') - 1;
    }

    &-centered {
      display: table;
      text-align: center;

      &.e-overlay-local {
        display: block;
      }
    }

    &-inverse {
      background: $overlay-color-background-inverse;
    }

    &-transparent {
      background: transparent;
    }

    &-below_topnav {
      z-index: z('topnav') - 1;
    }

    &__center {
      display: table-cell;
      vertical-align: middle;

      .e-overlay-local & {
        display: block;
        height: 100%;
        position: relative;
        text-align: center;

        &:before {
          content: '';
          display: inline-block;
          height: 100%;
          margin-left: -5px;
          vertical-align: middle;
          width: 1px;
        }
      }
    }

    &__content {
      display: inline-block;
    }
  }
}
