$wysiwyg-editor-height: 400px;
$wysiwyg-toolbar-padding: 2px;
$wysiwyg-toolbar-button-gutter: 3px;
$wysiwyg-toolbar-button-size: 25px;
$wysiwyg-toolbar-height: $wysiwyg-toolbar-button-size + (2 * $wysiwyg-toolbar-padding);

.e {
  &-wysiwyg {
    // scss-lint:disable SelectorDepth, ImportantRule, SelectorFormat
    .htmlarea {
      border: 1px solid palette('grays', 'separator');

      .toolbar {
        background: palette('grays', 'body');
        border: 0;
        padding: $wysiwyg-toolbar-padding 0;

        select {
          margin-left: $wysiwyg-toolbar-button-gutter;
        }

        .button {
          background: transparent;
          border: 1px solid palette('default', 'x-light');
          cursor: pointer;
          display: inline-block;
          height: $wysiwyg-toolbar-button-size;
          line-height: 22px;
          margin-left: $wysiwyg-toolbar-button-gutter;
          padding: 0;
          text-align: center;
          vertical-align: middle;
          width: $wysiwyg-toolbar-button-size;

          &:after {
            display: none;
          }

          &:hover {
            background-color: darken(palette('grays', 'body'), $hover-modifier-amount);
          }

          img {
            filter: grayscale(100%);
            vertical-align: inherit;
          }

          &.buttonDisabled {
            cursor: default;
          }
        }
      }
      // stylelint-disable declaration-no-important

      > iframe {
        border: 0;
        border-top: 1px solid palette('grays', 'separator');
        height: $wysiwyg-editor-height !important;
        width: 100% !important;
      }
    }

    > textarea {
      color: palette('grays', 'text') !important;
      font-family: Consolas, Menlo, Monaco, monospace !important;
      font-size: typo('body', 'size') !important;
      height: $wysiwyg-editor-height !important;
      width: 100% !important;
    }

    &-textmode {
      .htmlarea {
        border: 0;

        .toolbar {
          display: none;
        }
      }

      > textarea {
        height: $wysiwyg-editor-height + $wysiwyg-toolbar-height !important;
      }
    }
  }
}
