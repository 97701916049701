e-bubble {
  color: $color-white;
  outline: 0;
}

.e {
  &-bubble {
    background: currentColor;
    border-radius: 7px;
    height: 14px;
    left: 0;
    position: absolute;
    width: 14px;

    &-base {
      background: $color-white;
      border: 1px solid palette('grays', 'separator');
    }

    &-range {
      background: transparent;
      border-radius: 50%;
      box-shadow: 0 18px 0 9px #fff, 0 18px 0 10px palette('default', 'x-light'), 0 18px 0 10px transparent, 0 18px 0 15px transparent;
      cursor: pointer;
      height: 1px;
      transition: box-shadow $transition-time ease-out;
      width: 1px;
    }

    &__line {
      background: palette('grays', 'separator');
      height: 33px;
      margin-top: 1px;
      position: absolute;
      transform: translateX(-.5px);
      width: 1px;
    }

    &-slider {
      background: #fff;
      border: 1px solid palette('default', 'x-light');
      border-radius: 50%;
      bottom: 0;
      cursor: pointer;
      height: 20px;
      transform: translateX(-10px);
      transition: box-shadow $transition-time ease-out;
      width: 20px;
    }

    &-hover {
      box-shadow: 0 0 0 2px rgba(palette('default', 'x-light'), .5);
    }

    &-active {
      border: 1px solid palette('secondary', 'light');
      box-shadow: 0 0 0 2px rgba(palette('secondary', 'x-light'), .5);
      will-change: left;
      z-index: 1;
    }
  }
}

.e-compare {
  .e-bubble {
    margin-left: -7px;
    top: 52px;
    transform: translateY(-50%);
    z-index: 1;
  }

  .e-bubble-label {
    animation: none;
    background: transparent;
    border: 0;
    color: palette('grays', 'text');
    opacity: 1;
    padding: 0;
    transform: translate3d(0, -24px, 0);
  }
}

.e-range__helper {
  .e-bubble {
    bottom: 34px;
    pointer-events: none;
    z-index: 1;
  }
}

e-slider {
  // &:not([disabled]) {
  //   .e-bubble-slider:hover,
  //   &:not([range]) .e-slider__line:hover ~ e-bubble .e-bubble-slider,
  //   e-bubble:focus .e-bubble {
  //     box-shadow: 0 0 0 2px rgba(palette('default', 'x-light'), .5);
  //   }
  // }

  &[disabled] {
    .e-bubble-slider {
      cursor: default;
    }
  }
}
