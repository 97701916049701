$hamburger-color: map-get($palette_navigation, 'text') !default;
$hamburger-color-background: map-get($palette_navigation, 'background-hover');
$hamburger-box-size: $fixed-topnav-height !default;

.e {
  &-hamburger {
    // float: right;
    // margin-left: 1px;
    // background: $hamburger-color-background;
    cursor: pointer;
    display: block;
    height: $hamburger-box-size;
    left: 0;
    position: absolute;
    text-decoration: none;
    top: 0;
    transition: all .3s ease;
    user-select: none;
    width: $hamburger-box-size;

    @include screen('topnav', 'up') {
      display: none;
    }

    .e-topnav__mobilemenu:checked ~ &,
    &.e-hamburger-active {
      &:before {
        transform: translate(3px, -1px) rotate(45deg);
      }

      &:after {
        transform: translate(3px, 2px) rotate(-45deg);
      }

      .e-hamburger__line {
        opacity: 0;
      }
    }

    &:before,
    &:after,
    &__line {
      background: $hamburger-color;
      border-radius: 2px;
      content: '';
      display: block;
      height: 3px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      transform-origin: left;
      transition: transform .2s ease;
      width: 24px;
    }

    &:before {
      top: 22px;
    }

    &:after {
      top: 36px;
    }

    &__line {
      bottom: 0;
      top: 0;
      transition: opacity .2s ease;
    }
  }
}
