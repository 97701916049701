$box-color-background: $color-white !default;
$box-color-background-charts: $color-body !default;
$box-box-shadow: 0 0 5px rgba($color-black, .1);
$box-box-login-width: 1200px !default;
$box-box-loginform-width: 360px !default;
$box-box-summary-width: 360px !default;
$box-color-border: palette('grays', 'separator') !default;
$box-color-inner-background: palette('grays', 'inactive') !default;
$box-color-inner-separator: palette('grays', 'separator') !default;

@mixin e-box-style {
  background: $box-color-background;
  border: 1px solid $box-color-border;
  box-shadow: $box-box-shadow;
}

@mixin e-box {
  @include e-box-style;
  margin-bottom: $grid-gutter-width;
  padding: $grid-gutter-width / 2;
  padding-bottom: $grid-gutter-width;
}

.e {
  &-box {
    @include e-box;

    &-inner {
      background: $box-color-inner-background;
      border-color: $box-color-inner-separator;
      box-shadow: none;
      padding: $grid-gutter-width;
    }

    &-charts {
      background: $box-color-background-charts;
      box-shadow: none;
      padding: 0;
    }

    &-chart {
      box-shadow: none;
      padding: $grid-gutter-width / 2;
    }

    &-nopadding {
      padding: 0;

      .e-box__header {
        margin: 0;
      }
    }

    &-no_margin {
      margin: 0;
    }

    &-smallmargin {
      margin-bottom: $grid-gutter-width / 2;
    }

    &-summary {
      border-bottom: 0;
      border-right: 0;
      border-top: 0;
      bottom: 0;
      margin-bottom: 0;
      max-width: $box-box-summary-width;
      padding: $grid-gutter-width;
      position: absolute;
      right: 0;
      top: 0;
      z-index: z('fixed-element');

      &__close {
        position: absolute;
        right: 5px;
        top: 5px;
      }

      &__title {
        @include word-wrap;
        color: map-get($palette_navigation, 'background');
        font-size: typo('subheader', 'size');
      }
    }

    &-login {
      @include clearfix;
      background: rgba($color-white, .3);
      border: 0;
      box-shadow: none;
      color: palette('grays', 'empty');
      display: inline-block;
      padding: 25px;

      &-dark {
        background: rgba(palette('grays', 'text'), .6);
      }

      &-wide {
        max-width: $box-box-login-width;
      }

      h1 {
        color: palette('grays', 'empty');
      }
    }

    &__loginform {
      display: inline-block;
      margin-top: -12px;
      vertical-align: top;
      width: $box-box-loginform-width;
    }

    &__loginsplash {
      margin-left: 25px;
    }

    &__header {
      align-items: center;
      background: palette('grays', 'inactive');
      border-bottom: 1px solid palette('grays', 'separator');
      display: flex;
      height: 38px;
      margin: (- $grid-gutter-width / 2) (- $grid-gutter-width / 2) 0 (- $grid-gutter-width / 2);

      > * {
        flex: 0 0 auto;

        &.e-box__title {
          flex: 1 1 auto;
        }
      }
    }

    &__title {
      @include text-overflow;
      font-size: typo('subheader', 'size');
      padding-left: 15px;
    }
  }
}
