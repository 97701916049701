@import 'assets';

.e {
  &-btn {
    @include e-btn;

    // scss-lint:disable NameFormat
    @each $class, $data in $btn_style {
      // scss-lint:enable NameFormat
      &-#{$class} {
        @include e-btn-modifier($class);
      }
    }

    // scss-lint:disable SelectorFormat
    &.button_inactive {
      cursor: default;
      opacity: .5;
    }

    &__separator {
      @include embossed-border;
      display: inline-block;
      height: $btn-box-height - 2;
      margin-right: 10px;
      padding-right: 5px;
    }

    &__loading {
      float: left;

      .e-btn-onlyicon & {
        float: none;
      }

      .e-spinner {
        display: none;
      }

      &-active {
        .e-spinner {
          display: inline-block;
        }

        .e-btn & .e-icon,
        .e-btn & e-icon {
          display: none;
        }
      }
    }

    &-has_overlay {
      opacity: 1;
      position: relative;

      .e-btn__loading {
        background: rgba($color-white, .5);
        bottom: -1px;
        display: none;
        left: -1px;
        position: absolute;
        right: -1px;
        text-align: center;
        top: -1px;

        &-active {
          display: inline-block;
        }

        .e-spinner {
          margin: 0 0 0 -4px;

          .e-circles__circle:before {
            background-color: map-get($palette_navigation, 'background');
          }
        }
      }
    }

    &-dropdown {
      padding-right: 2em;
      position: relative;

      &:after {
        @include triangle(bottom, currentcolor, .4em, 1);
        margin-top: -3px;
        position: absolute;
        right: .7em;
        top: 50%;
      }

      &.e-btn-topnav {
        padding: 0 28px 0 15px;

        &:after {
          margin-top: -2px;
        }
      }
    }

    .e-icon {
      display: inline-block;
      height: $icon-size-button;
      line-height: $btn-box-height;
      margin: -2px 8px 0 -4px;
      vertical-align: middle;
      width: $icon-size-button;
    }

    .e-spinner {
      margin: -3px 7px 0 -4px;

      .e-circles__circle:before {
        background-color: currentColor;
      }
    }

    &-onlyicon {
      @include e-btn-onlyicon;

      .e-icon,
      .e-spinner {
        margin: -2px 0 0;
      }
    }

    &-on_overlay {
      @include e-btn-on_overlay;
    }

    &-inlineicon {
      .e-icon {
        margin: -2px 2px 0 5px;
      }
    }

    &-no_minwidth {
      min-width: 0;
    }

    &-borderless {
      background: transparent;
      border-color: transparent;

      &:hover,
      &.e-btn-active {
        background: $btn-color-background;
        border-color: $btn-color-border;
      }
    }

    &-fieldset {
      display: block;
      margin-top: -1px;
    }

    &-small {
      font-size: $btn-text-size-small;
      height: $btn-box-height-small;
      line-height: $btn-box-height-small - 2;

      &.e-btn-onlyicon {
        width: $btn-box-height-small;

        .e-icon {
          height: $icon-size-small;
          width: $icon-size-small;
        }
      }
    }

    &-fullwidth {
      width: 100%;
    }

    &-badge {
      height: $btn-box-height-badge;
      line-height: $btn-box-height-badge - 2px;
      min-width: 0;
      padding: 0 2px;

      .e-icon {
        height: $icon-size-badge;
        margin: -2px 0 0;
        width: $icon-size-badge;
      }
    }

    &-fileinput {
      overflow: hidden;
      position: relative;

      input {
        cursor: pointer;
        direction: ltr;
        display: block;
        font-size: $btn-text-size;
        height: 100%;
        margin: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }

    &-topnav,
    &-customerinfo {
      background-color: map-get($palette_navigation, 'background-hover');
      border-color: transparent;
      color: map-get($palette_navigation, 'text');
      height: $fixed-topnav-height;
      line-height: $fixed-topnav-height - 2;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        background-color: map-get($palette_navigation, 'background-hover');
        box-shadow: none;
        color: map-get($palette_navigation, 'text');
        outline: none;
      }

      &:hover,
      &:focus {
        background-color: darken(map-get($palette_navigation, 'background'), 5);
      }
    }

    &-customerinfo {
      font-size: typo('body', 'size');
      min-width: 0;
      padding: 0 30px 0 20px;

      &:after {
        margin-top: -2px;
        right: 15px;
      }
    }

    &-link {
      background-color: transparent;
      border-color: transparent;
      color: palette('secondary');
      padding: 0;

      .e-icon {
        margin: -3px 4px 0 0;
      }

      &.e-btn-onlyicon {
        .e-icon {
          margin: -3px 0 0;
        }
      }

      svg {
        fill: palette('secondary');
      }

      label {
        color: palette('grays', 'text');
      }

      &:active,
      &:visited {
        color: palette('secondary');

        svg {
          fill: palette('secondary');
        }
      }

      &:focus,
      &:hover {
        background: transparent;
        box-shadow: none;
        color: darken(palette('secondary'), $hover-modifier-amount);

        svg {
          fill: darken(palette('secondary'), $hover-modifier-amount);
        }
      }
    }

    &-large {
      height: $btn-box-height-large;
      line-height: $btn-box-height-large - 2px;
    }

    &-extended {
      line-height: 1.1em;

      small {
        display: block;
        font-size: $btn-text-size-small;
      }
    }

    &-checked {
      background: darken($btn-color-background, 5);
      box-shadow: $btn-checked-shadow;
    }

    &-narrow {
      padding: 0 ($btn-box-sidepadding / 2);

      &.e-btn-onlyicon {
        padding: 0;
        width: 20px;
      }
    }

    &-sticky_left { // email-preview
      border-left: 0;
    }

    &-sticky_right { // email-preview
      border-right: 0;
    }
  }
}
