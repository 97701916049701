$checkbox-text-size: typo('body', 'size') !default;
$checkbox-box-size: 18px !default;
$checkbox-color-box-border: palette('default', 'x-light') !default;
$checkbox-color-background: $color-white !default;
$checkbox-color-border-focus: palette('secondary') !default;
$checkbox-focus-transition: all $transition-time ease-in-out !default;

$checkbox-check: url('checkbox-check.svg') !default;
$checkbox-check-white: url('checkbox-check-white.svg') !default;

.e {
  &-checkbox {
    display: inline;
    opacity: 0;
    position: absolute;

    &:focus {
      + .e-checkbox__label,
      + label {
        &:before {
          border-color: $checkbox-color-border-focus;
        }
      }
    }

    &.e-input-error {
      + label {
        color: $input-color-border-error;

        &:before {
          border: 1px dashed $input-color-border-error;
        }
      }
    }

    + .e-checkbox__label,
    + label {
      cursor: pointer;
      font-size: $checkbox-text-size;
      margin-right: 10px;
      padding-left: $checkbox-box-size + 6px;
      position: relative;
      user-select: none;

      &:before {
        background: $checkbox-color-background;
        border: 1px solid $checkbox-color-box-border;
        content: '';
        display: inline-block;
        height: $checkbox-box-size;
        left: 0;
        margin-right: 8px;
        position: absolute;
        top: -2px;
        transition: $checkbox-focus-transition;
        width: $checkbox-box-size;
      }
    }

    &:checked {
      + .e-checkbox__label,
      + label {
        &:after {
          background-image: url('e-checks.svg');
          content: '';
          height: 12px;
          left: 3px;
          line-height: 1;
          position: absolute;
          top: 1px;
          width: 12px;
        }
      }
    }

    &:indeterminate {
      + .e-checkbox__label,
      + label {
        &:after {
          background: palette('grays', 'text');
          content: '';
          height: 3px;
          left: 5px;
          position: absolute;
          top: 5.5px;
          width: 8px;
        }
      }
    }

    &:disabled {
      + .e-checkbox__label,
      + label {
        cursor: default;

        &:after,
        &:before {
          opacity: .4;
        }
      }
    }

    &-block {
      + label {
        display: inline-block;

        &:before {
          top: 1px;
        }
      }

      &:checked {
        + label {
          &:after {
            top: 4px;
          }
        }
      }
    }

    &-nocolor_empty:not(:checked) {
      + .e-checkbox__label,
      + label {
        &:before {
          background: transparent;
        }
      }
    }

    &-onlycheckbox {
      + label {
        margin-right: 0;
        padding-left: 18px;
      }
    }

    &-right {
      + .e-checkbox__label,
      + label {
        display: block;
        margin-right: 0;
        padding-left: 0;
        padding-right: $checkbox-box-size + 6px;

        &:before {
          left: auto;
          margin-left: 8px;
          margin-right: 0;
          position: absolute;
          right: 0;
          top: 1px;
        }
      }

      &:checked {
        + .e-checkbox__label,
        + label {
          &:after {
            left: auto;
            right: 3px;
            top: 4px;
          }
        }
      }
    }

    @each $class, $data in $palette_reporting {
      &-#{$class} {
        + .e-checkbox__label,
        + label {
          &:before {
            background: #{$data};
            border-color: #{$data};
          }
        }

        &:checked {
          + .e-checkbox__label,
          + label {
            &:after {
              background-position: 0 -12px;
            }
          }
        }
      }
    }

    @each $class, $data in $palette_socialads {
      &-#{$class} {
        + .e-checkbox__label,
        + label {
          &:before {
            background: #{$data};
            border-color: #{$data};
          }
        }

        &:checked {
          + .e-checkbox__label,
          + label {
            &:after {
              background-position: 0 -12px;
            }
          }
        }
      }
    }

    @each $class, $data in $palette_smartinsight {
      &-#{$class} {
        + .e-checkbox__label,
        + label {
          &:before {
            background: #{$data};
            border-color: #{$data};
          }
        }

        &:checked {
          + .e-checkbox__label,
          + label {
            &:after {
              background-position: 0 -12px;
            }
          }
        }
      }
    }
  }
}
