e-dropdown {
  display: inline-block;
  position: relative;
  user-select: none;

  .e-separator {
    margin-bottom: 0;
  }
}

.e {
  &-dropdown {
    display: inline-block;
    position: relative;
    user-select: none;

    &.open {
      .e-dropdown__inner {
        display: block;
      }
    }

    &-popper {
      margin-top: 11px;
      top: -99999px;
      z-index: z('flying');

      .e-dropdown__content {
        background: $dropdown-color-inner-background;
        box-shadow: 0 0 7px 0 rgba($color-black, .16);
      }

      .popper__arrow {
        background: $dropdown-color-inner-background;
        box-shadow: 2px 2px 4px rgba($color-black, .06);
        height: $dropdown-box-arrow-size;
        margin: -6px 0;
        position: absolute;
        top: 0;
        transform: rotate(-135deg);
        width: $dropdown-box-arrow-size;
      }

      &[x-placement^='top-end'] {
        margin-bottom: 11px;

        .popper__arrow {
          bottom: 0;
          top: auto;
          transform: rotate(45deg);
        }
      }
    }

    &-popper_sticky {
      margin-top: -1px;

      .e-dropdown__content {
        border: 1px solid palette('default', 'x-light');
        box-shadow: 0 2px 5px 0 rgba(#000, .16);
      }

      .popper__arrow {
        display: none;
      }

      &[x-placement^='top-end'] {
        margin-bottom: -1px;

        .e-dropdown__content {
          box-shadow: 0 0 5px 0 rgba(#000, .16);
        }
      }
    }

    &__content {
      display: none;

      &-visible {
        display: block;
      }
    }

    &__button {
      border: 1px solid transparent;
      cursor: pointer;
      display: inline-block;

      .e-icon-table {
        margin-top: -2px;
      }

      &:hover {
        border-color: palette('default', 'x-light');
      }

      &-open {
        background: palette('grays', 'inactive');
        border-color: palette('default', 'x-light');
      }
    }

    &__item {
      color: $dropdown-color-text;
      cursor: pointer;
      display: block;
      fill: $dropdown-color-text;
      font-size: $dropdown-text-size;
      padding: $dropdown-box-padding;
      position: relative;
      text-align: left;
      text-decoration: none;
      transition: $dropdown-hover-transition;
      white-space: nowrap;
      z-index: z('dropdown') + 1;

      a {
        color: $dropdown-color-text;
        display: block;

        &:hover {
          color: palette('secondary', 'light');
          fill: palette('secondary', 'light');
        }
      }

      &:visited {
        color: $dropdown-color-text;
      }

      &:hover {
        background: palette('grays', 'inactive');
        color: palette('secondary', 'light');
        fill: palette('secondary', 'light');
      }

      &-righticon {
        cursor: default;
        padding: $dropdown-box-padding-righticon;

        a {
          margin-right: $grid-gutter-width;
        }
      }

      &-fixwidth {
        @include text-overflow;
        max-width: 600px;

        a {
          @include text-overflow;
        }
      }
    }

    &__container { // For 'rich' content, everything which is not a single icon+link
      padding: $grid-gutter-width / 2;
      text-align: left;
      white-space: normal;
      width: 400px;

      &-item {
        font-size: $dropdown-text-size;
        max-width: 400px;
        padding: $dropdown-box-padding;
        width: auto;
        z-index: z('dropdown') + 1;
      }
    }

    .e-separator {
      margin-bottom: 0;
    }
  }
}

.e-dropdown__inner,
e-dropdown > .e-dropdown__content {
  background: $dropdown-color-inner-background;
  box-shadow: 0 0 7px 0 rgba($color-black, .16);
  display: none;
  margin-top: 11px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: z('dropdown');

  &:after {
    background: $dropdown-color-inner-background;
    box-shadow: 2px 2px 4px rgba($color-black, .06);
    content: '';
    display: inline-block;
    height: $dropdown-box-arrow-size;
    margin: -6px 0;
    position: absolute;
    right: 8px;
    top: 0;
    transform: rotate(-135deg);
    width: $dropdown-box-arrow-size;
  }

  &-sticky {
    border: 1px solid palette('default', 'x-light');
    box-shadow: 0 2px 5px 0 rgba(#000, .16);
    left: auto;
    margin-top: -1px;
    min-width: 100%;
    right: 0;

    &:after {
      display: none;
    }

    .e-dropdown__item {
      line-height: $text-lineheight;
    }

    &.e-dropdown__content-alignright {
      left: 0;
      margin-left: 0;
      right: auto;
    }
  }

  &-alignright {
    left: 100%;
    margin-left: -($dropdown-box-arrow-size + 15px);
    right: auto;

    &:after {
      left: 8px;
      right: auto;
    }
  }

  &-aligntop {
    bottom: 100%;
    margin-bottom: 15px;
    top: auto;

    &:after {
      bottom: 0;
      box-shadow: -2px -2px 4px rgba($color-black, .06);
      top: auto;
    }
  }

  &-fullwidth {
    min-width: 100%;
  }

  &-open {
    display: inline-block;
  }

  @each $class, $color in $dropdown_style {
    &-#{$class},
    e-dropdown > &-#{$class} {
      background: $color;
      color: $color-white;

      &:after {
        background: $color;
      }
    }
  }
}

.e-buttongroup:not(.e-buttongroup-sticky) .e-dropdown__inner,
.e-buttongroup:not(.e-buttongroup-sticky) e-dropdown > .e-dropdown__content {
  right: 6px;

  &-sticky {
    right: auto;
  }
}
