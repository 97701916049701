$steps-size-text: 14px !default;
$steps-color-background: map-get($palette_navigation, 'background') !default;
$steps-color-background-hover: map-get($palette_navigation, 'background-hover') !default;
$steps-color-background-active: map-get($palette_navigation, 'background-active') !default;
$steps-box-counter-size: 20px !default;
$steps-text-color: palette('grays', 'empty');
$steps-text-color-disabled: palette('grays', 'shade') !default;
$steps-color-counter-background-current: palette('primary') !default;
$steps-color-counter-background-disabled: map-get($palette_navigation, 'background-active') !default;
$steps-color-counter-background: palette('secondary') !default;
$steps-maxsteps: 7 !default;

.e {
  &-steps {
    @include clearfix;
    background: $steps-color-background;
    bottom: 0;
    font-weight: $text-bold-weight;
    height: $steps-box-size;
    left: 0;
    margin: 0;
    position: fixed;
    width: 100%;
    z-index: z('fixed-element');

    &__progress {
      counter-reset: progressStep;
      display: table;
      font-size: 0;
      margin: auto;
      overflow: hidden;

      @include screen('topnav', 'down') {
        padding: 0 $steps-box-size;
      }
    }

    &__item,
    &__item:visited,
    &__item:hover {
      color: $steps-text-color;
    }

    &__item {
      background: $steps-color-background;
      cursor: default;
      display: table-cell;
      font-size: $steps-size-text;
      height: $steps-box-size;
      line-height: $text-lineheight-condensed;
      padding-left: 65px;
      padding-right: 5px;
      padding-top: 2px;
      position: relative;
      transition: initial;
      vertical-align: middle;

      @include screen('small', 'down') {
        padding-left: 30px;
      }

      &-action {
        cursor: pointer;

        &:hover {
          background: $steps-color-background-hover;
          color: palette('grays', 'empty');
        }
      }

      &:first-child {
        padding-left: 50px;

        @include screen('small', 'down') {
          padding-left: 15px;
        }

        &:before {
          left: 15px;
        }
      }

      &:last-child {
        padding-right: 15px;

        &:after {
          display: none;
        }
      }

      @for $i from 1 through $steps-maxsteps {
        &:nth-child(#{$i}) {
          z-index: $steps-maxsteps - $i + 1;
        }
      }

      &:before {
        background: $steps-color-counter-background;
        border-radius: 50%;
        color: $steps-color-background;
        content: counter(progressStep);
        counter-increment: progressStep;
        height: $steps-box-counter-size;
        left: 30px;
        line-height: $steps-box-counter-size;
        position: absolute;
        text-align: center;
        top: 15px;
        width: $steps-box-counter-size;

        @include screen('small', 'down') {
          display: none;
        }
      }

      &:after {
        background: inherit;
        border-right: 1px solid lighten($steps-color-background, 10);
        border-top: 1px solid lighten($steps-color-background, 10);
        content: '';
        height: 50px;
        position: absolute;
        right: -22px;
        top: -10px;
        transform: rotate(58deg) skew(25deg, 0deg);
        width: 80px;
        z-index: -1;
      }

      &-current,
      &-current:visited {
        background: $steps-color-background-active;

        &:before {
          background: $steps-color-counter-background-current;
          color: $steps-color-background-active;
        }
      }

      &-disabled,
      &-disabled:visited,
      &-disabled:hover {
        color: $steps-text-color-disabled;

        &:before {
          background: $steps-color-counter-background-disabled;
          color: $steps-text-color-disabled;
        }
      }
    }

    &__nav,
    &__nav:visited {
      color: $steps-text-color;
    }

    &__nav {
      background: $steps-color-background;
      cursor: pointer;
      font-size: $steps-size-text;
      height: $steps-box-size;
      line-height: $steps-box-size + 1;
      padding: 0 20px;
      transition: initial;
      z-index: $steps-maxsteps + 1;

      @include screen('topnav', 'down') {
        position: absolute;
        width: $steps-box-size;

        span {
          display: none;
        }
      }

      .e-icon {
        fill: $steps-text-color;
        height: 18px;
        margin-top: 17px;
        width: 18px;
      }

      e-icon .e-icon {
        margin-top: 0;
      }

      &-prev {
        border-right: 1px solid $steps-color-background-active;
        box-shadow: inset -1px 0 0 0 $steps-color-background-hover;
        float: left;

        .e-icon {
          float: left;
          margin-left: -5px;
          margin-right: 12px;
        }

        //scss-lint:disable DeclarationOrder
        @include screen('topnav', 'down') {
          left: 0;

          .e-icon {
            margin-left: -5px;
            margin-right: -5px;
          }
        }
      }

      &-next {
        border-left: 1px solid $steps-color-background-active;
        box-shadow: -1px 0 0 0 $steps-color-background-hover;
        float: right;

        .e-icon {
          float: right;
          margin-left: 12px;
          margin-right: -5px;
        }

        //scss-lint:disable DeclarationOrder
        @include screen('topnav', 'down') {
          right: 0;

          .e-icon {
            margin-left: -5px;
            margin-right: -5px;
          }
        }
      }

      &:hover {
        background: $steps-color-background-hover;
      }

      &-disabled,
      &-disabled:visited {
        color: $steps-text-color-disabled;
        cursor: default;
      }

      &-disabled {
        .e-icon {
          fill: $steps-text-color-disabled;
        }

        &:hover {
          background: $steps-color-background;
          color: $steps-text-color-disabled;

          .e-icon {
            fill: $steps-text-color-disabled;
          }
        }
      }
    }
  }
}
