$buttongroup-gap-normal: $grid-gutter-width / 6;

@mixin e-buttongroup($btn-selector: '.e-btn') {
  @include clearfix;
  margin-left: -$buttongroup-gap-normal;
  margin-right: -$buttongroup-gap-normal;

  > * {
    float: left;
  }

  #{$btn-selector} {
    margin-left: $buttongroup-gap-normal;
    margin-right: $buttongroup-gap-normal;
  }
}

@mixin e-buttongroup-modifier($modifier: 'default', $btn-selector: '.e-btn') {
  @if $modifier == 'sticky' {
    margin-left: 0;
    margin-right: 0;

    #{$btn-selector} {
      border-left-width: 0;
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        border-left-width: 1px;
      }
    }
  }
}

.e {
  &-buttongroup {
    @include e-buttongroup;

    &-sticky {
      @include e-buttongroup-modifier('sticky');
    }

    &-flex {
      align-items: baseline;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:before,
      &:after {
        display: none;
      }

      > * {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 0;
        margin-bottom: $buttongroup-gap-normal * 2;
      }
    }
  }
}
