$radio-text-size: typo('body', 'size') !default;
$radio-box-size: 18px !default;
$radio-box-size-dot: 10px !default;
$radio-color-box-border: palette('default', 'x-light') !default;
$radio-color-background: #fff !default;
$radio-color-checked: palette('secondary') !default;
$radio-color-button-checked: #f00;
$radio-color-border-focus: palette('secondary') !default;
$radio-focus-transition: all $transition-time ease-in-out !default;

.e {
  &-radio {
    display: inline;
    opacity: 0;
    position: absolute;

    &:focus {
      + label {
        &:before {
          border-color: $radio-color-border-focus;
        }
      }
    }

    &.e-input-error {
      + label {
        color: $input-color-border-error;

        &:before {
          border: 1px dashed $input-color-border-error;
        }
      }
    }

    + label {
      cursor: pointer;
      font-size: $radio-text-size;
      margin-right: 10px;
      padding-left: $radio-box-size + 6px;
      position: relative;
      user-select: none;

      &:before {
        background: $radio-color-background;
        border: 1px solid $radio-color-box-border;
        border-radius: $radio-box-size;
        content: '';
        display: inline-block;
        height: $radio-box-size;
        left: 0;
        margin-right: 8px;
        position: absolute;
        top: -2px;
        transition: $radio-focus-transition;
        width: $radio-box-size;
      }
    }

    &:checked {
      + label {
        &:after {
          background: $radio-color-checked;
          border-radius: $radio-box-size-dot;
          content: '';
          height: $radio-box-size-dot;
          left: 4px;
          line-height: 1;
          position: absolute;
          top: 2px;
          width: $radio-box-size-dot;
        }
      }
    }

    &:disabled {
      + label {
        cursor: default;

        &:after,
        &:before {
          opacity: .4;
        }
      }
    }

    &-block {
      + label {
        display: inline-block;

        &:before {
          top: 1px;
        }
      }

      &:checked {
        + label {
          &:after {
            top: 5px;
          }
        }
      }
    }
  }
}
