$list-box-indent: 25px !default;
$list-box-indent-number: $grid-gutter-width !default;
$list-color-counter: #fff !default;
$list-color-counter-background: palette('success') !default;

.e {
  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item {
      padding-bottom: $grid-gutter-width / 4;

      &__title {
        display: block;
        font-size: typo('subheader', 'size');
        font-weight: $text-bold-weight;
      }

      &-node {
        cursor: pointer;
        margin: 0 -15px 1px;
        padding: 5px 5px 5px 28px;

        &:hover {
          background-color: palette('grays', 'separator');

          .e-fullheight & {
            background-color: palette('grays', 'inactive');
          }
        }
      }

      &-has_icon {
        margin-bottom: 15px;
        min-height: 40px;
        padding-left: 55px;
        position: relative;

        e-icon,
        .e-icon {
          height: 40px;
          left: 0;
          position: absolute;
          top: 0;
          width: 40px;
        }
      }
    }

    &-indented {
      padding-left: $list-box-indent;
    }

    &-numbered {
      counter-reset: e-counter;
      padding-left: $list-box-indent-number;

      .e-list__item {
        position: relative;

        &:before {
          background: $list-color-counter-background;
          color: $list-color-counter;
          content: counter(e-counter);
          counter-increment: e-counter;
          left: - $list-box-indent-number;
          padding: 0 6px;
          position: absolute;
          top: 0;
        }
      }
    }
  }
}
