$sidepanel-box-headerheight: $fixed-topnav-height !default;
$sidepanel-box-footerheight: 40px !default;
$sidepanel-box-width: 330px !default;

.e {
  &-sidepanel {
    background: $color-white;
    box-shadow: $box-box-shadow, 0 0 0 1px $box-color-border inset;
    height: 100%;
    padding-bottom: $sidepanel-box-footerheight - 1;
    padding-top: $sidepanel-box-headerheight - 1;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(100%, 0, 0);
    transition: transform .3s ease-out;
    width: $sidepanel-box-width;
    z-index: z('modal-backdrop') + 1;

    &__header {
      @include center-content(false, '&__title');
      background: map-get($palette_navigation, 'background-hover');
      height: $sidepanel-box-headerheight;
      left: 0;
      padding: 0 35px 0 ($grid-gutter-width / 2);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: z('default');

      &__title {
        color: map-get($palette_navigation, 'text-hover');
        font-size: typo('subheader', 'size');
      }

      &__icon {
        fill: map-get($palette_navigation, 'text-hover');
        margin-right: 10px;
      }
    }

    &__close {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      .e-icon {
        fill: map-get($palette_navigation, 'text-hover');
      }

      e-icon {
        cursor: pointer;
      }
    }

    &__content {
      height: 100%;
      overflow: auto;
    }

    &__footer {
      border-top: 1px solid $box-color-border;
      bottom: 0;
      height: $sidepanel-box-footerheight;
      left: 0;
      padding: 10px $grid-gutter-width / 2;
      position: absolute;
      width: 100%;
      z-index: z('default');
    }

    &-open {
      transform: translate3d(0, 0, 0);
    }
  }
}
