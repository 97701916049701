e-funnelline {
  display: block;
}

.e {
  &-funnelchart {
    background: url('e-funnel-pattern.svg');
    background-position: 0 -2.5px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    &__legend {
      flex: 0 1 auto;
      width: calc(100% - 260px);
    }

    &__row {
      display: flex;
      justify-content: space-between;
      line-height: 39px;
    }

    &__title {
      @include text-overflow;
      padding-right: $grid-gutter-width / 2;
    }

    &__value {
      font-size: typo('title', 'size');
      font-weight: typo('title', 'weight');
    }

    &__chart {
      flex: 0 0 auto;
      font-size: 0;

      svg {
        margin-bottom: 5px;
      }
    }
  }
}
