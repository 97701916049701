$select2-color-highlighted-background: palette('secondary') !default;

// stylelint-disable declaration-no-important

.e {
  &-select {
    &__select2 {
      z-index: z('default');

      &-relative {
        overflow: visible;

        .select2-choices {
          background: transparent;
        }

        .select2-drop {
          margin-left: -1px;
          width: calc(100% + 2px);

          .select2-result-single {
            padding: 0;
          }
        }
      }

      &.select2-container,
      &.select2-container-multi {
        &-active {
          border-color: $select-color-border-focus;
          box-shadow: 0 0 4px rgba($input-color-border-focus, .3);
        }

        &-disabled {
          background-color: $select-color-background-disabled;
          cursor: not-allowed;

          .select2-chosen {
            color: $select-color-disabled;
          }
        }

        .select2-choices {
          border: 0 !important;
          line-height: $box-form-element-height;
        }

        .select2-choice {
          background: transparent !important;
          background-image: none !important;
          border: 0 !important;
          border-radius: 0 !important;
          filter: progid:DXImageTransform.Microsoft.gradient(enabled = false) !important;
          height: $input-box-height - 2 !important;
          line-height: $input-box-height - 2 !important;
          padding: $input-box-padding !important;

          &.select2-default {
            color: $select-text-color-placeholder;
            font-style: italic;
          }

          > .select2-chosen {
            margin-right: 15px;
          }

          .select2-arrow {
            background: transparent;
            background-image: $select-caret;
            background-position: 100% 50%;
            background-repeat: no-repeat;
            border: 0;
            filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);

            b {
              background: transparent !important;
            }
          }
        }
      }

      &.select2-container-multi {
        height: auto;
        position: relative;
        white-space: normal;

        .select2-choices {
          .select2-search-choice {
            @include e-label;
            @include e-label-modifier('tag');
            border: 0;
            box-shadow: none;
            color: $color-white;
            filter: progid:DXImageTransform.Microsoft.gradient(enabled = false) !important;
            height: auto;
            margin: 5px 0 3px 5px !important;
            padding-right: 1.6em !important;
            text-align: left !important;
            white-space: normal !important;

            > div {
              @include word-wrap;
            }
          }

          .select2-search-choice-focus {
            background: palette('danger') !important;
            filter: progid:DXImageTransform.Microsoft.gradient(enabled = false) !important;
          }

          .select2-search-choice-close {
            @include e-label-close;
            background: url('close-white.svg') center center no-repeat !important;
            background-position: 50% !important;
            height: 100%;
            left: auto;
            right: 6px;
            top: 0;
            width: 16px;
          }
        }
      }

      .ui-select-offscreen {
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        left: 0 !important;
        margin: 0 !important;
        outline: 0 !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        top: 0 !important;
        width: 1px !important;
      }
    }
  }
}

.select2-results {
  // scss-lint:disable ImportantRule,ColorVariable
  .select2-highlighted {
    background: $select2-color-highlighted-background !important;
  }

  .select2-disabled {
    background: transparent !important;
    color: #808080 !important;
  }

  li {
    max-width: none;
  }
}

.select2-result-label {
  @include word-wrap;
}

.select2-choices {
  background-image: none !important;
  line-height: $box-form-element-height;
}

.select2-drop {
  // scss-lint:disable ImportantRule
  border-radius: 0 !important;

  &-active {
    border-color: $select-color-border-focus !important;
    box-shadow: 0 0 4px rgba($input-color-border-focus, .3) !important;

    &.select2-drop-above {
      border-color: $select-color-border-focus !important;
      box-shadow: 0 0 4px rgba($input-color-border-focus, .3) !important;
    }
  }
}

// scss-lint:disable QualifyingElement
div.e-select__select2 {
  padding: 0;
}
