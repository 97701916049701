$toolbox-color-background: palette('grays', 'inactive') !default;
$toolbox-color-border: $input-color-border !default;
$toolbox-color-hover: palette('grays', 'separator') !default;
$toolbox-box-height: $box-form-element-height !default;
$toolbox-box-padding: 10px !default;

.e {
  &-toolbox {
    &__content {
      @include clearfix;
      background-color: $toolbox-color-background;
      border-left: 1px solid $toolbox-color-border;
      border-right: 1px solid $toolbox-color-border;
      border-top: 1px solid $toolbox-color-border;
      line-height: $toolbox-box-height;
      padding: 0 $toolbox-box-padding;
    }

    &__actions {
      float: left;
      margin-left: -$toolbox-box-padding;
      margin-right: -$toolbox-box-padding;

      .e-select {
        border-bottom: 0;
        border-top: 0;
        float: left;
      }

      .e-btn {
        background: transparent;
        border: 0;
        float: left;
        line-height: $toolbox-box-height;

        &:hover {
          background-color: $toolbox-color-hover;
          box-shadow: none;
        }
      }

      .e-input {
        background: transparent;
        border: 0;
      }
    }
  }
}
