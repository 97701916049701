/* Need for IE 6 */
/* stylelint-disable media-feature-no-missing-punctuation */
@mixin screen($size, $dir: up, $sizes: $screen-sizes) {
  @if $dir == down {
    @media all and (max-width: #{map-get($sizes, $size)-1}) {
      @content;
    }
  } @else {
    @media all and (min-width: #{map-get($sizes, $size)}) {
      @content;
    }
  }
}

@mixin clearfix() {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/


@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin word-wrap {
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

@mixin break-all {
  overflow-wrap: break-word;
  word-break: break-all;
  word-wrap: break-word;
}

// Embossed border
//
// 'left' means white border (box shadow) is on the left

@mixin embossed-border($direction: left) {
  @if $direction == left {
    border-right: 1px solid rgba($color-black, .1);
    box-shadow: inset -1px 0 0 0 rgba($color-white, .1);
  } @else if $direction == right {
    border-left: 1px solid rgba($color-black, .1);
    box-shadow: -1px 0 0 0 rgba($color-white, .1);
  } @else if $direction == bottom {
    border-bottom: 1px solid rgba($color-black, .1);
    box-shadow: 0 1px 0 0 rgba($color-white, .05);
  }
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
/// @param {Length} $wide [1.5] - Triangle wideness
@mixin triangle($direction, $color: currentcolor, $size: 1em, $wide: 1.5) {
  @if not index(top right bottom left, $direction) {
    @error 'Direction must be either `top`, `right`, `bottom` or `left`.';
  }

  content: '';
  height: 0;
  width: 0;

  @if $direction == top {
    border-bottom: ($size * $wide) solid $color;
  } @else if $direction == bottom {
    border-top: ($size * $wide) solid $color;
  } @else if $direction == left {
    border-right: ($size * $wide) solid $color;
  } @else if $direction == right {
    border-left: ($size * $wide) solid $color;
  }

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

@mixin center-content($horizontal:true, $content:'&__content') {
  font-size: 0;

  @if $horizontal {
    text-align: center;
  }

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  #{$content} {
    display: inline-block;
    font-size: typo('body', 'size');
    vertical-align: middle;

    @if $horizontal {
      text-align: left;
    }
  }
}

@mixin flipperOn($flipper) {
  @if & {
    html.FLIPPER-#{$flipper} & {
      @content;
    }
  } @else {
    html.FLIPPER-#{$flipper} {
      @content;
    }
  }
}

@mixin flipperOff($flipper) {
  @if & {
    html:not(.FLIPPER-#{$flipper}) & {
      @content;
    }
  } @else {
    html:not(.FLIPPER-#{$flipper}) {
      @content;
    }
  }
}

@mixin flipperOnImportFile($flipper) {
  html.FLIPPER-#{$flipper} {
    @content;
  }
}

@mixin flipperOffImportFile($flipper) {
  html:not(.FLIPPER-#{$flipper}) {
    @content;
  }
}

@mixin aspect-ratio($width: 1, $height: null, $pseudo: 'all') {
  $padding: if(
    $height,
    percentage($height/$width),
    percentage(1/$width)
  );

  @if ($pseudo != 'all') {
    // stylelint-disable all
    &:#{$pseudo} {
      content: '';
      display: block;
      padding-bottom: $padding;
    }
  } @else {
    &:before {
      content: '';
      float: left;
      padding-bottom: $padding;
    }

    &:after {
      clear: both;
      content: '';
      display: table;
    }
  }
}
