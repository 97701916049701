$header-fixed-position: $fixed-topnav-height !default;
$header-color-boxed: #fff !default;

.e {
  &-header {
    position: relative;

    &-small {
      h1 {
        @include word-wrap;
        max-width: 70%;
        word-break: break-all;
      }
    }

    &-flex {
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      margin: 6px 0 14px;

      h1,
      h2 {
        flex: 1 1 100%;
        line-height: $text-lineheight-condensed;
        margin-bottom: 0;
        max-width: 100%;
      }

      .e-header {
        &__button {
          align-items: center;
          display: flex;
          flex-basis: auto;
          flex-grow: 0;
          flex-shrink: 0;
          margin-left: auto;
          margin-top: 0;
          position: static;
        }
      }
    }

    &-box {
      align-items: center;
      border-bottom: 1px solid palette('grays', 'separator');
      height: 63px;
      margin: (-$grid-gutter-width / 2) (-$grid-gutter-width / 2) $grid-gutter-width / 2;
      padding: $grid-gutter-width / 2;
    }

    &__title {
      line-height: $box-form-element-height;
    }

    &__parentlink {
      color: map-get($palette_navigation, 'background');
      cursor: pointer;
      line-height: $box-form-element-height;
      padding-right: 10px;

      .e-icon {
        fill: map-get($palette_navigation, 'background');
      }
    }

    &__back,
    &__button {
      padding-left: $grid-gutter-width / 2;
      position: absolute;
      right: 0;
      text-decoration: none;
      top: 50%;
    }

    &__back {
      margin-top: -10px;
    }

    &__button {
      margin-top: -$box-form-element-height / 2;
    }
  }
}
