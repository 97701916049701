$panel-box-height: 60px !default;

.e {
  &-panel {
    @include clearfix;
    position: relative;

    &-box {
      @include e-box;
      box-shadow: none;
      padding-bottom: $grid-gutter-width / 2;
    }

    &-col { // Trends popup
      border: 1px solid $box-color-border;
      border-right: 0;
      margin: -1px 0 0;
      padding: $grid-gutter-width / 2;

      &:first-child {
        border-left: 0;
      }
    }

    &-bordered { // Advanced scheduling
      border-right: 1px solid $box-color-border;
      padding: $grid-gutter-width / 2;

      &:last-child {
        border-right: 0;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      height: $panel-box-height;
      justify-content: center;
      margin-bottom: 2 * $grid-gutter-width + 2;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      @include text-overflow;
      font-size: typo('subheader', 'size');
      line-height: 1;
      margin-bottom: 5px;
      padding-right: $grid-gutter-width / 2;
      position: relative;

      &-multiline {
        overflow: visible;
        white-space: normal;
      }

      .e-btn-small {
        float: right;
        margin-left: 5px;
        margin-top: -5px;
      }
    }

    &__icon {
      float: left;
      margin-right: $grid-gutter-width / 2;
      position: relative;

      .e-icon {
        fill: $color-white;
        margin: 10px;
      }
    }

    &__value {
      font-size: typo('title', 'size');
      font-weight: typo('title', 'weight');
      line-height: 1;
      position: relative;
    }

    &__postfix {
      padding-left: 4px;
    }

    &__text {
      font-size: typo('small', 'size');
      margin-top: 5px;

      &-twolines {
        height: 35px;
        overflow: hidden;
      }
    }

    &__tooltip {
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: z('default');
    }
  }
}

.e-chart-si {
  .e-field {
    .e-panel__icon {
      margin-bottom: 0;
    }
  }
}
