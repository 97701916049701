$popover-box-size: map-deep-get($size, 'large', 'width') !default;
$popover-color-background: palette('secondary', 'light') !default;
$popover-color-background-dropdown: #fff !default;
$popover-color-text: #fff !default;
$popover-size-tooltip: 6px !default;

.e {
  &-popover {
    color: $popover-color-text;
    max-width: $popover-box-size;
    position: absolute;
    z-index: z('dropdown');

    &-dropdown {
      min-width: 250px;

      .e-popover {
        &__content {
          background: $popover-color-background-dropdown;
          color: $color-text;
        }
      }
    }

    &__arrow {
      position: absolute;

      &:after {
        position: absolute;
      }
    }

    &__close {
      background: url('close-white.svg');
      background-size: cover;
      border: 0;
      cursor: pointer;
      float: right;
      font-size: 0;
      height: 15px;
      margin: 5px 5px 0 0;
      width: 15px;
    }

    &__content {
      background: $popover-color-background;
      box-shadow: $box-box-shadow;
      line-height: $text-lineheight-condensed;
      padding: 5px 22px 5px 7px;
    }

    &__title {
      background: $popover-color-background;
      color: $popover-color-text;
      font-size: typo('subheader', 'size');
      font-weight: typo('subheader', 'weight');
      padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
    }

    &.left {
      padding-right: $popover-size-tooltip;

      .e-popover__arrow {
        margin-top: -$popover-size-tooltip;
        right: $popover-size-tooltip;
        top: 50%;

        &:after {
          @include triangle('right', $popover-color-background, $popover-size-tooltip, 1);
        }
      }
    }

    &.right {
      padding-left: $popover-size-tooltip;

      .e-popover__arrow {
        left: 0;
        margin-top: -$popover-size-tooltip;
        top: 50%;

        &:after {
          @include triangle('left', $popover-color-background, $popover-size-tooltip, 1);
        }
      }
    }

    &.top {
      padding-bottom: $popover-size-tooltip;

      .e-popover__arrow {
        bottom: $popover-size-tooltip;
        margin-top: -$popover-size-tooltip;

        &:after {
          @include triangle('bottom', $popover-color-background, $popover-size-tooltip, 1);
        }
      }
    }

    &.bottom {
      padding-top: $popover-size-tooltip;

      .e-popover__arrow {
        margin-left: -$popover-size-tooltip;
        top: 0;

        &:after {
          @include triangle('top', $popover-color-background, $popover-size-tooltip, 1);
        }
      }
    }

  }
}
