$inplaceeditor-color-border-focus: palette('secondary') !default;

.e {
  &-inplaceeditor {
    @include word-wrap;

    border: 0;
    display: inline-block;
    margin: -5px;
    outline-offset: -1px;
    padding: 5px;

    &:hover {
      outline: 1px solid rgba($inplaceeditor-color-border-focus, .5);
    }

    &:focus {
      background: $color-white;
      box-shadow: 0 0 4px rgba($inplaceeditor-color-border-focus, .5);
      outline: 1px solid $inplaceeditor-color-border-focus;
    }

    &-has_buttons {
      &:focus {
        margin-right: 120px;
      }
    }

    &-disabled {
      &:hover {
        outline-color: palette('grays', 'shade');
      }
    }

    &__buttons {
      display: inline-block;
      line-height: 1;
      margin: 0 10px;
      outline: 0;
      position: relative;
      top: -1px;
      vertical-align: middle;

      .e-btn {
        margin: 0 5px;
        vertical-align: top;
      }
    }
  }
}
