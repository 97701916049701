$verticalnav-box-size: 64px !default;
$verticalnav-box-size-condensed: 40px !default;
$verticalnav-color-separator: palette('grays', 'separator') !default;
$verticalnav-color-active: map-get($palette_navigation, 'background') !default;
$verticalnav-color-hover: palette('grays', 'inactive') !default;
$verticalnav-transition: $transition-time * 2 cubic-bezier(.25, .8, .25, 1) !default;

e-verticalnav-menu {
  background: $color-body;
  display: block;
  transition: width $verticalnav-transition;
  width: $verticalnav-box-size;
}

.e {
  &-verticalnav {
    display: flex;
    margin-bottom: $grid-gutter-width;

    &-fullheight {
      height: 100%;
      margin-bottom: 0;
    }

    &-condensed {
      .e-verticalnavitem {
        height: $verticalnav-box-size-condensed;
        line-height: $verticalnav-box-size-condensed;

        .e-icon {
          height: 20px;
          width: 20px;
        }

        &-active {
          &:after {
            height: $verticalnav-box-size-condensed - 2px;
          }
        }
      }
    }

    &__content {
      @include e-box;
      flex: 1 1 0%;
      margin-bottom: 0;
      min-width: 0; // Firefox overflow issue: http://stackoverflow.com/questions/26895349/how-can-i-get-ff-33-x-flexbox-behavior-in-ff-34-x/26916542#26916542
      position: relative;

      &-fullheight {
        height: 100%;
      }

      &-has_container {
        padding: 7px 0 0;
      }

      &-nopadding {
        padding: 0;

        .e-verticalnav__header {
          margin: 0 0 15px;
        }
      }

      &-scrollable {
        overflow: auto;
      }
    }

    &__toggle {
      align-items: center;
      display: flex;
      height: $verticalnav-box-size;
      justify-content: center;
      width: $verticalnav-box-size;

      &-active {
        .e-icon {
          transform: rotate(180deg);
        }
      }

      .e-icon {
        transition: transform $verticalnav-transition;
      }
    }

    &__header {
      align-items: center;
      border-bottom: 1px solid palette('grays', 'separator');
      display: flex;
      height: 63px;
      margin: -($grid-gutter-width / 2);
      margin-bottom: $grid-gutter-width / 2;
      padding: 0 $grid-gutter-width / 2;

      &-no_border {
        border-bottom: none;
        margin-bottom: -1px;
      }
    }

    &__body {
      height: calc(100% - 63px);
      margin-top: -15px;
      overflow: auto;
      padding: 15px;
    }

    &__title {
      font-family: typo('title', 'family');
      font-size: typo('title', 'size');
      font-weight: typo('title', 'weight');
      line-height: $text-lineheight-condensed;
    }

    &__actions {
      flex-basis: auto;
      flex-shrink: 0;
      margin-left: auto;
    }

    &__extended {
      e-verticalnav-item {
        display: block;
      }

      .e-verticalnavitem {
        &:before {
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
      }

      .e-verticalnavitem-active .e-verticalnavitem,
      .e-verticalnavitem.e-verticalnavitem-active {
        &:after {
          left: calc(100% - 3px);
        }
      }
    }
  }

  &-verticalnavitem {
    border-bottom: 1px solid $verticalnav-color-separator;
    border-top: 1px solid transparent;
    font-size: 0;
    height: $verticalnav-box-size;
    line-height: $verticalnav-box-size;
    margin-bottom: -1px;
    padding-right: $grid-gutter-width / 2;
    position: relative;
    white-space: nowrap;

    &:hover {
      background: $verticalnav-color-hover;
    }

    &-faded {
      .e-icon,
      .e-verticalnavitem__value {
        opacity: .5;
      }
    }

    &-disabled {
      cursor: not-allowed;

      .e-verticalnavitem__item,
      .e-verticalnavitem__icon {
        opacity: .3;
      }

      .e-svgclickfix {
        cursor: not-allowed;
      }

      &:hover {
        background: transparent;
      }
    }

    &__icon {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      width: $verticalnav-box-size;
    }

    &__value {
      display: inline-block;
      font-size: typo('body', 'size');
      vertical-align: middle;
    }
  }
}

e-verticalnav-item {
  display: inline-block;
  position: relative;

  &:focus {
    outline: 0;
  }

  &.e-verticalnavitem-active .e-verticalnavitem,
  .e-verticalnavitem-active {
    background: $color-white;
    border-top-color: $verticalnav-color-separator;
    box-shadow: inset 3px 0 0 $verticalnav-color-active;
    color: $verticalnav-color-active;

    &:hover {
      background: $color-white;
    }

    &:after {
      background: $color-white;
      content: '';
      height: $verticalnav-box-size - 2px;
      left: $verticalnav-box-size - 3px;
      position: absolute;
      top: 0;
      width: 4px;
      z-index: 1;
    }

    .e-icon {
      fill: $verticalnav-color-active;
    }
  }
}
