$noc-color-background-badge: #ca005d;

#notification-center {
  .overlay.ng-hide-add,
  .overlay.ng-hide-add-active,
  .overlay.ng-hide-remove,
  .overlay.ng-hide-remove-active {
    transition: all cubic-bezier(.25, .46, .45, .94) 100ms;
  }

  .overlay.ng-hide {
    opacity: 0;
  }

  .notifications {
    .notification {
      overflow: hidden;

      &.ng-enter,
      &.ng-leave {
        overflow: hidden;
        transition: all cubic-bezier(.25, .46, .45, .94) 200ms;
      }

      &.ng-enter,
      &.ng-leave-active {
        max-height: 0;
        padding-bottom: 0;
        padding-top: 0;
      }

      &.ng-leave,
      &.ng-enter-active {
        max-height: 200px;
        padding-bottom: 8px;
        padding-top: 8px;
      }
    }
  }

  .nc-alert-container {
    .nc-alert.nc-highlight {
      transform: scale(1.07);
    }

    .nc-alert.ng-hide-add.ng-hide-add-active,
    .nc-alert.ng-hide-remove.ng-hide-remove-active {
      transition: all 150ms cubic-bezier(.25, .46, .45, .94);
    }

    .nc-alert.ng-hide {
      opacity: 0;
      transform: translateX(50px);
    }

    .nc-alert.ng-hide-add.ng-hide-add-active {
      transform: translateX(0);
    }
  }
}

#notification-center-badge {
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  text-align: center;
  transform: scale(1);
  transform-origin: 50% 35%;
  transition: transform .1s ease-out;
  width: 100%;

  &.e-noc-shame {
    width: 32px; // fix bade width on dashbordpage - old ui
  }

  > .e-icon,
  e-icon {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity $transition-time-long ease-out;
  }

  &.active {
    > .e-icon,
    e-icon {
      opacity: 1;
    }

    .e-spinner {
      opacity: 0;
    }

    &:hover {
      transform: rotateZ(10deg);
    }
  }

  &.ring {
    animation: shake 1300ms;
  }

  .e-spinner {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity $transition-time-long ease-out;
  }

  .e-circles__circle:before {
    background-color: map-get($palette_navigation, 'text');
  }
}

#notification-center-badge-status {
  background-color: $noc-color-background-badge;
  border-radius: 3px;
  color: $color-white;
  display: block;
  font-size: 10px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 13px;
  line-height: 13px;
  opacity: 0;
  padding: 0 4px;
  position: absolute;
  right: 15px;
  top: 15px;
  transform: scale(0);
  transition: all $transition-time ease-out;

  &.active {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: rotateZ(0deg);
  }

  10% {
    transform: rotateZ(-30deg);
  }

  20% {
    transform: rotateZ(15deg);
  }

  30% {
    transform: rotateZ(-10deg);
  }

  40% {
    transform: rotateZ(7.5deg);
  }

  50% {
    transform: rotateZ(-6deg);
  }

  60% {
    transform: rotateZ(5deg);
  }

  70% {
    transform: rotateZ(-4.28571deg);
  }

  80% {
    transform: rotateZ(3.75deg);
  }

  90% {
    transform: rotateZ(-3.33333deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}
