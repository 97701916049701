$separator-gutter: 20px !default;
$separator-gutter-fullwidth: $grid-gutter-width !default;
$separator-color: palette('grays', 'separator') !default;

.e {
  &-separator {
    background: $separator-color;
    border: 0;
    height: 1px;
    margin: 0  0 $separator-gutter;

    &-fullwidth {
      margin: 0 (-$grid-gutter-width / 2) $separator-gutter-fullwidth;
      width: auto;
    }

    &-small {
      margin-bottom: $grid-gutter-width / 2;
    }

    &-condensed {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
