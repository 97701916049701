// stylelint-disable declaration-no-important

.e {
  &-selectbox {
    background: $color-white;
    border: solid 1px $select-color-border;
    border-radius: 0;
    display: inline-block;
    margin-top: 5px;
    outline: none;
    overflow: auto;
    overflow-x: hidden;
    text-decoration: none;
    width: 100%;

    .selectBox-options {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        @include clearfix;
        color: palette('grays', 'text');
        cursor: pointer;
        display: block;
        font-size: typo('body', 'size') !important;
        padding: 5px 10px;
        text-decoration: none;
        transition: none;

        b {
          display: inline-block;
          width: 30%;

        }

        em {
          display: inline-block;
          font-style: normal;
          width: 70%;
        }
      }

      li {
        border-bottom: 1px solid $select-color-border;

        &.selectBox-selected {
          a {
            background: palette('secondary');
            color: $color-white;
          }
        }

        &.selectBox-hover {
          a {
            background: lighten(palette('secondary'), 5);
            color: $color-white;
          }
        }
      }
    }
  }
}
