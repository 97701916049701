.e-tokeninput {
  display: block;
  position: relative;

  &:before {
    color: $input-text-color-placeholder;
    content: attr(fake-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 7px;
  }

  &__editable,
  &__content {
    @include text-overflow;
    outline: none;
    padding-top: 6px;
    text-overflow: clip;
    user-select: text;
    word-wrap: break-word;

    &-inline {
      display: inline;

      &:empty {
        display: block;
      }
    }

    &-multiline {
      display: block;
      white-space: normal;
    }
  }

  &__editable {
    &-multiline {
      opacity: 0;
    }

    &.e-input-textarea {
      display: block;
      overflow: auto;
      white-space: normal;
    }
  }

  &__content {
    display: none;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    &-multiline {
      display: block;
      opacity: 1;
    }
  }

  &__token {
    background: palette('primary');
    border-radius: .25em;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 0 10px;

    &-active {
      background: palette('success');

      &[type='personalization'] {
        background-color: lighten(palette('secondary'), 5);
      }
    }

    &[type='personalization'] {
      background-color: palette('secondary');
    }
  }
}
