$grid-color-background-separator: #fff !default;

@mixin make-grid-columns($class: 'default', $i: 1) {
  @if ($class == 'default') {
    $class: '';
  } @else {
    $class: $class + '-';
  }

  $list: '.e-col-#{$class}1';

  @for $i from (1 + 1) through $grid-columns {
    $list: '#{$list}, .e-col-#{$class}#{$i}';
  }

  #{$list} {
    float: left;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}

@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .e-col-#{$class}#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }

  @if ($type == push) and ($index > 0) {
    .e-col-#{$class}push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }

  @if ($type == push) and ($index == 0) {
    .e-col-#{$class}push-0 {
      left: auto;
    }
  }

  @if ($type == pull) and ($index > 0) {
    .e-col-#{$class}pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }

  @if ($type == pull) and ($index == 0) {
    .e-col-#{$class}pull-0 {
      right: auto;
    }
  }

  @if ($type == offset) {
    .e-col-#{$class}offset-#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
}

@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}

@mixin make-grid($class: 'default') {
  @if ($class == 'default') {
    $class: '';
  } @else {
    $class: $class + '-';
  }

  @include loop-grid-columns($grid-columns, $class, width);
}

@mixin container-fixed($gutter: $grid-gutter-width) {
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin make-row($gutter: $grid-gutter-width) {
  @include clearfix;
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
}

@mixin make-column($columns, $gutter: $grid-gutter-width) {
  float: left;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  position: relative;
  width: percentage(($columns / $grid-columns));
}

// Class generation
.e-container {
  @include container-fixed;
  max-width: map-get($screen-sizes, 'large');
  min-width: map-get($screen-sizes, 'small');
  padding-top: 8px;

  &-fullwidth {
    max-width: none;
  }

  &-flexible {
    min-width: 0;

    @include screen('x-small', 'down') {
      padding: 0;

      > .e-row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &-withsteps {
    padding-bottom: $steps-box-size;

    .e-box {
      margin-bottom: $grid-gutter-width / 2;
    }
  }
}

.e-row {
  @include make-row;
  position: relative;

  &__height {
    display: table;
    height: 100%;
    table-layout: fixed;
    width: 100%;
  }

  &-condensed {
    .e-col-6:first-child {
      padding-right: $grid-gutter-width / 6;
    }

    .e-col-6:last-child {
      padding-left: $grid-gutter-width / 6;
    }

    .e-col-6:not(:first-child, :last-child) {
      padding-left: $grid-gutter-width / 6;
      padding-right: $grid-gutter-width / 6;
    }
  }
}

@include make-grid-columns();
@include make-grid();

@each $screen-name, $size in $screen-sizes {
  @media all and (min-width: $size) {
    @include make-grid-columns($screen-name);
    @include make-grid($screen-name);
  }
}

.e-col {
  &-height {
    display: table-cell;
    float: none;
    height: 100%;
    vertical-align: middle;

    &-bordered {
      border-right: 1px solid palette('grays', 'separator');
      box-shadow: 1px 0 0 0 rgba($color-white, .8);
    }

    &-relative {
      position: relative;
    }
  }

  &-top {
    vertical-align: top;
  }

  &-bottom {
    vertical-align: bottom;
  }

  &-nopadding {
    padding: 0;
  }

  &-condensed {
    padding-left: ($grid-gutter-width / 6);
    padding-right: ($grid-gutter-width / 6);

    &:first-child {
      padding-left: ($grid-gutter-width / 2);
    }

    &:last-child {
      padding-right: ($grid-gutter-width / 2);
    }
  }

  &-even {
    padding-left: ($grid-gutter-width / 4);
    padding-right: ($grid-gutter-width / 4);

    &:first-child {
      padding-left: ($grid-gutter-width / 2);
    }

    &:last-child {
      padding-right: ($grid-gutter-width / 2);
    }
  }

  &__separator {
    background: palette('grays', 'separator');
    height: 100%;
    left: 50%;
    position: absolute;
    width: 1px;

    &:after {
      background: $grid-color-background-separator;
      bottom: 0;
      content: '';
      height: $grid-gutter-width / 2;
      position: absolute;
      width: 1px;
    }

    &-fullheight {
      &:after {
        height: auto;
      }
    }

    &-4 {
      left: percentage((4 / $grid-columns));
    }

    &-8 {
      left: percentage((8 / $grid-columns));
    }

    &-3 {
      left: percentage((3 / $grid-columns));
    }

    &-9 {
      left: percentage((9 / $grid-columns));
    }
  }
}

a.e-col-height {
  &:hover {
    background: $color-hover;
    box-shadow: 0 0 6px 0 rgba($color-black, .15);
  }
}
