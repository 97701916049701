.e {
  &-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$grid-gutter-width / 2) (-$grid-gutter-width) (-$grid-gutter-width / 2);

    &__item {
      flex: 0 0 auto;
      margin: 0 ($grid-gutter-width / 2) $grid-gutter-width ($grid-gutter-width / 2);

      &-auto {
        flex: 1 1 0%;
      }

      &-center {
        align-self: center;
      }
    }

    &__separator {
      border-right: 1px solid palette('grays', 'separator');
      flex: 0 0 0;
      margin-right: -1px;
    }

    //GRID SIZES
    //.e-flex__item {
    //  @for $i from 1 through 12 {
    //    &-#{$i} {
    //      width: calc(#{percentage(($i / 12))} - 30px);
    //    }
    //  }
    //}

    &-around,
    &-between {
      margin: 0;

      .e-flex__item {
        margin: 0;
      }
    }

    &-widgets {
      margin: 0 -10px -20px;

      //GRID SIZES
      .e-flex__item {
        margin: 0 10px 20px;

        @for $i from 1 through 12 {
          &-#{$i} {
            width: calc(#{percentage(($i / 12))} - 20px);
          }
        }
      }
    }

    &-condensed {
      margin: 0 (-$grid-gutter-width / 6) (-$grid-gutter-width / 3) (-$grid-gutter-width / 6);

      //GRID SIZES
      //.e-flex__item {
      //  @for $i from 1 through 12 {
      //    &-#{$i} {
      //      width: calc(#{percentage(($i / 12))} - 10px);
      //    }
      //  }
      //}

      &.e-flex__item {
        margin: 0 (($grid-gutter-width / 2) - ($grid-gutter-width / 6)) 20px (($grid-gutter-width / 2) - ($grid-gutter-width / 6));
      }

      .e-flex__item {
        margin: 0 ($grid-gutter-width / 6) ($grid-gutter-width / 3) ($grid-gutter-width / 6);

        &.e-flex-sticky {
          margin: 0 ($grid-gutter-width / 6) + 1 ($grid-gutter-width / 3) + 1 ($grid-gutter-width / 6) + 1;
        }
      }
    }

    &-sticky {
      margin: 0 0 1px 1px;

      //GRID SIZES
      .e-flex__item {
        margin: 0 0 -1px -1px;

        @for $i from 1 through 12 {
          &-#{$i} {
            width: calc(#{percentage(($i / 12))} + 1px);
          }
        }
      }

      .e-flex__separator {
        margin: 0 -1px -1px 0;
      }

      &.e-flex__item {
        margin: 0 ($grid-gutter-width / 2) + 1 ($grid-gutter-width / 2) + 1;
      }
    }

    &-around {
      justify-content: space-around;
    }

    &-between {
      justify-content: space-between;
    }

    &-center {
      justify-content: center;
    }

    &-vertical_center {
      align-items: center;
    }
  }
}
