// Used: Ac, Combined Segments,value control
// maybe: Segment, Email campaigns recurring schedule (in not remove old css - Recipient source: Generated through an event )

.e {
  &-pnotify {
    left: 0;
    position: fixed;
    right: 0;
    top: 61px;

    .ui-pnotify-container {
      width: 600px;

      .ui-pnotify-title {
        @include clearfix;
        margin: 0 0 5px;
      }

      // scss-lint:disable SelectorDepth
      .ui-pnotify-icon {
        float: left;
        margin-top: 2px;
        padding-right: 5px;

        .icon-info-sign,
        .icon-warning-sign {
          display: inline-block;
          height: 16px;
          width: 16px;
        }

        .icon-info-sign {
          background: url('info-circle-info.svg');
        }

        .icon-warning-sign {
          background: url('exclamation-circle-danger.svg');
        }
      }

      .ui-pnotify-closer {
        position: absolute;
        right: 20px;
        top: 15px;

        .icon-remove {
          background: url('close.svg');
          display: inline-block;
          height: 16px;
          width: 16px;
        }
      }

      .ui-pnotify-sticker {
        position: absolute;
        right: 40px;
        top: 15px;

        .icon-pause,
        .icon-play,
        .icon-remove {
          display: inline-block;
          height: 14px;
          margin-top: 3px;
          width: 14px;
        }

        .icon-pause {
          background: url('pause.svg');
        }

        .icon-play {
          background: url('play.svg');
        }
      }

      &.alert {
        @include e-alert;
        @include e-alert-modifier('info');
        border-radius: 0;
        margin: 7px auto;
        padding-top: 15px;
      }

      &.alert-error {
        @include e-alert-modifier('danger');
      }

      &.ui-pnotify-shadow {
        box-shadow: none;
      }
    }

    &-bottomright {
      left: auto;
      top: auto;

      .ui-pnotify-container {
        width: 265px;
      }
    }
  }
}
