// stylelint-disable
.e {
  &-hidden {
    display: none !important;
  }

  &-boxpadding {
    padding: $grid-gutter-width / 2;
  }

  &-gridcol {
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);

    &-condensed {
      padding-left: $grid-gutter-width / 6;
      padding-right: $grid-gutter-width / 6;
    }

    &-separated {
      border-right: 1px solid palette('grays', 'separator');
    }

    &-first {
      padding-left: 0;
    }

    &-last {
      border-right: 0;
      padding-right: 0;
    }
  }

  &-nomargin {
    margin-left: 0 !important;
    margin-right: 0 !important;

    &-bottom {
      margin-bottom: 0 !important;
    }
  }

  &-scrollable {
    height: 100%;
    position: relative;
    width: 100%;

    &__content {
      display: block;
      height: 100%;
      overflow: auto;
      position: absolute;
      width: 100%;
    }
  }

  &-iframecontainer {
    font-size: 0;

    > iframe {
      vertical-align: top;
    }
  }

  &-clickable {
    cursor: pointer !important;
  }

  &-grabbable {
    cursor: move !important;
    cursor: grab !important;

    &:active {
      cursor: grabbing !important;
    }
  }

  &-svgclickfix {
    cursor: pointer;
    position: relative;

    > svg {
      pointer-events: none;
    }

    &:after {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &-stretch {
    height: 100%;
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    position: relative;

    &-small {
      margin-left: -$grid-gutter-width / 4;
      margin-right: -$grid-gutter-width / 4;
    }

    .e-col-nopadding & {
      margin: 0;
    }

    &-top {
      margin-top: -$grid-gutter-width / 2;
    }

    &-bottom {
      margin-bottom: -$grid-gutter-width - 10;

      .e-box-chart & {
        margin-bottom: -$grid-gutter-width + 5;
      }
    }
  }

  &-notransition {
    transition: none !important;
  }

  &-test {
    &-notransition * {
      transition: none !important;
    }

    &-noopacity * {
      opacity: 1 !important;
    }
  }

  &-zebrastripe {
    > *:nth-child(odd) {
      background-color: palette('grays', 'zebra');
    }
  }

  &-screenheight {
    bottom: 0;
    height: calc(100% - #{$fixed-topnav-height});
    left: 0;
    position: absolute;
    right: 0;
    top: $fixed-topnav-height;
    width: 100%;

    &-has_steps {
      bottom: $steps_box_size;
      height: calc(100% - #{$fixed-topnav-height} - #{$steps_box_size});
    }
  }

  &-popperfix {
    top: -99999px;

    &-flying {
      z-index: z('flying');
    }
  }
}

.text-align {
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}

.text-lineheight {
  line-height: $text-lineheight-condensed;
}

.text-underline {
  text-decoration: underline !important;
}

.clearfix {
  @include clearfix;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.text-color {
  color: palette('default');

  @each $class, $data in $palettes {
    @if $class != 'default' {
      @if $class != 'grays' {
        &-#{$class} {
          color: palette($class) !important;
          fill: palette($class) !important;
        }
      }
    }
  }

  &-shade {
    color: map-get(map-get($palettes, 'grays'), 'shade') !important;
    fill: map-get(map-get($palettes, 'grays'), 'shade') !important;
  }

  &-white {
    color: map-get(map-get($palettes, 'grays'), 'empty') !important;
    fill: map-get(map-get($palettes, 'grays'), 'empty') !important;
  }

  &-inherit {
    color: currentColor !important;
    fill: currentColor !important;
  }

  &-title {
    color: map-get($palette_navigation, 'background') !important;
    fill: map-get($palette_navigation, 'background') !important;
  }

  @each $class, $data in $palette_reporting {
    &-#{$class} {
      color: #{$data} !important;
      fill: #{$data} !important;
    }
  }

  @each $class, $data in $palette_socialads {
    &-#{$class} {
      color: #{$data} !important;
      fill: #{$data} !important;
    }
  }

  @each $class, $data in $palette_smartinsight {
    &-#{$class} {
      color: #{$data} !important;
      fill: #{$data} !important;
    }
  }

  &-disabled {
    opacity: .5 !important;
  }
}

.background-color {
  @each $class, $data in $palettes {
    @if $class != 'default' {
      @if $class != 'grays' {
        &-#{$class} {
          background-color: palette($class) !important;
        }
      }
    }
  }

  @each $class, $data in map-get($palettes, 'grays') {
    &-#{$class} {
      background-color: #{$data} !important;
    }
  }

  @each $class, $data in $palette_reporting {
    &-#{$class} {
      background-color: #{$data} !important;
    }
  }

  @each $class, $data in $palette_socialads {
    &-#{$class} {
      background-color: #{$data} !important;
    }
  }

  @each $class, $data in $palette_smartinsight {
    &-#{$class} {
      background-color: #{$data} !important;
    }
  }
}

.button-height {
  line-height: $btn-box-height - 2 !important;

  &-borderless {
    line-height: $btn-box-height !important;
  }

  &-small {
    line-height: $btn-box-height-small !important;
  }
}

.text-size {
  @each $class, $data in $typo {
    &-#{$class} {
      font-size: typo($class, 'size') !important;
      font-weight: typo($class, 'weight') !important;
    }
  }
}

.text-overflow {
  @include text-overflow();
  display: inline-block;
  max-width: 100%;
  vertical-align: text-bottom;
  width: auto;
}

.word-wrap {
  @include word-wrap;
}

.break-all {
  @include break-all;
}

.dark-link {
  color: palette('grays', 'text') !important;
  text-decoration: none;

  svg {
    fill: palette('grays', 'text') !important;
  }

  &:hover,
  &:active {
    color: darken(palette('secondary'), $hover-modifier-amount) !important;

    svg {
      fill: darken(palette('secondary'), $hover-modifier-amount) !important;
    }
  }
}

.light-link {
  color: $color-white !important;

  svg {
    fill: $color-white !important;
  }

  &:hover,
  &:active {
    color: darken($color-white, 10) !important;

    svg {
      fill: darken($color-white, 10) !important;
    }
  }
}

.font-weight-thin {
  font-weight: $text-thin-weight !important;
}

.font-weight-normal {
  font-weight: $text-normal-weight !important;
}

.font-weight-bold {
  font-weight: $text-bold-weight !important;
}

.noselect {
  user-select: none !important;
}
// stylelint-enable
