// scss-lint:disable SelectorFormat,IdSelector,ImportantRule,ColorVariable,PlaceholderInExtend,NameFormat,SelectorDepth

.ui-datepicker {
  @include e-datepicker;
  background: #fff;
  box-shadow: 0 0 5px rgba(#000, .1);
  display: none;
  margin-top: 4px;
  padding-bottom: 0;

  &,
  & *,
  & *:before,
  & *:after {
    box-sizing: border-box;
  }

  a {
    color: palette('grays', 'text');
    font-size: typo('body', 'size');
    text-decoration: none;
  }

  .ui-datepicker-title {
    @include e-datepicker-title;
  }

  .ui-datepicker-prev {
    @include e-datepicker-action('prev');
    font-size: 0;
    top: 7px;
    width: 20px;

    &:after {
      content: url('caret-left.svg');
    }
  }

  .ui-datepicker-next {
    @include e-datepicker-action('next');
    font-size: 0;
    top: 7px;
    width: 20px;

    &:after {
      content: url('caret-right.svg');
    }
  }

  &-calendar {
    // scss-lint:disable QualifyingElement
    a.ui-state-default {
      @include e-datepicker-day;
      display: inline-block;
      padding: 0;
      text-align: center;

      &.ui-state-active {
        @include e-datepicker-day-modifier('current');
      }
    }
  }

  .timepicker {
    &-title {
      @extend .e-field__label;
      display: block;
    }
  }

  .ui-timepicker {
    &-div {
      padding: 0 15px;
    }

    &-title {
      @extend .e-field__label;
      @extend .e-field__label-inline;
    }

    &-select-input {
      @include e-input;
      @include e-input-size('small');
    }

  }

  &-buttonpane {
    @include clearfix;
    padding-bottom: 15px;

    // scss-lint:disable QualifyingElement
    button.ui-state-default {
      @include e-btn;
      float: left;
      margin-left: 15px;

      &.ui-priority-primary {
        @include e-btn-modifier('primary');
        float: right;
        margin-right: 15px;
      }
    }
  }
}
