$iconmenu-color: map-get($palette_navigation, 'text') !default;
$iconmenu-color-background: map-get($palette_navigation, 'background-hover');
$iconmenu-box-size: $fixed-topnav-height !default;
$iconmenu-size-icon: 20px !default;

.e {
  &-iconmenu {
    float: right;
    font-size: 0;

    &__item {
      display: inline-block;
      font-size: 0;
      margin-left: 1px;
      vertical-align: middle;

      &:hover {
        .e-iconmenu__name {
          background: $menu-color-background-active;
        }
      }

      .e-dropdown__inner {
        margin-top: 0;
      }
    }

    &__name {
      align-items: center;
      background: $iconmenu-color-background;
      cursor: pointer;
      display: flex;
      height: $iconmenu-box-size;
      justify-content: center;
      position: relative;
      transition: $menu-hover-transition;
      width: $iconmenu-box-size;

      &:hover {
        transition: none;
      }

      + .e-dropdown__inner {
        &:after {
          right: 24px;
        }
      }

      .e-icon {
        fill: $iconmenu-color;
        height: $iconmenu-size-icon;
        width: $iconmenu-size-icon;
      }
    }

    &__circle {
      background: $menu-color-background;
      border-radius: 100%;
      display: inline-block;
      line-height: 1;
      overflow: hidden;
      vertical-align: middle;

      .e-icon {
        height: 25px;
        width: 25px;
      }
    }

    &__profile {
      &__header {
        border-bottom: 1px solid palette('grays', 'separator');
        padding: $grid-gutter-width / 2;

        a {
          color: inherit;
        }
      }

      .e-icon {
        fill: $icon-color;
      }

      &__time {
        color: palette('grays', 'shade');
        font-size: typo('small', 'size');
        white-space: nowrap;
      }
    }
  }

  &-search {
    font-size: typo('body', 'size');
    padding: $grid-gutter-width / 2;
    width: 220px;
  }
}
