/*
1: remove non-modifier version
*/

$spinner-color-default: map-get($palette_navigation, 'background') !default;
$spinner-animation-time: .8s !default;
$spinner-circles: 12 !default;

.e {
  &-spinner {
    display: inline-block;
    vertical-align: middle;

    &__text {
      line-height: 1;
      margin-left: 8px;
      vertical-align: -9%;
    }

    &-fullscreen {
      display: inline-block;
      left: 50%;
      margin: auto;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-circles {
    display: inline-block;
    height: 52px;
    position: relative;
    vertical-align: middle;
    width: 52px;

    &-small {
      height: 18px;
      width: 18px;

      .e-circles__circle {
        &:before {
          height: 4px;
          width: 1px;
        }
      }
    }

    &-large {
      height: 155px;
      width: 155px;

      .e-circles__circle {
        &:before {
          height: 24%;
          width: 7%;
        }
      }
    }

    &-table {
      height: 18px;
      margin: -3px 3px 0;
      width: 18px;

      .e-circles__circle {
        &:before {
          height: 4px;
          width: 1px;
        }
      }
    }

    &__circle {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &:before {
        animation: spinner $spinner-animation-time infinite linear;
        background-color: $spinner-color-default;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        content: '';
        display: block;
        height: 12px;
        margin: 0 auto;
        opacity: .1;
        outline: 1px solid transparent;
        width: 4px;
      }

      &-active {
        &:before {
          opacity: 1;
        }
      }
    }

    $j: ($spinner-animation-time / $spinner-circles);

    @for $i from 1 to $spinner-circles + 1 {
      /* 1 */
      &__circle#{$i},
      &__circle-#{$i} {
        transform: rotate((360deg / $spinner-circles) * ($i - 1));

        &:before {
          animation-delay: $j;
          $j: $j + ($spinner-animation-time / $spinner-circles);
        }
      }
    }
  }
}

@keyframes spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: .1;
  }
}
