.e {
  &-legend {
    line-height: $text-lineheight-condensed;

    .e-icon {
      height: 16px;
      margin: -4px 5px 0 0;
      width: 16px;
    }

    &__title {
      & + .e-legend__value {
        margin-top: 1px;
      }
    }

    &__value {
      font-size: typo('title', 'size');
      line-height: 1;

      small {
        color: palette('grays', 'shade');
        font-size: typo('small', 'size');
      }

      & + .e-legend__description {
        margin-top: 1px;
      }
    }

    &__description {
      color: palette('grays', 'shade');
      font-size: typo('small', 'size');
    }

    &-large {
      .e-legend__title {
        font-size: typo('subheader', 'size');

        .e-icon {
          height: 16px;
          margin: -4px 8px 0 0;
          width: 16px;
        }
      }

      .e-legend__value {
        font-size: typo('headline', 'size');

        small {
          font-size: typo('body', 'size');
        }

        & + .e-legend__description {
          margin-top: 3px;
        }
      }
    }

    & + .e-legend {
      margin-top: 9px;
    }
  }
}
