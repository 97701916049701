$footer-box-height: $fixed-footer-height !default;
$footer-color-text: rgba(palette('grays', 'empty'), .35) !default;

.e {
  &-footer {
    @include container-fixed;
    @include center-content(false);
    border-top: 1px solid rgba($color-white, .15);
    box-shadow: 0 -1px 0 0 rgba($color-black, .25);
    color: $footer-color-text;
    height: $footer-box-height;
    max-width: map-get($screen-sizes, 'large');
    min-width: map-get($screen-sizes, 'small');

    &__content {
      font-size: typo('small', 'size');
    }
  }
}
