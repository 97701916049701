$datepicker-box-icon: 16px !default;
$datepicker-box-size: 245px !default;
$datepicker-box-size-weeks: 264px !default;
$datepicker-table-size: 217px !default;
$datepicker-button-box-size: $box-form-element-height !default;
$datepicker-color-header-background: palette('grays', 'separator') !default;
$datepicker-color-cell-separator: palette('grays', 'separator') !default;
$datepicker-color-alternate: palette('default', 'x-light') !default;
$datepicker-color-current: #fff !default;
$datepicker-color-background-current: palette('secondary', 'light') !default;
$datepicker-color-cell-hover-background: palette('grays', 'inactive') !default;
$datepicker-hover-transition: background $transition-time ease-in-out !default;

e-calendar {
  &:focus {
    outline: none;
  }
}

@mixin e-datepicker {
  background: $datepicker-color-current;
  border: 1px solid $datepicker-color-cell-separator;
  margin: 0 auto $grid-gutter-width / 2;
  position: relative;
  text-align: center;
  user-select: none;
  width: $datepicker-box-size;

  table {
    font-size: typo('body', 'size');
    margin: auto;
    margin-bottom: $grid-gutter-width / 4;
    table-layout: fixed;
    text-align: center;
    width: 100%;
  }

  thead {
    th {
      font-weight: $text-bold-weight;
      height: $datepicker-box-size / 7;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }
}

@mixin e-datepicker-action($dir: false) {
  cursor: pointer;
  position: absolute;
  top: 5px;

  @if ($dir == 'prev') {
    left: 5px;
  }

  @if ($dir == 'next') {
    right: 5px;
  }
}

@mixin e-datepicker-title {
  border-bottom: 1px solid $datepicker-color-cell-separator;
  display: block;
  height: $datepicker-button-box-size;
  line-height: $datepicker-button-box-size;
  padding: 0 $datepicker-button-box-size;
  text-align: center;
  width: $datepicker-box-size;
}

@mixin e-datepicker-day {
  cursor: pointer;
  height: $datepicker-table-size / 7;
  line-height: $datepicker-table-size / 7;
  transition: $datepicker-hover-transition;
  width: $datepicker-table-size / 7;

  &:hover {
    background: $datepicker-color-cell-hover-background;
  }
}

@mixin e-datepicker-day-modifier($modifier: 'default') {
  @if ($modifier == 'current') {
    background: $datepicker-color-background-current;
    color: $datepicker-color-current;

    &:hover {
      background: darken($datepicker-color-background-current, 5);
    }
  }

  @if ($modifier == 'alternate') {
    color: $datepicker-color-alternate;
  }
}

.e {
  &-datepicker {
    @include e-datepicker;

    &-borderless {
      border: 0;
      text-align: left;
    }

    &__prev {
      @include e-datepicker-action('prev');
    }

    &__next {
      @include e-datepicker-action('next');
    }

    &__day {
      @include e-datepicker-day;

      &-alternate {
        @include e-datepicker-day-modifier('alternate');
      }

      &-current {
        @include e-datepicker-day-modifier('current');
      }
    }

    &__title {
      @include e-datepicker-title;
    }

    &-weeks {
      overflow: hidden;
      width: $datepicker-box-size-weeks;

      .e-datepicker {
        &__day {
          height: $datepicker-box-size-weeks / 8;
          width: $datepicker-box-size-weeks / 8;
        }

        &__title {
          width: $datepicker-box-size-weeks;
        }
      }
    }
  }
}

.e-datepicker-new {
  cursor: pointer;
  line-height: $input-box-height - 2;
  outline: 0;
  position: relative;
  user-select: none;

  .e-icon {
    height: $datepicker-box-icon;
    margin-right: 8px;
    margin-top: -3px;
    width: $datepicker-box-icon;
  }

  &.e-input-disabled .e-datepicker-new__clear {
    display: none;
  }

  &-active {
    &:after {
      background: $color-white;
      bottom: -1px;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: z('flying') + 1;
    }
  }

  &:read-only:focus {
    @include e-input-modifier('focus');
  }

  &-inline {
    min-width: 200px;
    width: auto;
  }

  &__close {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__clear {
    @include search-input-cancel;
    border: 0;
    position: absolute;
    right: 11px;
    top: 9px;
  }
}
