$grid-box-gutter-xsmall: 5px;
$grid-box-gutter-small: $grid-gutter-width / 4;
$grid-box-gutter-medium: 10px;
$grid-box-gutter-large: $grid-gutter-width / 2;

@mixin e-grid($padClass: false, $gutter: 0) {
  &-#{$padClass} {
    margin: -$gutter;
  }
}

@mixin e-cell($padClass: false, $gutter: 0) {
  &-#{$padClass} {
    padding: $gutter;
  }
}

.e {
  &-grid {
    display: flex;

    @include e-grid('xsmall', $grid-box-gutter-xsmall);
    @include e-grid('small', $grid-box-gutter-small);
    @include e-grid('medium', $grid-box-gutter-medium);
    @include e-grid('large', $grid-box-gutter-large);

    &-around {
      justify-content: space-around;
    }

    &-between {
      justify-content: space-between;
    }

    &-center {
      justify-content: center;
    }

    &-vertical_center {
      align-items: center;
    }

    &-wrap {
      flex-wrap: wrap;
    }

    &-column {
      flex-direction: column;
    }
  }

  &-cell {
    flex: 0 0 auto;

    @for $i from 1 through 12 {
      &-#{$i} {
        width: #{percentage($i / 12)};
      }
    }

    @include e-cell('xsmall', $grid-box-gutter-xsmall);
    @include e-cell('small', $grid-box-gutter-small);
    @include e-cell('medium', $grid-box-gutter-medium);
    @include e-cell('large', $grid-box-gutter-large);

    &-border {
      &_left {
        border-left: 1px solid palette('grays', 'separator');
      }

      &_right {
        border-right: 1px solid palette('grays', 'separator');
      }

      &_top {
        border-top: 1px solid palette('grays', 'separator');
      }

      &_bottom {
        border-bottom: 1px solid palette('grays', 'separator');
      }
    }

    &-auto {
      flex: 1 1 0%;
      min-width: 0;
    }

    &-grow {
      flex-grow: 1;
    }

    &-sticky {
      margin-left: -1px;
    }
  }
}
