$icon-size: 24px !default;
$icon-size-large: 80px !default;
$icon-size-middle: 50px !default; // only use in mediaDB
$icon-size-button: 17px !default;
$icon-size-small: 14px !default;
$icon-size-badge: 12px !default;
$icon-color-tooltip: #002850 !default;
$icon-color-tooltip-background: #d0def0 !default;
$icon-color: palette('default') !default;
$icon-color-white: $color-white !default;

e-icon {
  display: inline-flex;
  position: relative;
  vertical-align: middle;

  &:after {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .e-icon {
    fill: $icon-color;
    pointer-events: none;
  }
}

.e {
  &-icon {
    fill: $icon-color;
    height: $icon-size;
    vertical-align: middle;
    width: $icon-size;

    &-large {
      height: $icon-size-large;
      width: $icon-size-large;
    }

    &-middle {
      height: $icon-size-middle;
      width: $icon-size-middle;
    }

    &-white {
      fill: $icon-color-white;
    }

    &-rotate-left {
      transform: rotate(-90deg);
    }

    &-rotate-right {
      transform: rotate(90deg);
    }

    &-label {
      background: url('question-circle.svg') no-repeat center center;
      height: $icon-size-small;
      margin-left: 4px;
      margin-top: -3px;
      vertical-align: middle;
      width: $icon-size-small;

      use {
        display: none;
      }
    }

    &-helper {
      height: $icon-size-small;
      margin-left: 4px;
      margin-top: -2px;
      vertical-align: middle;
      width: $icon-size-small;
    }

    &-dropdown,
    &-table,
    &-small {
      height: $icon-size-small;
      vertical-align: middle;
      width: $icon-size-small;
    }

    &-table {
      cursor: pointer;
      margin: -3px 5px 0;
    }

    &-inline {
      margin: 0 5px -1px 0;
      vertical-align: -27%; //TODO: fix webcomponent align (replace to margin?)

      &.e-icon-small {
        margin: -4px 5px -1px 0;
        vertical-align: middle;
      }
    }

    &-disabled {
      cursor: default;
      opacity: .5;
    }
  }
}
