$tooltip_old-color-backgronud: #fff !default;
$tooltip_old-color: palette('grays', 'text') !default;
$tooltip_old-color-border: #ccc !default;
$tooltip_old-box-arrow-size: 8px !default;
$tooltip_old-box-borderradius: 0 !default;
$tooltip_old-box-width-longtext: 200px !default;
$tooltip_old-box-width-longtext-medium: 400px !default;
$tooltip_old-box-width-longtext-large: 600px !default;
$tooltip_old-text-size: typo('small', 'size') !default;
$tooltip_old-text-weight: typo('body', 'weight') !default;
$tooltip_old-transition: all $transition-time ease-in-out !default;
$tooltip_old-transition-long: all $transition-time 1s ease-in-out !default;

@mixin tooltip_old-container() {
  background: $tooltip_old-color-backgronud;
  bottom: 135%;
  color: $tooltip_old-color;
  opacity: 0;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  transition: $tooltip_old-transition;
  visibility: hidden;
  z-index: z('tooltip');
}

@mixin tooltip_old-tip() {
  box-shadow: 1px 1px 0 0 $tooltip_old-color-border;
  content: '';
  height: $tooltip_old-box-arrow-size;
  margin: -3px;
  transform: rotate(45deg);
  width: $tooltip_old-box-arrow-size;
}

@mixin tooltip_old-content() {
  border: 1px solid $tooltip_old-color-border;
  border-radius: $tooltip_old-box-borderradius;
  box-shadow: 0 2px 4px rgba($color-black, .1);
  content: attr(data-e-tooltip);
  font-size: $tooltip_old-text-size;
  font-weight: $tooltip_old-text-weight;
  line-height: $text-lineheight;
  padding: .533em 1em;
  text-align: left;
  white-space: nowrap;
}

.e {
  &-tooltip {
    display: inline-block;
    position: relative;

    &:after,
    &:before {
      @include tooltip_old-container;
    }

    &:after {
      @include tooltip_old-tip;
    }

    &:before {
      @include tooltip_old-content;
    }

    &:hover,
    &-hover {
      &:after,
      &:before {
        bottom: 130%;
        opacity: 1;
        visibility: visible;
      }
    }

    &-cell {
      display: table-cell;

      &:before,
      &:after {
        bottom: 105%;
      }

      &:hover {
        &:before,
        &:after {
          bottom: 100%;
        }
      }
    }

    &-top-left {
      &:before {
        left: 50%;
        right: auto;
        transform: translateX(-15px);
      }
    }

    &-top-right {
      &:before {
        left: auto;
        right: 50%;
        transform: translateX(15px);
      }
    }

    &-bottom-left,
    &-bottom-right {
      &:after,
      &:before {
        bottom: auto;
        top: 140%;
      }

      &:after {
        transform: rotate(-135deg);
      }

      &:hover {
        &:before {
          bottom: auto;
        }
      }
    }

    &-bottom-left {
      &:before {
        left: 50%;
        right: auto;
        transform: translateX(-15px);
      }

      &:hover {
        &:before {
          bottom: auto;
        }
      }
    }

    &-bottom-right {
      &:before {
        left: auto;
        right: 50%;
        transform: translateX(15px);
      }
    }

    &-right {
      &:after,
      &:before {
        bottom: 50%;
        left: 110%;
        right: auto;
      }

      &:after {
        transform: rotate(135deg);
      }

      &:before {
        transform: translateY(50%);
      }

      &:hover {
        &:after,
        &:before {
          bottom: 50%;
          left: 105%;
        }
      }

      .e-tooltip__content {
        bottom: 50%;
        left: 110%;
        transform: translateY(50%);
      }
    }

    &-left {
      &:after,
      &:before {
        bottom: 50%;
        left: auto;
        right: 110%;
      }

      &:after {
        transform: rotate(-45deg);
      }

      &:before {
        transform: translateY(50%);
      }

      &:hover {
        &:after,
        &:before {
          bottom: 50%;
          left: auto;
          right: 105%;
        }
      }

      .e-tooltip__content {
        bottom: 50%;
        left: auto;
        right: 110%;
        transform: translateY(50%);
      }
    }

    &-bottom {
      &:after,
      &:before {
        bottom: auto;
        top: 140%;
      }

      &:after {
        transform: rotate(-135deg);
      }

      &:hover {
        &:after,
        &:before {
          bottom: auto;
          top: 135%;
        }
      }

      .e-tooltip__content {
        bottom: auto;
        top: 135%;
      }

      &.e-tooltip-sticky {
        &:after,
        &:before {
          top: 100%;
        }

        .e-tooltip__content {
          top: 100%;
        }
      }
    }

    &-bottomright {
      &:after,
      &:before {
        bottom: auto;
        top: 140%;
      }

      &:after {
        transform: rotate(-135deg);
      }

      &:hover {
        &:after,
        &:before {
          bottom: auto;
          top: 135%;
        }
      }

      .e-tooltip__content {
        bottom: auto;
        left: auto;
        right: 50%;
        top: 135%;
        transform: translateX(15px);

        &-large {
          left: - $grid-gutter-width / 2;
        }
      }

      &.e-tooltip-sticky {
        &:after,
        &:before {
          top: 100%;
        }

        .e-tooltip__content {
          top: 100%;
        }
      }
    }

    &-longtext {
      &:before {
        white-space: normal;
        width: $tooltip_old-box-width-longtext;
      }
    }

    &-breakword {
      &:before {
        word-break: break-all;
      }
    }

    &-longdelay {
      &:before,
      &:after,
      & .e-tooltip__content {
        transition: $tooltip_old-transition-long;
      }

      &:hover {
        &:before,
        &:after,
        & .e-tooltip__content {
          transition: $tooltip_old-transition;
        }
      }
    }

    &-html {
      &:hover {
        &:before {
          opacity: 0;
          visibility: hidden;
        }

        &:after {
          bottom: 135%;
          opacity: 1;
          visibility: visible;
        }

        .e-tooltip {
          &:hover {
            &:before,
            &:after {
              opacity: 0;
              visibility: hidden;
            }
          }

          &__content {
            opacity: 1;
            visibility: visible;

            &:after {
              display: none;
            }
          }
        }
      }

      &.e-tooltip-left {
        &:hover {
          &:after {
            bottom: 50%;
            right: 110%;
          }
        }
      }

      &.e-tooltip-right {
        &:hover {
          &:after {
            bottom: 50%;
            left: 110%;
          }
        }
      }

      &.e-tooltip-bottomright {
        &:hover {
          &:after,
          &:before {
            bottom: auto;
            top: 135%;
          }
        }
      }
    }

    &__content {
      @include tooltip_old-container;
      @include tooltip_old-content;
      white-space: normal;
      width: $tooltip_old-box-width-longtext;

      &-medium {
        width: $tooltip_old-box-width-longtext-medium;
      }

      &-large {
        width: $tooltip_old-box-width-longtext-large;
      }

      &:after {
        @include tooltip_old-tip;
      }

      .e-charttooltip {
        margin: -5.863px -11px;
      }
    }
  }
}
