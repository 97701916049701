.fd-zone {
  .fd-file {
    bottom: 0;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 86px;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &.over {
    @extend .e-filedrop-over;
  }

  > div:first-child {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  form {
    height: 100%;
  }
}
