.e {
  &-emptystate {
    align-items: center;
    color: palette('grays', 'placeholder');
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 100%;
    padding: $grid-gutter-width / 2;
    text-align: center;

    &-top {
      justify-content: flex-start;
      padding-top: 35px;
    }

    &-absolute {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &-horizontal {
      flex-direction: row;
      text-align: left;

      .e-emptystate__content {
        align-items: flex-start;
      }

      .e-emptystate__icon,
      .e-emptystate__image {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }

    &__content {
      align-items: center;
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      justify-content: center;
      max-width: 320px;
      width: 100%;

      *:last-child {
        margin-bottom: 0;
      }

      > img { // inbox preview shame
        margin-bottom: 20px;
      }
    }

    &__image {
      flex: 0 0 auto;
      margin-bottom: 20px;
    }

    &__icon {
      align-items: center;
      border: 4px solid currentColor;
      border-radius: 50%;
      display: flex;
      flex: 0 0 auto;
      height: 100px;
      justify-content: center;
      margin-bottom: 20px;
      width: 100px;

      .e-icon {
        fill: currentColor;
        height: 50px;
        width: 50px;
      }

      &-small {
        border-width: 2px;
        height: 50px;
        width: 50px;

        .e-icon {
          height: 25px;
          width: 25px;
        }
      }

      .e-circles__circle {
        &:before {
          background-color: currentColor;
        }
      }
    }

    &__title {
      color: palette('default');
      display: block;
      font-size: typo('title', 'size');
      font-weight: typo('title', 'weight');
      line-height: $text-lineheight-condensed;
      margin-bottom: 10px;
      max-width: 100%;
    }

    &__lead {
      color: palette('default');
      display: block;
      margin-bottom: 10px;
      max-width: 100%;
    }

    &__button {
      margin-top: 10px;
    }
  }
}
