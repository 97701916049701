$popup-color-background: #fff !default;

.e {
  &-popup {
    background: $popup-color-background;
    height: 100%;

    &__hidden {
      .e-popup & {
        // stylelint-disable declaration-no-important
        display: none !important;
      }
    }

    .fixedheader-container {
      padding-top: 0;
    }

    .e {
      &-topnav {
        display: none;
      }

      &-container {
        min-width: map-get($screen-sizes, 'x-small');
      }
    }
  }
}
