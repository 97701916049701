$snackbar-content-bg: $color-white !default;
$snackbar-box-shadow: 0 3px 5px 0 rgba($color-black, .1);
$snackbar-color-border: palette('grays', 'separator') !default;

.e {
  &-snackbar {
    background: $snackbar-content-bg;
    border: 1px solid $box-color-border;
    box-shadow: $snackbar-box-shadow;
    left: 0;
    margin: auto;
    min-width: 660px;
    padding: $grid-gutter-width / 2;
    position: absolute;
    right: 0;
    top: $grid-gutter-width / 2;
    width: 70%;
    z-index: z('topnav') - 1;

    &-bottom {
      bottom: $grid-gutter-width / 2;
      top: auto;
    }

    &__content {
      display: table;
      width: 100%;
    }

    &__description {
      display: table-cell;
      padding-right: $grid-gutter-width;
      vertical-align: middle;
      width: 100%;
    }

    &__actions {
      display: table-cell;
      text-align: right;
      vertical-align: middle;
      white-space: nowrap;
    }

    h3 {
      margin: 0;
    }

    .e-btn {
      margin-left: $grid-gutter-width / 3;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
