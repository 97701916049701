$ng-tabs-color-background-selected: #0097cf;

tags-input {
  display: block;

  .host {
    height: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    position: relative;

    &:active {
      outline: none;
    }
  }

  .tags {
    @include e-input;
    @include word-wrap;
    cursor: text;
    height: 100%;
    line-height: $input-box-height - 2;
    overflow: hidden;
    padding-left: .3em;

    &.focused {
      border-color: $input-color-border-focus;
      box-shadow: 0 0 4px rgba($input-color-border-focus, .5);
      outline: none;
    }

    .tag-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .tag-item {
      @include e-label;
      @include e-label-modifier('tag');
      float: left;
      margin-top: 3px;
      padding-bottom: 0;

      &.selected {
        @include e-label-modifier('danger');
      }

      // scss-lint:disable SelectorDepth
      .remove-button {
        @include e-label-close;
        font-size: 0;
        height: $label-box-tag-height + 1;
        width: 16px;

        &:active {
          color: palette('danger');
        }
      }
    }

    .input {
      border: 0;
      outline: none;
      padding: 0;

      &.invalid-tag {
        color: palette('danger');
      }
    }
  }

  .autocomplete {
    background-color: $color-white;
    border: 1px solid rgba($color-black, .2);
    margin-top: 5px;
    padding: 5px 0;
    position: absolute;
    width: 100%;
    z-index: 999;

    .suggestion-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    // scss-lint:disable SelectorDepth
    .suggestion-item {
      background-color: $color-white;
      color: $color-black;
      cursor: pointer;
      font: 16px 'Helvetica Neue', Helvetica, Arial, sans-serif;
      overflow: hidden;
      padding: 5px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.selected {
        background-color: $ng-tabs-color-background-selected;
        color: $color-white;

        em {
          background-color: $ng-tabs-color-background-selected;
          color: $color-white;
        }
      }

      em {
        background-color: $color-white;
        color: $color-black;
        font: normal bold 16px 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }
    }
  }
}
