.e {
  &-upload {
    &__browse {
      position: relative;
    }

    &__filelabel {
      background: $color-white;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 100%;
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .e-input {
      cursor: pointer;
    }
  }
}
