e-slider {
  display: block;

  &[range] {
    .e-bubble:not(.e-bubble-active, .e-bubble-hover) ~ .e-tt-bubble {
      display: none;
    }

    .e-slider__line:before {
      background-color: palette('grays', 'separator');
    }
  }
}

.e {
  &-slider {
    margin: 0 10px;
    padding-top: 40px;
    position: relative;

    &-disabled {
      opacity: .5;
    }

    &-no_bubble {
      .e-tt-bubble {
        display: none;
      }
    }

    &__line {
      background-clip: content-box;
      background-color: palette('grays', 'separator');
      border-bottom: 5px solid transparent;
      border-top: 5px solid transparent;
      height: 20px;
      position: relative;

      &:before,
      &:after {
        background-color: palette('grays', 'separator');
        bottom: 0;
        content: '';
        height: 10px;
        position: absolute;
        width: 11px;
      }

      &:before {
        background-color: palette('secondary');
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        left: -10px;
      }

      &:after {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        right: -10px;
      }
    }

    &__fill {
      background-color: palette('secondary');
      height: 10px;
      left: 0;
      position: absolute;
      top: 0;
    }

    &__steps {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__step {
      background: rgba($color-white, .5);
      height: 100%;
      position: absolute;
      top: 0;
      transform: translateX(-1px);
      width: 2px;
    }

    &__label {
      bottom: 27px;
      color: #666;
      font-size: 11px;
      position: absolute;

      &-min {
        left: -10px;
      }

      &-max {
        right: -10px;
      }
    }
  }
}
