$criteria-color-background: palette('grays', 'inactive') !default;
$criteria-color-condition-border: #b1b2b1;

.e {
  &-criteria {
    margin-bottom: $grid-gutter-width;
    overflow-x: auto;
    width: 100%;

    &-noscroll {
      overflow-x: inherit;
    }

    // scss-lint:disable SelectorFormat
    .table_condition {
      border: 1px solid $criteria-color-condition-border;
    }

    &__operator {
      width: 120px;
    }

    &__title,
    &__crits,
    &__actions {
      display: table-cell;
    }

    &__title {
      font-weight: $text-bold-weight;
    }

    &__actions {
      vertical-align: middle;
    }

    &__item {
      background: $criteria-color-background;
      padding: 3px;

      &:hover {
        background: darken($criteria-color-background, 3%);
      }

      &-table {
        border-bottom: 1px solid palette('grays', 'separator');
        display: table;
        padding: $grid-gutter-width 3px $grid-gutter-width $grid-gutter-width;
        width: 100%;

        &:last-child {
          border-bottom: 0;
        }
      }

      &-behavior {
        @include word-wrap;
        padding: $grid-gutter-width 3px $grid-gutter-width $grid-gutter-width;
      }

      .e-select,
      .e-input {
        margin-left: 5px;
      }

      .e-btn {
        margin: 5px;
      }
    }
  }
}
