e-validator {
  border-bottom: 1px solid palette('grays', 'separator');
  display: block;
  fill: palette('grays', 'shade');
  line-height: $text-lineheight-condensed;

  mark {
    background-color: transparent;
    color: palette('grays', 'shade');
    font-weight: $text-bold-weight;
  }

  &[status='danger'] {
    mark {
      color: palette('danger');
    }
  }

  &[status='success'] {
    mark {
      color: palette('success');
    }
  }

  &[status='warning'] {
    mark {
      color: palette('warning');
    }
  }

  &-header {
    display: block;
    transition: background $transition-time;
  }

  &-content {
    color: palette('default');
    display: block;
    height: 0;
    line-height: $text-lineheight;
    overflow: hidden;
    padding: 0 15px 0 39px;
    transition: height $transition-time, margin $transition-time;
  }
}

.e-validator {
  &__header {
    display: flex;
    font-size: 15px;
    justify-content: space-between;
    padding: 10px #{$grid-gutter-width / 2} 9px;
    position: relative;

    h2 {
      margin-bottom: 0;

      &:before,
      &:after {
        border: 6px solid transparent;
        border-bottom: 6px solid palette('grays', 'separator');
        border-top-width: 0;
        bottom: 0;
        content: '';
        position: absolute;
        right: 15px;
        transform: translateX(-1px) translateY(.5px);
      }

      &:after {
        border: 5px solid transparent;
        border-bottom: 5px solid $color-white;
        transform: translateX(-2px) translateY(1px);
      }
    }
  }

  &__title {
    flex-grow: 1;
  }

  &__summary_item {
    align-items: center;
    display: inline-flex;
    font-size: 11px;
    margin-left: 10px;

    e-icon {
      margin-left: 4px;
    }
  }

  &__icon {
    flex-shrink: 0;
    padding-right: 5px;
  }

  &__action {
    flex-shrink: 0;
    font-size: 14px;
    margin-left: auto;
    padding-left: 10px;

    e-icon {
      transform: translateX(2px);
      transform-origin: center;
      transition: transform $transition-time;
    }

    .e-icon {
      height: 18px;
      width: 18px;
    }
  }

  &__subheader:not(:empty) {
    @include text-overflow();
    color: palette('default');
    padding: 0 #{$grid-gutter-width / 2} 10px 40px;
  }

  &__toggle {
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #f2f2f2;
    }

    &-opened {
      .e-validator__action e-icon {
        transform: translateX(2px) rotate(180deg);
      }
    }
  }
}
