.e {
  &-input {
    &-textarea {
      @include e-input-modifier('textarea');
    }

    &-auto_height {
      @include e-input-modifier('autoheight');
    }

  }
}
