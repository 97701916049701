$filedrop-color-background: palette('dark', 'xx-light');
$filedrop-color-background-over: darken($filedrop-color-background, 5);
$filedrop-box-border: 3px dashed palette('dark', 'light');
$filedrop-box-width: 570px;
$filedrop-box-height: 315px;

.e {
  &-filedrop {
    @include center-content;
    background: $filedrop-color-background;
    border: $filedrop-box-border;
    display: inline-block;
    height: $filedrop-box-height;
    position: relative;
    width: $filedrop-box-width;

    &__content {
      padding: $grid-gutter-width / 2;
      text-align: center;
    }

    &-over {
      background: $filedrop-color-background-over;
    }

    &-overlay {
      background: rgba($filedrop-color-background, .85);
      bottom: ($grid-gutter-width / 2);
      display: none;
      height: auto;
      left: ($grid-gutter-width / 2);
      position: fixed;
      right: ($grid-gutter-width / 2);
      top: ($grid-gutter-width / 2);
      width: auto;
      z-index: z('modal');

      &.e-filedrop-over {
        background: rgba($filedrop-color-background, .85);
        display: block;
      }
    }
  }
}
