$topnav-size-height: $fixed-topnav-height !default;
$topnav-color-background: map-get($palette_navigation, 'background') !default;

.e {
  &-topnav {
    @include clearfix;
    background: $topnav-color-background;
    position: relative;

    @include screen('topnav', 'down') {
      padding-left: 60px;
    }

    &__container {
      margin: auto;
      max-width: map-get($screen-sizes, 'large');

      @include screen('large') {
        padding-right: $grid-gutter-width / 2;
      }

      &-fullwidth {
        max-width: 100%;
      }
    }

    &__mobilemenu {
      display: none;

      &:checked {
        + .e-menu {
          display: block;
        }
      }
    }

    &-fixed {
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: z('topnav');
    }

    &__logo {
      float: left;
      line-height: $topnav-size-height;
      margin: 0 $grid-gutter-width / 2;
      overflow: hidden;

      @include screen('topnav', 'down') {
        margin: 0;
      }

      @include screen('medium', 'down') {
        width: 20px;
      }

      svg {
        fill: map-get($palette_navigation, 'text');
        height: 30px;
        position: relative;
        top: 1px;
        vertical-align: middle;
        width: 115px;
      }

      &-b2c {
        svg {
          fill: none;
          top: -2px;
          width: 130px;
        }
      }
    }

    &-old {
      .e-topnav {
        &__logo {
          @include screen('medium', 'down') {
            width: auto;
          }
        }
      }
    }
  }
}

// Fixed header helper container
.fixedheader-container {
  padding-top: $fixed-topnav-height;

  &-image {
    background-position: 0 $fixed-topnav-height;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    height: 100%;
  }

  &-withfooter {
    margin-bottom: -$fixed-footer-height;
    min-height: 100%;
  }
}

.fixedbreadcrumb {
  padding-top: $fixed-topnav-height + $fixed-breadcrumb-height;
}

