$card-color-border: #eee !default;
$card-color-border-active: $input-color-border-focus !default;
$card-box-height-title: 50px !default;
$card-box-height-content: calc(100% - #{$card-box-height-title});

.e {
  &-card {
    @include aspect-ratio(1, 1, 'before');
    background: $color-white;
    background-image:
      linear-gradient(45deg, $card-color-border 25%, transparent 25%, transparent 75%, $card-color-border 75%, $card-color-border),
      linear-gradient(45deg, $card-color-border 25%, transparent 25%, transparent 75%, $card-color-border 75%, $card-color-border);
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
    border: 1px solid $card-color-border;
    box-shadow: 0 2px 4px 1px rgba($color-black, .1);
    display: inline-block;
    margin: 0 20px 20px 0;
    min-height: 180px;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    width: 240px + 2px;

    &:after {
      background: $color-white;
      bottom: 0;
      box-shadow: 0 0 10px 8px #fff;
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &:hover,
    &-hover {
      .e-card__buttons {
        opacity: 1;
      }

      .e-card__action {
        opacity: 1;
      }

      .e-card__content-empty {
        background: $color-hover;

        svg {
          fill: palette('grays', 'text');
        }
      }

      .e-card__lead {
        transform: translate3d(0, 0, 0);
      }

      .e-card__title {
        max-height: 100%;
        transition: none;
      }
    }

    &-emptystate {
      background: $color-white;
      border: 2px dashed $card-color-border;
      box-shadow: none;
    }

    &-has_overlay {
      &:hover,
      &.e-card-hover {
        .e-card__content {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    &-has_input {
      overflow: visible;

      &:after {
        content: none;
      }

      .e-card__lead {
        box-shadow: none;
        overflow: visible;
      }
    }

    &-no_title {
      &:after {
        display: none;
      }

      .e-card__content {
        height: 100%;
      }
    }

    &-small {
      width: 182px;
    }

    &-fluid {
      margin: 0;
      width: 100%;
    }

    &-auto_height {
      &:before {
        content: none;
      }

      &.e-card-emptystate {
        min-height: 0;

        .e-card__content {
          margin-bottom: 0;
        }
      }

      .e-card__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: $card-box-height-title;
        position: relative;
      }

      .e-card__preview {
        display: inline-block;
        position: static;
        transform: none;
      }
    }

    &-no_content {
      min-height: 0;
      transition: box-shadow $transition-time ease-in-out;
      user-select: none;

      &:hover {
        box-shadow: 0 7px 14px rgba(0, 0, 0, .15), 0 5px 5px rgba(0, 0, 0, .12);
      }

      &:before {
        display: none;
      }

      .e-card__lead {
        padding: 15px;
        position: static;
        transform: translate3d(0, 0, 0);
      }

      .e-card__title {
        max-height: none;
      }
    }

    &-no_hover {
      min-height: 0;

      .e-card__content {
        margin-bottom: 0;
        min-height: 0;
      }

      .e-card__lead {
        position: static;
        transform: translate3d(0, 0, 0);
      }

      .e-card__title {
        max-height: none;
      }
    }

    &-no_checkerboard {
      background: $color-white;
    }

    &-active {
      border-color: $card-color-border-active;
      box-shadow: 0 2px 4px 1px rgba($card-color-border-active, .5);
    }

    &__content {
      background-position: center;
      background-size: cover;
      height: $card-box-height-content;
      left: 0;
      min-height: 130px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;

      .e-card-has_overlay & {
        &:after {
          background: rgba(palette('grays', 'text'), .66);
          content: '';
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity $transition-time ease-in-out;
          width: 100%;
        }
      }

      &-empty {
        align-items: center;
        background: palette('grays', 'zebra');
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: background $transition-time ease-in-out;

        svg {
          fill: palette('grays', 'placeholder');
          transition: fill $transition-time ease-in-out;
        }
      }
    }

    &__lead {
      @include word-wrap;
      background: $color-white;
      bottom: 0;
      box-shadow: 0 -1px 0 0 $card-color-border, 0 0 5px 5px $color-white inset;
      display: flex;
      flex-direction: column;
      left: 0;
      max-height: 100%;
      min-height: $card-box-height-title;
      overflow: hidden;
      padding: 9px 15px;
      position: absolute;
      transform: translate3d(0, 100%, 0) translate3d(0, -$card-box-height-title, 0);
      transition: all $transition-time-long ease-out;
      width: 100%;
    }

    &__caption {
      display: flex;
      flex: 0 0 auto;
      font-size: 0;
      min-height: 32px;
    }

    &__description {
      color: palette('grays', 'shade');
      flex: 1 0 auto;
      padding-bottom: $card-box-height-title;
      padding-top: 10px;
    }

    &__title {
      align-self: center;
      color: palette('grays', 'text');
      flex: 1 1 0%;
      font-size: typo('body', 'size');
      line-height: $text-lineheight-condensed;
      max-height: 32px;
      overflow: hidden;
      position: relative;
      transition: max-height 0s linear $transition-time-long;

      &-multiple {
        p {
          margin-bottom: 2px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    input.e-card__input { // mediadb xeditable workaround
      color: palette('grays', 'text');
      height: $card-box-height-title;
      left: 0;
      line-height: $card-box-height-title;
      margin: 0;
      padding: 0 14px;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__badge {
      flex: 0 0 auto;
      padding: 6px 0 0 15px;

      .e-label {
        margin: 0;
      }
    }

    &__preview {
      border: 0;
      bottom: auto;
      left: 50%;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      right: auto;
      top: 50%;
      transform: translate(-50%, -50%);
      vertical-align: top;

      &-stretch {
        bottom: 0;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        transform: none;
        width: 100%;
      }
    }

    &__buttons {
      opacity: 0;
      position: absolute;
      right: 10px;
      top: 10px;
      transition: opacity $transition-time ease-in-out;
      z-index: 1;

      .e-btn {
        @include e-btn-on_overlay;
      }

      ~ .e-card__lead {
        max-height: calc(100% - 55px);
      }
    }

    &__action {
      @include center-content(false, '.e-card__action');
      color: $color-white;
      height: $card-box-height-content;
      left: 0;
      opacity: 0;
      padding: 60px 0;
      position: absolute;
      text-align: center;
      top: 0;
      transition: opacity $transition-time ease-in-out;
      width: 100%;

      ~ .e-card__lead {
        max-height: calc(50% - 10px);

        .e-card-small & {
          max-height: calc(50% - 15px);
        }
      }
    }

    &__footer {
      background: $color-white;
      bottom: 0;
      left: 0;
      padding: 10px 15px 15px;
      position: absolute;
      width: 100%;

      e-dropdown {
        float: right;
      }
    }
  }
}
