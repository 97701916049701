$buttonradio-color-background-checked: palette('grays', 'inactive') !default;

.e {
  &-buttonradio,
  &-buttoncheckbox {
    display: none;

    &:checked {
      + .e-btn {
        background: darken($btn-color-background, 5);
        box-shadow: $btn-checked-shadow;

        &:focus,
        &:hover {
          background: darken($btn-color-background, 5);
          box-shadow: $btn-checked-shadow;
        }

        @each $class, $data in $btn_style {
          &-#{$class} {
            background: darken(map-get($data, 'background'), 10);

            &:focus,
            &:hover {
              background: darken(map-get($data, 'background'), 10);
              box-shadow: $btn-checked-shadow;
            }
          }
        }
      }
    }
  }

  &-buttonradio {
    &:checked {
      + .e-btn {
        cursor: default;
      }
    }
  }
}

// scss-lint:disable QualifyingElement
label.e-buttonradio,
label.e-buttoncheckbox {
  display: inline-block;

  input {
    display: none;

    &:checked + .e-btn {
      box-shadow: 0 0 5px transparentize(darken($color-white, 30), .3);

      @each $class, $data in $btn_style {
        &-#{$class} {
          border-color: darken(map-get($data, 'background'), 30);
          box-shadow: 0 0 3px transparentize(map-get($data, 'background'), .3);
        }
      }
    }
  }
}
