$modal-zindex: z('modal') !default;
$modal-backdrop-zindex: z('modal-backdrop') !default;
$modal-backdrop-bg: rgba($color-black, .5) !default;
$modal-backdrop-opacity: .5 !default;
$modal-content-bg: $color-white !default;
$modal-content-border-color: $color-black !default;
$modal-border-radius: 0 !default;
$modal-inner-padding: 0 $grid-gutter-width / 2 !default;
$modal-title-padding: $grid-gutter-width $grid-gutter-width 5px !default;
$modal-footer-padding: $grid-gutter-width !default;
$modal-title-line-height: 30px !default;
$modal-iframe-padding: 54px !default;
$modal-box-header-height: 65px !default;
$modal-box-footer-height: 66px !default;

e-modal {
  display: none;
}

.e {
  &-modal {
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: z('flying');

    &__visible {
      display: block;
    }

    &__background {
      background: $modal-backdrop-bg;
      bottom: 0;
      height: 100%;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: z('modal-backdrop');
    }

    &__container {
      @include center-content(true, '.e-modal__content');
      height: 100%;
      position: relative;
    }

    &__close {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      right: 15px;
      top: 15px;
    }

    &__header {
      height: $modal-box-header-height;
      left: 0;
      line-height: 1.2;
      padding: ($grid-gutter-width / 2) $grid-gutter-width;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__footer {
      border-top: 1px solid palette('grays', 'separator');
      bottom: 0;
      height: $modal-box-footer-height;
      left: 0;
      padding: $grid-gutter-width / 2;
      position: absolute;
      width: 100%;
    }

    &__content {
      background: $modal-content-bg;
      display: inline-block;
      max-width: 650px;
      padding: $grid-gutter-width * 1.5 $grid-gutter-width;
      position: relative;
      text-align: left;
      vertical-align: middle;
      width: 100%;
      z-index: z('modal');

      &-small {
        max-width: 500px;
      }

      &-large {
        max-width: 1000px;
      }

      .e-separator-fullwidth {
        margin-left: - $grid-gutter-width;
        margin-right: - $grid-gutter-width;
      }

      &-condensed {
        padding: $grid-gutter-width / 2;

        .e-separator-fullwidth {
          margin-left: - $grid-gutter-width / 2;
          margin-right: - $grid-gutter-width / 2;
        }

        .e-modal__scrollable {
          margin: 0 (-($grid-gutter-width / 2));
          padding: 0 ($grid-gutter-width / 2) ($grid-gutter-width / 2);
        }

        .e-modal__header {
          padding: $grid-gutter-width / 2;
        }
      }
    }

    &__image {
      margin-bottom: - $grid-gutter-width / 1.5;
      margin-top: - $grid-gutter-width / 2;
    }

    &-image {
      .e-modal__content {
        width: auto;
      }

      .e-modal__close {
        right: 5px;
        top: 5px;
      }
    }

    &-iframe {
      .e-modal__content {
        font-size: 0;
        padding: $modal-iframe-padding 0 0;
      }

      .e-modal__close {
        right: 5px;
        top: 5px;
      }
    }

    &-multiple {
      .e-modal__content {
        font-size: 0;
        padding: $grid-gutter-width * 1.5 $grid-gutter-width;
        text-align: center;
      }
    }

    &-withheader {
      .e-modal__content {
        padding-top: $modal-box-header-height;
      }
    }

    &__scrollable {
      bottom: $modal-box-footer-height;
      margin: 0 (-$grid-gutter-width);
      overflow: auto;
      padding: 0 $grid-gutter-width ($grid-gutter-width * 1.5);
      position: absolute;
      top: $modal-box-header-height;
      width: 100%;

      .e-modal__content {
        padding: $grid-gutter-width * 1.5 $grid-gutter-width;
      }
    }

    &__prev_item,
    &__next_item {
      bottom: 25px;
      cursor: pointer;
      left: 0;
      padding: 3px 2px;
      position: absolute;
      top: 30px;
      transition: background .2s;
      width: 30px;

      &:hover {
        background: $color-hover;
      }

      a {
        left: 2px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__next_item {
      left: auto;
      padding: 3px 4px;
      right: 0;

      a {
        left: 4px;
      }
    }
  }
}

.modal-open {
  overflow: hidden;
}

.modal {
  background: $color-white;
  border-radius: $modal-border-radius;
  display: none;
  left: 0;
  margin: auto;
  max-width: 100%;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 5%;
  width: 600px;
  z-index: $modal-zindex;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-body {
  background-clip: padding-box;
  background-color: $modal-content-bg;
  max-height: 460px;
  // Remove focus outline from opened modal
  outline: 0;
  overflow-y: auto;
  padding: $modal-inner-padding;
  position: relative;
}

.modal-backdrop {
  background-color: $modal-backdrop-bg;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $modal-backdrop-zindex;

  &.fade {
    opacity: 0;
  }

  &.in {
    opacity: $modal-backdrop-opacity;
  }
}

.modal-header {
  background-color: $modal-content-bg;
  padding: $modal-title-padding;

  h3 {
    @extend %h2;
  }

  .close {
    font-size: 0;
    position: absolute;
    right: 30px;
    text-decoration: none;
    top: 10px;

    &:before {
      background: url('close.svg');
      content: '';
      height: 20px;
      position: absolute;
      width: 20px;
    }
  }
}

.modal-title {
  margin: 0;
}

.modal-footer {
  background-color: $modal-content-bg;
  padding: $modal-footer-padding;
  text-align: left; // right align buttons

  .btn {
    @include e-btn;
  }

  .btn-primary {
    @include e-btn-modifier('primary');
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}
