$menu-color-background: map-get($palette_navigation, 'background') !default;
$menu-color-background-hover: map-get($palette_navigation, 'background-hover') !default;
$menu-color-background-active: darken($menu-color-background, 5);
$menu-color-text: map-get($palette_navigation, 'text') !default;
$menu-color-active: map-get($palette_navigation, 'text-active') !default;
$menu-color-border: #03121a;
$menu-color-shadow: #163a4e;
$menu-color-border-name-child: #0c2838;
$menu-color-shadow-name-child: #184560;
$menu-text-size: typo('button', 'size') !default;
$menu-text-size-child: typo('body', 'size') !default;
$menu-box-height: $fixed-topnav-height !default;
$menu-box-padding: 0 15px !default;
$menu-box-padding-old: 0 20px !default;
$menu-hover-transition: all $transition-time ease-in-out !default;
$menu-box-drop-maxheight: calc(100vh - 60px) !default;
$menu-box-drop-minwidth: 220px !default;

.e {
  &-menu {
    background: $menu-color-background;
    float: left;

    @include screen('topnav', 'down') {
      background: $menu-color-background-active;
      display: none;
      left: 0;
      position: absolute;
      top: $menu-box-height;
      width: 100%;
      z-index: z('dropdown');
    }

    &__drop {
      display: none;
      margin-left: -$box-form-element-height;
      padding: 0 $box-form-element-height $box-form-element-height;
      position: absolute;
      z-index: z('dropdown');
    }

    &__list {
      border-left: 1px solid $menu-color-border;
      box-shadow: -1px 0 0 0 $menu-color-shadow;
      float: left;
      font-size: 0;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &-child {
        background: $menu-color-background-hover;
        border: 0;
        box-shadow: none;
        list-style-type: none;
        margin: 0;
        max-height: $menu-box-drop-maxheight;
        min-width: $menu-box-drop-minwidth;
        overflow: auto;
        padding: 0;
        white-space: nowrap;

        &__item {
          display: block;
        }
      }
    }

    &__item {
      color: $menu-color-text;
      display: inline-block;
      font-weight: $text-bold-weight;
      position: relative;
      transition: $menu-hover-transition;

      &:hover {
        background: $menu-color-background-hover;
        color: $menu-color-active;
        transition: none;

        &:after {
          display: none;
        }

        .e-menu__drop {
          display: block;
        }
      }

      &-active {
        background: $menu-color-background-active;
        color: $menu-color-active;

        &:after {
          @include triangle(top, palette('grays', 'body'), 8px, 1);
          bottom: 0;
          left: 0;
          margin: auto;
          position: absolute;
          right: 0;
        }
      }

      &-child {
        display: block;
        z-index: z('dropdown');

        &:nth-last-child {
          .e-menu__name-child {
            border-bottom: 0;
            box-shadow: none;
          }
        }
      }

    }

    &__name {
      border-right: 1px solid $menu-color-border;
      box-shadow: inset -1px 0 0 0 $menu-color-shadow;
      color: inherit;
      display: inline-block;
      font-size: $menu-text-size;
      line-height: $menu-box-height;
      padding: $menu-box-padding;
      text-decoration: none;
      transition: none;

      &:visited,
      &:hover,
      &:focus {
        color: inherit;
        text-decoration: none;
      }

      &:hover:focus {
        text-decoration: none;
      }

      &-child {
        border-bottom: 1px solid $menu-color-border-name-child;
        border-left: 0;
        border-right: 0;
        box-shadow: 0 1px 0 0 $menu-color-shadow-name-child;
        font-size: $menu-text-size-child;
        line-height: 2.6em;
        transition: $menu-hover-transition;
        width: 100%;

        &:hover {
          background: $menu-color-background-active;
          color: $menu-color-active;
          transition: none;
        }
      }
    }

    &-old {
      .e-menu {
        &__name {
          padding: $menu-box-padding-old;

          &-child {
            padding: $menu-box-padding-old;
          }
        }
      }
    }
  }
}

// scss-lint:disable QualifyingElement
span.e-menu__name {
  cursor: default;
}
