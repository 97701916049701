$calendar-box-size-days: 35px !default;
$calendar-box-size-header: 45px !default;
$calendar-box-size-button: $box-form-element-height !default;
$calendar-color-current: $color-white !default;
$calendar-color-today: palette('secondary', 'default') !default;
$calendar-color-alternate: palette('default', 'light') !default;
$calendar-color-disabled: palette('default', 'x-light') !default;
$calendar-color-background-current: palette('secondary', 'light') !default;
$calendar-color-cell-separator: palette('grays', 'separator') !default;
$calendar-color-cell-hover-background: palette('grays', 'inactive') !default;
$calendar-hover-transition: background $transition-time ease-in-out !default;

e-calendar {
  &:focus {
    outline: none;
  }
}

@mixin e-calendar {
  background: $calendar-color-current;
  border: 1px solid $calendar-color-cell-separator;
  margin: 0 auto $grid-gutter-width / 2;
  position: relative;
  text-align: center;
  user-select: none;
  width: ($calendar-box-size-days * 7) + 2px;

  table {
    font-size: typo('body', 'size');
    margin: auto;
    table-layout: fixed;
    text-align: center;
    width: 100%;
  }

  thead {
    th {
      font-weight: $text-bold-weight;
      height: $calendar-box-size-days;
      line-height: $calendar-box-size-days;
      text-transform: uppercase;
      vertical-align: middle;
      width: $calendar-box-size-days;
    }
  }
}

@mixin e-calendar-day {
  cursor: pointer;
  height: $calendar-box-size-days;
  line-height: $calendar-box-size-days;
  transition: $calendar-hover-transition;
  width: $calendar-box-size-days;

  &:hover {
    background: $calendar-color-cell-hover-background;
  }
}

@mixin e-calendar-day-modifier($modifier: 'default') {
  @if ($modifier == 'current') {
    background: $calendar-color-background-current;
    color: $calendar-color-current;

    &:hover {
      background: darken($calendar-color-background-current, 5);
    }
  }

  @if ($modifier == 'alternate') {
    color: $calendar-color-alternate;
  }

  @if ($modifier == 'disabled') {
    color: $calendar-color-disabled;
    cursor: default;

    &:hover {
      background: transparent;
    }
  }

  @if ($modifier == 'today') {
    color: $calendar-color-today;
  }
}

.e {
  &-calendar {
    @include e-calendar;

    &-sticky {
      margin: -1px auto;
    }

    &__head {
      align-items: center;
      border-bottom: 1px solid $calendar-color-cell-separator;
      color: palette('default');
      display: flex;
      height: $calendar-box-size-header;
    }

    &__action {
      cursor: pointer;
      margin: 0 4px;
    }

    &__now {
      box-shadow: -7px 0 0 -6px $calendar-color-cell-separator;
      padding: 10px;
      position: relative;
    }

    &__now_day {
      font-size: typo('small', 'size');
      font-weight: $text-bold-weight;
      left: 16px;
      line-height: 1;
      position: absolute;
      top: 20px;
    }

    &__title {
      @include text-overflow;
      flex: 1 1 0%;
    }

    &__day {
      @include e-calendar-day;

      &-alternate {
        @include e-calendar-day-modifier('alternate');
      }

      &-today {
        @include e-calendar-day-modifier('today');
      }

      &-current {
        @include e-calendar-day-modifier('current');
      }

      &-disabled {
        @include e-calendar-day-modifier('disabled');
      }
    }
  }
}
