$emojipicker-box-size: 20px !default;
$emojipicker-box-width: 35px !default;

e-emoji {
  display: block;
  position: relative;

  .e-input {
    color: transparent;

    &:disabled {
      background-image: none;
      color: transparent;

      ~ .e-emoji {
        color: $input-color-disabled;
        cursor: not-allowed;
      }
    }
  }

  .e-emoji {
    background: transparent;
    background-clip: padding-box;
    border-color: transparent;
    left: 0;
    position: absolute;
    top: 0;
  }

  .e-input:last-child {
    color: $input-text-color;
  }

  .e-input-error ~ .e-emoji {
    color: $input-color-border-error;

    &::placeholder {
      color: $input-color-border-error;
    }

    &:focus {
      border-color: transparent;
      box-shadow: 0 0 4px rgba($input-color-border-error, .5);
    }
  }
}

.e {
  &-emojipicker {
    display: block;
    position: relative;

    &-active {
      .e-emojipicker {
        &__open {
          display: none;
        }

        &__close {
          display: block;
        }
      }
    }

    .e-emoji,
    .e-tokeninput__editable {
      font-family: 'Segoe UI Emoji', typo('body', 'family');
      padding-right: $emojipicker-box-width;

      &::-ms-clear {
        display: none;
      }
    }

    &__picker {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: $emojipicker-box-width - 2;
      justify-content: center;
      opacity: .7;
      position: absolute;
      right: 1px;
      top: 1px;
      width: $emojipicker-box-width;
      z-index: 1;

      &:after {
        background: rgba(255, 255, 255, .0001);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &:hover {
        opacity: 1;
      }

      svg {
        fill: palette('grays', 'shade');
        height: $emojipicker-box-size;
        position: relative;
        top: -1px;
        width: $emojipicker-box-size;
      }
    }

    &__close {
      display: none;
    }

    &__emoji {
      cursor: pointer;
      display: inline-block;
      line-height: 1;
      padding: 3px;
      text-align: center;

      &:after {
        content: attr(data-emoji);
      }

      &:hover {
        background-color: palette('grays', 'separator');
      }
    }

    &__container {
      @include e-box;
      background: $color-white;
      flex-direction: column;
      font-family: 'Segoe UI Emoji', typo('body', 'family');
      font-size: 20px;
      height: 200px;
      margin-bottom: 0;
      margin-right: -1px;
      padding: 0;
      top: -99999px;
      user-select: none;
      width: 290px;
      z-index: z('flying');
    }

    &__content {
      height: 165px;
      overflow: auto;
      padding: 5px;
    }

    &__tabs {
      display: flex;
      height: 33px;
    }

    &__tab {
      background: palette('grays', 'separator');
      cursor: pointer;
      flex: 1 0 auto;
      opacity: .8;
      text-align: center;

      &:hover {
        opacity: 1;
      }

      &-active {
        background: $color-white;
        opacity: 1;
      }
    }
  }
}
