$select-box-height: $box-form-element-height !default;
$select-box-padding: 0 2em 0 1em !default;
$select-color-background: #fff !default;
$select-color-background-disabled: palette('grays', 'inactive') !default;
$select-color-disabled: palette('default', 'light') !default;
$select-color-border: palette('default', 'x-light') !default;
$select-color-border-error: palette('danger') !default;
$select-color-border-focus: palette('secondary') !default;
$select-color-highlighted-background: palette('secondary') !default;
$select-text-size: typo('body', 'size') !default;
$select-text-color: palette('default') !default;
$select-text-color-placeholder: palette('grays', 'placeholder') !default;
$select-focus-transition: border $transition-time ease-in-out, box-shadow $transition-time ease-in-out !default;

$select-caret: url('select-caret.svg') !default;

@mixin e-select {
  @include text-overflow;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: $select-color-background;
  background-image: $select-caret;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  border: 1px solid $select-color-border;
  border-radius: 0;
  color: $select-text-color;
  font-size: $select-text-size;
  height: $select-box-height;
  padding: $select-box-padding;
  transition: $select-focus-transition;
  width: 100%;

  &::-ms-expand {
    display: none;
  }
}

@mixin e-select-size($_size: 'large') {
  width: map-deep-get($size, $_size, 'width');
}

.e {
  &-select {
    @include e-select;

    &-inline {
      width: auto;
    }

    &-autowidth {
      min-width: map-deep-get($size, 'large', 'width');
      width: auto;
    }

    &-pager {
      margin: 0 $grid-gutter-width / 4;
    }

    &:focus {
      border-color: $select-color-border-focus;
      box-shadow: 0 0 4px rgba($input-color-border-focus, .3);
      outline: 0;
    }

    &:disabled,
    &-disabled {
      background-color: $select-color-background-disabled;
      color: $select-color-disabled;
      cursor: not-allowed;
    }

    &-error {
      border: 1px dashed $select-color-border-error;
      color: $select-color-border-error;
    }

    @each $class, $data in $size {
      &-#{$class} {
        @include e-select-size($class);
      }
    }
  }
}

e-select {
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;

  &:focus,
  &[opened='true'] {
    outline: none;

    select:not([disabled]) {
      border-color: $select-color-border-focus;
      box-shadow: 0 0 4px rgba($input-color-border-focus, .3);

      &.e-select-error {
        border-color: $select-color-border-error;
        box-shadow: 0 0 4px rgba($select-color-border-error, .5);
      }
    }
  }

  .e-select {
    vertical-align: text-top;
  }

  &[tabindex]:after {
    background: $color-white;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &[inline]:after {
    display: none;
  }
}

.e-select2popup {
  position: absolute;
  text-align: left;
  top: -99999px;
  z-index: z('flying');

  &__border {
    background: $select-color-border-focus;
    height: 1px;
    position: absolute;
    right: 0;
  }

  &__content {
    background: $color-white;
    border: 1px solid $select-color-border-focus;
    border-top-width: 0;
    box-shadow: 0 0 4px rgba($input-color-border-focus, .3);
    display: block;
    margin: -1px 0;

    &-inline {
      border-width: 1px;
      box-shadow: none;
      margin: 0;
      min-height: 238px;
    }
  }

  &[x-placement='top-start'] {
    .e-select2popup__content {
      border-bottom-width: 0;
      border-top-width: 1px;
    }
  }
}

.e-select2options {
  line-height: $text-lineheight;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  &__option {
    @include word-wrap;
    cursor: pointer;
    padding: 3px 7px 4px;

    &-active {
      background: lighten($select-color-highlighted-background, 7);
      color: $color-white;
    }

    &-selected {
      background: $select-color-highlighted-background;
      color: $color-white;
    }

    &-disabled {
      color: $select-color-disabled;
      cursor: default;
    }

    &-group {
      color: palette('grays', 'text');
      font-weight: $text-bold-weight;
    }

    &-has_group {
      padding-left: 20px;
    }
  }

  &__noresult {
    background: palette('grays', 'inactive');
    padding: 3px 7px 4px;
  }
}

e-search {
  display: block;
  padding: 4px;

  &:empty {
    display: none;
  }

  .e-input {
    background-image: url('e-search.svg');
    background-position: right 4px center;
    background-repeat: no-repeat;
    height: 28px;
    padding-right: 24px;

    &:focus {
      border-color: $input-color-border;
      box-shadow: none;
    }
  }
}
