@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-300.woff2') format('woff2'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-300.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src:
    local('Roboto Light Italic'),
    local('Roboto-LightItalic'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-300italic.woff2') format('woff2'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-300italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-regular.woff2') format('woff2'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src:
    local('Roboto Italic'),
    local('Roboto-Italic'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-italic.woff2') format('woff2'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-500.woff2') format('woff2'),
    url('https://assets.emarsys.net/ui/static/fonts/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-500.woff') format('woff');
}
