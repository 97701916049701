e-dialog {
  display: none;
}

.e {
  &-dialog {
    align-items: center;
    backface-visibility: hidden;
    background: rgba($color-black, .6);
    bottom: 0;
    display: none;
    justify-content: center;
    left: 0;
    opacity: 0;
    outline: none;
    padding: 65px $grid-gutter-width $grid-gutter-width;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateZ(0);
    transition: opacity $transition-time ease-out;
    visibility: hidden;
    z-index: z('modal-backdrop');

    &-visible {
      display: flex;
      visibility: visible;
    }

    &-local {
      position: absolute;
    }

    &-active {
      opacity: 1;
    }

    &-img {
      .e-dialog__content {
        text-align: center;
        user-select: none;

        img {
          margin: auto;
          vertical-align: top;
        }
      }
    }

    &-iframe {
      .e-dialog__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0;
        user-select: none;

        iframe {
          border: 0;
          flex: 1 1 auto;
          height: 100%;
          vertical-align: top;
          width: 100%;
        }
      }
    }

    &-tabs {
      .e-dialog__content {
        padding: $grid-gutter-width / 2;
      }
    }

    &__container {
      background: #fff;
      box-shadow: 0 4px 8px 0 rgba($color-black, .3);
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      max-height: 100%;
      max-width: 650px;
      padding-top: $grid-gutter-width / 2;
      text-align: left;
      width: 100%;
    }

    &__header {
      flex: 0 0 auto;
      font-size: typo('title', 'size');
      font-weight: typo('title', 'weight');
      line-height: 34px;
      margin-top: -15px;
      min-height: 54px;
      padding: 10px ($grid-gutter-width + 24) 10px ($grid-gutter-width / 2);
      position: relative;
    }

    &__close {
      cursor: pointer;
      font-size: 0;
      position: absolute;
      right: $grid-gutter-width / 2;
      top: 10px;

      &:after {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &__content {
      flex: 1 1 auto;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding: 0 $grid-gutter-width / 2 $grid-gutter-width / 2;

      &-overflow {
        overflow: visible;
      }

      > img {
        vertical-align: top;
      }

      e-iframe-fit {
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    &__footer {
      border-top: 1px solid palette('grays', 'separator');
      flex: 0 0 auto;
      font-size: typo('title', 'size');
      font-weight: typo('title', 'weight');
      padding: $grid-gutter-width / 2;
    }
  }
}
